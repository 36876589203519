@charset "UTF-8";

:root {
  --color-foreground: var(--color-base-text);
  --color-background: var(--color-base-background-1);
  --gradient-background: var(--gradient-base-background-1);
  --color-foreground: var(--color-base-outline-button-labels);
  --alpha-link: 0.85;
  --color-button: var(--color-base-accent-1);
  --color-button-text: var(--color-base-solid-button-labels);
  --alpha-button-background: 1;
  --alpha-button-border: 1;
  --color-badge-background: var(--color-background);
  --color-badge-border: var(--color-foreground);
  --alpha-badge-border: 0.1;
  --color-card-hover: var(--color-base-text);
  --color-icon: rgb(var(--color-base-text));
  --duration-short: 100ms;
  --duration-default: 200ms;
  --duration-long: 500ms;
  --ax: 0.1rem;
  --ax: calc(clamp(8.53rem, 26.66vw, 10rem) * 0.01);
  --bar-height: 0;
}

@media only screen and (min-width: 990px) {
  :root {
    --ax: calc(clamp(8rem, 6.944vw, 10rem) * 0.01);
  }
}

:root.show-announcement {
  --bar-height: calc(var(--ax) * 30);
}

@media only screen and (min-width: 990px) {
  :root.show-announcement {
    --bar-height: calc(var(--ax) * 32);
  }
}

@media only screen and (max-width: 989px) {
  .mobile-menu-open {
    overflow: hidden;
  }

  .mobile-menu-open #MainContent,
  .mobile-menu-open #main-cart-footer {
    overflow: hidden;
    pointer-events: none;
  }
}

#preview-bar-iframe {
  display: none !important;
}

.cart-open {
  overflow: hidden;
}

#MainContent {
  margin-top: var(--header-height);
  overflow-x: hidden;
  width: 100%;
  z-index: 2;
}

#observable {
  display: block;
  height: calc(var(--bar-height) + 1px);
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: -1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes animateMenuOpen {
  0% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.no-js:not(html) {
  display: none !important;
}

html.no-js .no-js:not(html) {
  display: block !important;
}

.no-js-inline {
  display: none !important;
}

html.no-js .no-js-inline {
  display: inline-block !important;
}

html.no-js .no-js-hidden {
  display: none !important;
}

.element-margin-top {
  margin-top: 5rem;
}

@media only screen and (min-width: 750px) {
  .element-margin {
    margin-top: calc(5rem + var(--container-margin));
  }
}

body {
  background-color: rgb(var(--color-background));
  color: #272b1a;
}

.grid-auto-flow {
  display: grid;
  grid-auto-flow: column;
}

.page-margin,
.shopify-challenge__container {
  margin: 7rem auto;
}

.rte-width {
  margin: 0 auto 2rem;
  max-width: 82rem;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.skip-to-content-link:focus {
  clip: auto;
  height: auto;
  overflow: auto;
  position: inherit;
  width: auto;
  z-index: 9999;
}

.full-width-link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

::selection {
  background-color: rgba(var(--color-foreground), 0.2);
}

.object-cover {
  object-position: center;
}

.semantic-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.semantic-link {
  appearance: none;
  display: block;
  text-decoration: none;
}

.semantic-button {
  border: none;
  box-shadow: none;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.visually-hidden--inline {
  height: 1em;
  margin: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.isolate {
  position: relative;
  z-index: 0;
}

.container,
[class*=" container-"],
[class^="container-"] {
  margin-left: auto;
  margin-right: auto;
  padding: 0 calc(var(--ax) * 15);
  width: 100%;
}

@media only screen and (min-width: 750px) and (max-width: 989px) {
  .container,
  [class*=" container-"],
  [class^="container-"] {
    width: calc(100% - var(--ax) * 60);
  }
}

@media only screen and (min-width: 990px) {
  .container,
  [class*=" container-"],
  [class^="container-"] {
    max-width: calc(var(--ax) * 1512);
    padding: 0 calc(var(--ax) * 100);
  }
}

@media only screen and (max-width: 989px) {
  .container.container--full-width-mobile {
    max-width: 100%;
  }
}

.indent {
  display: inline-block;
  width: 0.4em;
}

.page-overlay {
  align-items: flex-end;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(39, 43, 26, 0.3);
  display: flex;
  justify-content: center;
  padding: 1.5em;
  position: fixed;
  z-index: 0;
}

.page-overlay span {
  opacity: 0;
  transition: opacity 0.1s linear;
}

.page-overlay:focus span {
  opacity: 1;
  transition: opacity 0.1s linear;
}

.no-focus {
  outline: none !important;
}

.a11y .no-focus {
  outline: unset !important;
}

.loading-overlay {
  background: hsla(0, 0%, 100%, 0.9);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 1.5em;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.image-block {
  display: block;
  height: auto;
  width: 100%;
}

.loading-overlay__spinner {
  display: none !important;
}

@media only screen and (min-width: 990px) {
  .hide--m-up {
    display: none !important;
  }
}

@media only screen and (max-width: 989px) {
  .hide--m-down {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {
  .hide--l-up {
    display: none !important;
  }
}

@media only screen and (max-width: 1199px) {
  .hide--l-down {
    display: none !important;
  }
}

.anchor,
.anchor:empty {
  display: block;
  height: 1px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: calc(var(--ax) * -80);
  width: 100%;
  z-index: 0;
}

.circle-divider:after {
  content: "•";
  margin: 0 calc(var(--ax) * 13) 0 calc(var(--ax) * 15);
}

.circle-divider:last-of-type:after {
  display: none;
}

hr {
  background-color: rgba(var(--color-foreground), 0.2);
  border: none;
  display: block;
  height: calc(var(--ax) * 1);
  margin: calc(var(--ax) * 50) 0;
}

@media only screen and (min-width: 750px) {
  hr {
    margin: calc(var(--ax) * 70) 0;
  }
}

details > * {
  box-sizing: border-box;
}

.break {
  word-break: break-word;
}

.visibility-hidden {
  visibility: hidden;
}

@media (prefers-reduced-motion) {
  .motion-reduce {
    animation: none !important;
    transition: none !important;
  }
}

.h3 .icon-arrow,
.icon-arrow,
h3 .icon-arrow {
  width: calc(var(--ax) * 15);
}

.animate-arrow .icon-arrow path {
  transform: translateX(-0.25rem);
  transition: transform var(--duration-short) ease;
}

.animate-arrow:hover .icon-arrow path {
  transform: translateX(-0.05rem);
}

summary {
  cursor: pointer;
  list-style: none;
  position: relative;
}

summary .icon-caret {
  height: 0.6rem;
  position: absolute;
  right: 1.5rem;
  top: calc(50% - 0.2rem);
}

summary::-webkit-details-marker {
  display: none;
}

.disclosure-has-popup {
  position: relative;
}

.disclosure-has-popup[open] > summary:before {
  background: transparent;
  bottom: 0;
  content: " ";
  cursor: default;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.disclosure-has-popup[open] > summary * {
  z-index: 100;
}

@media only screen and (min-width: 750px) {
  .disclosure-has-popup[open] > summary + * {
    z-index: 4;
  }

  .facets .disclosure-has-popup[open] > summary + * {
    z-index: 2;
  }
}

.disclosure-has-popup > summary:before {
  display: none;
}

.badge {
  background-color: rgb(var(--color-badge-background));
  border: 1px solid transparent;
  border-color: rgba(var(--color-badge-border), var(--alpha-badge-border));
  border-radius: var(--badge-corner-radius);
  color: #272b1a;
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  padding: 0.5rem 1.3rem 0.6rem;
  text-align: center;
  word-break: break-word;
}

@media screen and (forced-colors: active) {
  .icon {
    fill: CanvasText !important;
    color: CanvasText;
  }

  .icon-close-small path {
    stroke: CanvasText;
  }
}

.ratio {
  align-items: stretch;
  display: flex;
  position: relative;
}

.ratio:before {
  content: "";
  height: 0;
  padding-bottom: var(--ratio-percent);
  width: 0;
}

.content-container {
  border: var(--text-boxes-border-width) solid
    rgba(var(--color-foreground), var(--text-boxes-border-opacity));
  border-radius: var(--text-boxes-radius);
  position: relative;
}

.content-container:after {
  border-radius: var(--text-boxes-radius);
  bottom: calc(var(--text-boxes-border-width) * -1);
  box-shadow: var(--text-boxes-shadow-horizontal-offset)
    var(--text-boxes-shadow-vertical-offset)
    var(--text-boxes-shadow-blur-radius)
    rgba(var(--color-shadow), var(--text-boxes-shadow-opacity));
  content: "";
  left: calc(var(--text-boxes-border-width) * -1);
  position: absolute;
  right: calc(var(--text-boxes-border-width) * -1);
  top: calc(var(--text-boxes-border-width) * -1);
  z-index: -1;
}

.content-container--full-width:after {
  border-radius: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 749px) {
  .content-container--full-width-mobile {
    border-left: none;
    border-radius: 0;
    border-right: none;
  }

  .content-container--full-width-mobile:after {
    display: none;
  }
}

.global-media-settings {
  background-color: rgb(var(--color-background));
  border-radius: 0;
  overflow: visible !important;
  position: relative;
}

.global-media-settings iframe,
.global-media-settings img,
.global-media-settings model-viewer,
.global-media-settings video {
  border-radius: calc(0 - var(--media-border-width));
}

.content-container--full-width,
.global-media-settings--full-width,
.global-media-settings--full-width img {
  border-left: none;
  border-radius: 0;
  border-right: none;
}

@supports not (inset: 10px) {
  .grid {
    margin-left: calc(var(--ax) * 5 * -1);
  }

  .grid__item {
    padding-bottom: calc(var(--ax) * 5);
    padding-left: calc(var(--ax) * 5);
  }

  @media only screen and (min-width: 750px) {
    .grid {
      margin-left: calc(var(--ax) * 5 * -1);
    }

    .grid__item {
      padding-bottom: calc(var(--ax) * 5);
      padding-left: calc(var(--ax) * 5);
    }
  }

  .grid--gapless .grid__item {
    padding-bottom: 0;
    padding-left: 0;
  }

  @media screen and (min-width: 749px) {
    .grid--peek .grid__item {
      padding-left: calc(var(--ax) * 5);
    }
  }

  .product-grid .grid__item {
    padding-bottom: calc(var(--ax) * 5);
  }

  @media only screen and (min-width: 750px) {
    .product-grid .grid__item {
      padding-bottom: calc(var(--ax) * 5);
    }
  }
}

.font-body-bold {
  font-weight: var(--font-body-weight-bold);
}

.drop-shadow {
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 989px) {
  .drop-shadow--m-down {
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  }
}

#MainContent {
  position: relative;
}

#MainContent .shopify-section {
  position: relative;
  z-index: 1;
}

.primary-background {
  height: 100%;
  left: 0;
  opacity: 0.5;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
}

.shopify-section-group-footer-group {
  position: relative;
  z-index: 3;
}

.text-color--black {
  color: #272b1a;
}

.text-color--white {
  color: #fff;
}

.text-color--sand {
  color: #c3beb3;
}

.text-color--sand-dark {
  color: #2b2316;
}

.text-color--virgil {
  color: #e6e5e4;
}

.text-color--green {
  color: #444934;
}

.text-hover--black:hover {
  color: #272b1a;
}

.text-hover--white:hover {
  color: #fff;
}

.text-hover--sand:hover {
  color: #c3beb3;
}

.text-hover--sand-dark:hover {
  color: #2b2316;
}

.text-hover--virgil:hover {
  color: #e6e5e4;
}

.text-hover--green {
  color: #444934;
}

.bg-color--black {
  background-color: #272b1a;
}

.bg-color--white {
  background-color: #fff;
}

.bg-color--sand {
  background-color: #c3beb3;
}

.bg-color--sand-dark {
  background-color: #2b2316;
}

.bg-color--virgil {
  background-color: #e6e5e4;
}

.bg-color--green {
  background-color: #444934;
}
.bg-color--lightgray {
  background-color: #e6e5e4;
}

.bg-hover--black:hover {
  background-color: #272b1a;
}

.bg-hover--white:hover {
  background-color: #fff;
}

.bg-hover--sand:hover {
  background-color: #c3beb3;
}

.bg-hover--sand-dark:hover {
  background-color: #2b2316;
}

.bg-hover--virgil:hover {
  background-color: #e6e5e4;
}

.bg-hover--green:hover {
  background-color: #444934;
}

:focus,
:focus-visible {
  box-shadow: none;
  outline: 0;
}

@media only screen and (min-width: 990px) {
  :focus-visible {
    outline: 0.1rem solid currentColor;
    outline-offset: 0.3rem;
  }
}

.focused,
.no-js :focus {
  box-shadow: 0 0 0 0.3rem #fff,
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid #272b1a;
  outline-offset: 0.3rem;
}

.no-js :focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

@media only screen and (min-width: 990px) {
  .focus-inset:focus-visible {
    box-shadow: none;
    outline: none;
    position: relative;
  }

  .focus-inset:focus-visible:not(:hover):before {
    background-color: currentColor;
    border-radius: 100%;
    display: block;
    height: calc(var(--ax) * 5);
    position: absolute;
    top: calc(100% + var(--ax) * 5);
    transition: width 0.2s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: calc(var(--ax) * 5);
  }
}

.focused.focus-inset,
.no-js .focus-inset:focus {
  box-shadow: 0 0 0.2rem 0 rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid #272b1a;
  outline-offset: -0.2rem;
}

.no-js .focus-inset:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

.focus-none {
  box-shadow: none !important;
  outline: 0 !important;
}

.focus-offset:focus-visible {
  box-shadow: 0 0 0 1rem #fff,
    0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid #272b1a;
  outline-offset: 1rem;
}

.focus-offset.focused,
.no-js .focus-offset:focus {
  box-shadow: 0 0 0 1rem #fff,
    0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid #272b1a;
  outline-offset: 1rem;
}

.no-js .focus-offset:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

.bg-color--black button:focus {
  box-shadow: 0 0 0 0.3rem #fff;
  outline-color: #fff;
}

body:not(.a11y) {
  -webkit-ring-color: transparent !important;
}

body:not(.a11y) :focus,
body:not(.a11y) :focus-visible {
  box-shadow: none !important;
  outline-color: transparent !important;
  outline-style: none !important;
  outline-style: outset !important;
  outline-width: 0 !important;
  outline: 0 !important;
}

body:not(.a11y) .focus-inset:focus,
body:not(.a11y) .focus-inset:focus-visible,
body:not(.a11y) button:focus,
body:not(.a11y) button:focus-visible,
body:not(.a11y) input:focus,
body:not(.a11y) input:focus-visible,
body:not(.a11y) select:focus,
body:not(.a11y) select:focus-visible,
body:not(.a11y) textarea:focus,
body:not(.a11y) textarea:focus-visible {
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 0 hsla(0, 0%, 100%, 0);
  box-shadow: 0 0 0 hsla(0, 0%, 100%, 0);
  outline: none !important;
  outline-color: transparent !important;
  outline-style: none !important;
  outline: 0 !important;
}

.button,
.cart__dynamic-checkout-buttons [role="button"],
.cart__dynamic-checkout-buttons iframe,
.customer button,
.shopify-challenge__button,
.shopify-payment-button [role="button"],
button.shopify-payment-button__button--unbranded,
div#tripleseat_embed_form .button {
  --shadow-horizontal-offset: $radiusShadowHorizontalOffset;
  --shadow-vertical-offset: $radiusShadowVerticalOffset;
  --shadow-blur-radius: $radiusShadowBlurRadius;
  --shadow-opacity: $radiusShadowOpacity;
  --shadow-visible: $radiusShadowVisible;
  --border-offset: $radiusBorderOffset;
  --border-opacity: calc(1 - $radiusBorderOpacity);
  align-items: center;
  background: transparent;
  border: 1px solid #272b1a;
  border-radius: 0;
  color: #272b1a;
  cursor: pointer;
  display: inline-flex;
  font-family: Agenda, "sans-serif";
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  font-weight: 400;
  height: 2.8em;
  justify-content: center;
  max-width: 100%;
  padding: 0 1em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.15s ease, color 0.17s ease;
  width: max-content;
}

@media only screen and (min-width: 990px) {
  .button,
  .cart__dynamic-checkout-buttons [role="button"],
  .cart__dynamic-checkout-buttons iframe,
  .customer button,
  .shopify-challenge__button,
  .shopify-payment-button [role="button"],
  button.shopify-payment-button__button--unbranded,
  div#tripleseat_embed_form .button {
    font-size: calc(var(--ax) * 14);
    height: calc(var(--ax) * 40);
    min-width: calc(var(--ax) * 154);
    padding: 0 2.03em;
  }
}

.button,
.customer button,
.shopify-challenge__button,
button.shopify-payment-button__button--unbranded {
  height: calc(var(--ax) * 40);
  min-width: calc(var(--ax) * 100);
  padding: 0 1.5em;
}

.shopify-payment-button__button--branded {
  z-index: auto;
}

.cart__dynamic-checkout-buttons iframe {
  box-shadow: var(--shadow-horizontal-offset) var(--shadow-vertical-offset)
    var(--shadow-blur-radius)
    rgba(var(--color-base-text), var(--shadow-opacity));
}

.pill {
  border: 1px solid #272b1a;
  border-radius: 20rem;
  color: #272b1a;
  display: flex;
  height: calc(var(--ax) * 33);
  min-width: calc(var(--ax) * 103);
  padding: 0 1em;
  position: relative;
}

.pill__text {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 12.5);
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  top: -0.1em;
}

@media only screen and (min-width: 990px) {
  .pill__text {
    font-size: 1.25rem;
    font-size: clamp(1.25rem, 0.868vw, 1.25rem);
  }
}

fieldset {
  border: none;
}

.button {
  transition: background 0.2s cubic-bezier(0.32, 0.24, 0.15, 1),
    color 0.1s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.button:hover {
  background: #272b1a;
  color: #fff;
}

.button--text,
.underline-hover {
  text-display: none;
  position: relative;
}

.button--text:focus-visible,
.underline-hover:focus-visible {
  box-shadow: none;
  outline: none;
}

@media only screen and (min-width: 990px) {
  .button--text:focus-visible,
  .button--text:hover,
  .underline-hover:focus-visible,
  .underline-hover:hover {
    background: transparent;
    color: currentColor;
  }

  .button--text:before,
  .underline-hover:before {
    background-color: currentColor;
    bottom: calc(var(--ax) * -5);
    content: "";
    display: block;
    height: 1px;
    left: calc(var(--ax) * 4);
    margin: 0 auto;
    position: absolute;
    right: calc(var(--ax) * 4);
    transition: width 0.35s cubic-bezier(0.32, 0.24, 0.15, 1);
    width: 0;
  }

  .button--text:focus-visible:before,
  .button--text:hover:before,
  .underline-hover:focus-visible:before,
  .underline-hover:hover:before {
    width: calc(100% - var(--ax) * 8);
  }

  .button--text--tight:before,
  .underline-hover--tight:before {
    bottom: calc(var(--ax) * -2);
    left: 0;
    opacity: 0.75;
    right: unset;
  }
}

.pf-button {
  --shadow-horizontal-offset: $radiusShadowHorizontalOffset;
  --shadow-vertical-offset: $radiusShadowVerticalOffset;
  --shadow-blur-radius: $radiusShadowBlurRadius;
  --shadow-opacity: $radiusShadowOpacity;
  --shadow-visible: $radiusShadowVisible;
  --border-offset: $radiusBorderOffset;
  --border-opacity: calc(1 - $radiusBorderOpacity);
  align-items: center;
  background: transparent;
  border: 1px solid #272b1a;
  border-radius: 0;
  color: #272b1a;
  cursor: pointer;
  display: inline-flex;
  font-family: Montserrat, "sans-serif";
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  font-weight: 600;
  height: 2.8em;
  justify-content: center;
  letter-spacing: 0.14em;
  max-width: 100%;
  padding: 0 1em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.15s ease, color 0.17s ease;
  width: max-content;
}

@media only screen and (min-width: 990px) {
  .pf-button {
    font-size: calc(var(--ax) * 14);
    height: calc(var(--ax) * 40);
    min-width: calc(var(--ax) * 180);
    padding: 0 2.03em;
  }
}

.button--white {
  background: transparent;
  border: 1px solid #e6e5e4;
  color: #e6e5e4;
}

.button--white:hover {
  background: #e6e5e4;
  color: #272b1a;
}

.button--solid-white,
div#tripleseat_embed_form .button {
  background: #e6e5e4;
  border: 1px solid #e6e5e4;
  color: #272b1a;
}

.button--solid-white:hover,
div#tripleseat_embed_form .button:hover {
  background: #e6e5e4;
  color: #272b1a;
}

.button--solid-sand {
  background: #c3beb3;
  border: 1px solid #c3beb3;
  color: #272b1a;
}

.button--solid-sand:hover {
  background: #2b2316;
  color: #c3beb3;
}

.button--solid-black {
  background: #272b1a;
  border: 1px solid #272b1a;
  color: #e6e5e4;
}

.button--solid-black:hover,
.button--text {
  background: transparent;
  color: #272b1a;
}

.button--text {
  border: none;
  height: unset;
  letter-spacing: 0.1428571429em;
  min-width: unset;
  padding: 0;
}

@media only screen and (min-width: 750px) {
  .button--auto {
    min-width: unset;
  }
}

@media only screen and (min-width: 990px) {
  .button--large {
    max-width: calc(100% - var(--ax) * 30);
    min-width: calc(var(--ax) * 175);
  }
}

@media only screen and (max-width: 749px) {
  .s-down-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 750px) and (max-width: 989px) {
  .m-only-hide {
    display: none !important;
  }
}

.absolute-fill {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.object-cover {
  object-fit: cover;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  opacity: 0.7;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.b-0 {
  border: 0;
}

.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.mx-auto {
  margin-right: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0;
}

.my-auto {
  margin-bottom: auto;
  margin-top: auto;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.lh-1 {
  line-height: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.center-within {
  align-items: center;
  display: flex;
  justify-content: center;
}

.block-svg {
  height: auto;
  width: 100%;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.tt-uppercase {
  text-transform: uppercase;
}

.tt-lowercase {
  text-transform: lowercase;
}

.positioned-image {
  display: block;
}

.positioned-image:not(.absolute-fill) {
  position: relative;
}

.positioned-image img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.unpositioned-image {
  display: block;
}

.unpositioned-image:not(.absolute-fill) {
  position: relative;
}

[data-transition="fade"] {
  opacity: 0;
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
}

.section-animated [data-transition="fade"],
[data-transition="fade"].section-animated {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
}

[aria-hidden="true"] [data-transition="fade"][data-slide-animation] {
  opacity: 0;
}

[aria-hidden="false"]
  .section-animated
  [data-transition="fade"][data-slide-animation],
[aria-hidden="false"]
  [data-transition="fade"][data-slide-animation].section-animated {
  opacity: 1;
}

[data-transition="fade-down"]:not(
    [data-duration]:not([data-animation-duration])
  ),
[data-transition="fade-left"]:not(
    [data-duration]:not([data-animation-duration])
  ),
[data-transition="fade-right"]:not(
    [data-duration]:not([data-animation-duration])
  ),
[data-transition="fade-up"]:not(
    [data-duration]:not([data-animation-duration])
  ) {
  animation-duration: 0.7s;
  transition-duration: 0.7s;
}

@media only screen and (min-width: 990px) {
  [data-transition="fade-up"] {
    opacity: 0;
    transform: translateY(22%);
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  .section-animated [data-transition="fade-up"],
  [data-transition="fade-up"].section-animated {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
  }

  [aria-hidden="true"] [data-transition="fade-up"][data-slide-animation] {
    opacity: 0;
    transform: translateY(22%);
    transition-property: opacity, transform;
  }

  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-up"][data-slide-animation],
  [aria-hidden="false"]
    [data-transition="fade-up"][data-slide-animation].section-animated {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
  }

  [data-transition="fade-down"] {
    opacity: 0;
    transform: translateY(-22%);
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  .section-animated [data-transition="fade-down"],
  [data-transition="fade-down"].section-animated {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
  }

  [aria-hidden="true"] [data-transition="fade-down"][data-slide-animation] {
    opacity: 0;
    transform: translateY(-22%);
    transition-property: opacity, transform;
  }

  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-down"][data-slide-animation],
  [aria-hidden="false"]
    [data-transition="fade-down"][data-slide-animation].section-animated {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
  }

  [data-transition="fade-left"] {
    opacity: 0;
    transform: translateX(-15%);
  }

  .section-animated [data-transition="fade-left"],
  [data-transition="fade-left"].section-animated {
    animation-fill-mode: forwards;
    animation-name: fade-left;
    animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  [aria-hidden="true"] [data-transition="fade-left"][data-slide-animation] {
    animation: unset;
    opacity: 0;
    transform: translateX(-15%);
  }

  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-left"][data-slide-animation],
  [aria-hidden="false"]
    [data-transition="fade-left"][data-slide-animation].section-animated {
    animation-fill-mode: forwards;
    animation-name: fade-left;
    animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  [data-transition="fade-right"] {
    opacity: 0;
    transform: translateX(15%);
  }

  .section-animated [data-transition="fade-right"],
  [data-transition="fade-right"].section-animated {
    animation-fill-mode: forwards;
    animation-name: fade-right;
    animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  [aria-hidden="true"] [data-transition="fade-right"][data-slide-animation] {
    animation: unset;
    opacity: 0;
    transform: translateX(15%);
  }

  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-right"][data-slide-animation],
  [aria-hidden="false"]
    [data-transition="fade-right"][data-slide-animation].section-animated {
    animation-fill-mode: forwards;
    animation-name: fade-right;
    animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }
}

@media only screen and (max-width: 989px) {
  [data-transition="fade-down"],
  [data-transition="fade-left"],
  [data-transition="fade-right"],
  [data-transition="fade-up"] {
    opacity: 0;
    transform: translateY(22%);
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  .section-animated [data-transition="fade-down"],
  .section-animated [data-transition="fade-left"],
  .section-animated [data-transition="fade-right"],
  .section-animated [data-transition="fade-up"],
  [data-transition="fade-down"].section-animated,
  [data-transition="fade-left"].section-animated,
  [data-transition="fade-right"].section-animated,
  [data-transition="fade-up"].section-animated {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
  }

  [aria-hidden="true"] [data-transition="fade-down"][data-slide-animation],
  [aria-hidden="true"] [data-transition="fade-left"][data-slide-animation],
  [aria-hidden="true"] [data-transition="fade-right"][data-slide-animation],
  [aria-hidden="true"] [data-transition="fade-up"][data-slide-animation] {
    opacity: 0;
    transform: translateY(22%);
    transition-property: opacity, transform;
  }

  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-down"][data-slide-animation],
  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-left"][data-slide-animation],
  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-right"][data-slide-animation],
  [aria-hidden="false"]
    .section-animated
    [data-transition="fade-up"][data-slide-animation],
  [aria-hidden="false"]
    [data-transition="fade-down"][data-slide-animation].section-animated,
  [aria-hidden="false"]
    [data-transition="fade-left"][data-slide-animation].section-animated,
  [aria-hidden="false"]
    [data-transition="fade-right"][data-slide-animation].section-animated,
  [aria-hidden="false"]
    [data-transition="fade-up"][data-slide-animation].section-animated {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
  }
}

@keyframes fade-left {
  0% {
    opacity: 0;
    transform: translateX(-15%);
  }

  20% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translateX(15%);
  }

  20% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

[data-duration="0.1"] {
  transition-duration: 0.1s;
}

[data-duration="0.2"] {
  transition-duration: 0.2s;
}

[data-duration="0.3"] {
  transition-duration: 0.3s;
}

[data-duration="0.4"] {
  transition-duration: 0.4s;
}

[data-duration="0.5"] {
  transition-duration: 0.5s;
}

[data-duration="0.6"] {
  transition-duration: 0.6s;
}

[data-duration="0.7"] {
  transition-duration: 0.7s;
}

[data-duration="0.8"] {
  transition-duration: 0.8s;
}

[data-duration="0.9"] {
  transition-duration: 0.9s;
}

[data-duration="1.0"] {
  transition-duration: 1s;
}

[data-duration="1.1"] {
  transition-duration: 1.1s;
}

[data-duration="1.2"] {
  transition-duration: 1.2s;
}

[data-duration="1.3"] {
  transition-duration: 1.3s;
}

[data-duration="1.4"] {
  transition-duration: 1.4s;
}

[data-duration="1.5"] {
  transition-duration: 1.5s;
}

[data-duration="1.6"] {
  transition-duration: 1.6s;
}

[data-duration="1.7"] {
  transition-duration: 1.7s;
}

[data-duration="1.8"] {
  transition-duration: 1.8s;
}

[data-duration="1.9"] {
  transition-duration: 1.9s;
}

[data-duration="2.0"] {
  transition-duration: 2s;
}

[data-delay="0"] {
  transition-delay: 0s;
}

[data-delay="0.1"] {
  transition-delay: 0.1s;
}

[data-delay="0.2"] {
  transition-delay: 0.2s;
}

[data-delay="0.3"] {
  transition-delay: 0.3s;
}

[data-delay="0.4"] {
  transition-delay: 0.4s;
}

[data-delay="0.5"] {
  transition-delay: 0.5s;
}

[data-delay="0.6"] {
  transition-delay: 0.6s;
}

[data-delay="0.7"] {
  transition-delay: 0.7s;
}

[data-delay="0.8"] {
  transition-delay: 0.8s;
}

[data-delay="0.9"] {
  transition-delay: 0.9s;
}

[data-delay="1.0"] {
  transition-delay: 1s;
}

[data-delay="1.1"] {
  transition-delay: 1.1s;
}

[data-delay="1.2"] {
  transition-delay: 1.2s;
}

[data-delay="1.3"] {
  transition-delay: 1.3s;
}

[data-delay="1.4"] {
  transition-delay: 1.4s;
}

[data-delay="1.5"] {
  transition-delay: 1.5s;
}

[data-animation-duration="0.1"] {
  animation-duration: 0.1s;
}

[data-animation-duration="0.2"] {
  animation-duration: 0.2s;
}

[data-animation-duration="0.3"] {
  animation-duration: 0.3s;
}

[data-animation-duration="0.4"] {
  animation-duration: 0.4s;
}

[data-animation-duration="0.5"] {
  animation-duration: 0.5s;
}

[data-animation-duration="0.6"] {
  animation-duration: 0.6s;
}

[data-animation-duration="0.7"] {
  animation-duration: 0.7s;
}

[data-animation-duration="0.8"] {
  animation-duration: 0.8s;
}

[data-animation-duration="0.9"] {
  animation-duration: 0.9s;
}

[data-animation-duration="1.0"] {
  animation-duration: 1s;
}

[data-animation-duration="1.1"] {
  animation-duration: 1.1s;
}

[data-animation-duration="1.2"] {
  animation-duration: 1.2s;
}

[data-animation-duration="1.3"] {
  animation-duration: 1.3s;
}

[data-animation-duration="1.4"] {
  animation-duration: 1.4s;
}

[data-animation-duration="1.5"] {
  animation-duration: 1.5s;
}

[data-animation-duration="1.6"] {
  animation-duration: 1.6s;
}

[data-animation-duration="1.7"] {
  animation-duration: 1.7s;
}

[data-animation-duration="1.8"] {
  animation-duration: 1.8s;
}

[data-animation-duration="1.9"] {
  animation-duration: 1.9s;
}

[data-animation-duration="2.0"] {
  animation-duration: 2s;
}

[data-animation-delay="0"] {
  animation-delay: 0s !important;
}

[data-animation-delay="0.1"] {
  animation-delay: 0.1s !important;
}

[data-animation-delay="0.2"] {
  animation-delay: 0.2s !important;
}

[data-animation-delay="0.3"] {
  animation-delay: 0.3s !important;
}

[data-animation-delay="0.4"] {
  animation-delay: 0.4s !important;
}

[data-animation-delay="0.5"] {
  animation-delay: 0.5s !important;
}

[data-animation-delay="0.6"] {
  animation-delay: 0.6s !important;
}

[data-animation-delay="0.7"] {
  animation-delay: 0.7s !important;
}

[data-animation-delay="0.8"] {
  animation-delay: 0.8s !important;
}

[data-animation-delay="0.9"] {
  animation-delay: 0.9s !important;
}

[data-animation-delay="1.0"] {
  animation-delay: 1s !important;
}

[data-animation-delay="1.1"] {
  animation-delay: 1.1s !important;
}

[data-animation-delay="1.2"] {
  animation-delay: 1.2s !important;
}

[data-animation-delay="1.3"] {
  animation-delay: 1.3s !important;
}

[data-animation-delay="1.4"] {
  animation-delay: 1.4s !important;
}

[data-animation-delay="1.5"] {
  animation-delay: 1.5s !important;
}

body {
  font-family: Agenda, "sans-serif";
  font-style: normal;
}

a.button {
  appearance: none;
  text-decoration: none;
}

.h0,
.hxl,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.h0,
.h1,
.hxl,
.rte h1,
.shopify-policy__title h1,
h1 {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 20);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0145em;
  line-height: 0.9;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .h0,
  .h1,
  .hxl,
  .rte h1,
  .shopify-policy__title h1,
  h1 {
    font-size: calc(var(--ax) * 24);
  }
}

.h0--product,
.h1--product,
.hxl--product,
.rte h1--product,
.shopify-policy__title h1--product,
h1--product {
  font-size: calc(var(--ax) * 24);
}

@media only screen and (min-width: 990px) {
  .h0--product,
  .h1--product,
  .hxl--product,
  .rte h1--product,
  .shopify-policy__title h1--product,
  h1--product {
    font-size: calc(var(--ax) * 28);
  }
}

.h2,
.tag {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 41);
  font-style: normal;
  font-variation-settings: "wght" 160;
  font-weight: 160;
  line-height: 0.66;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .h2,
  .tag {
    font-size: calc(var(--ax) * 80);
  }
}

.h3,
.rte h2,
.rte h3,
h2 {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 24);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .h3,
  .rte h2,
  .rte h3,
  h2 {
    font-size: calc(var(--ax) * 43);
  }
}

.h4,
h4 {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-style: normal;
  letter-spacing: 0.14375em;
  line-height: 1;
}

@media only screen and (min-width: 990px) {
  .h4,
  h4 {
    font-size: calc(var(--ax) * 16);
  }
}

.rte li strong,
.rte-sec,
.sentence-large,
.sentence-product,
.sentence-small,
.shopify-policy__container,
.text-body {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.0625em;
  line-height: 1.485;
}

@media only screen and (min-width: 990px) {
  .rte li strong,
  .rte-sec,
  .sentence-large,
  .sentence-product,
  .sentence-small,
  .shopify-policy__container,
  .text-body {
    font-size: calc(var(--ax) * 16);
  }
}

.rte li strong b,
.rte li strong strong,
.rte-sec b,
.rte-sec strong,
.sentence-large b,
.sentence-large strong,
.sentence-product b,
.sentence-product strong,
.sentence-small b,
.sentence-small strong,
.shopify-policy__container b,
.shopify-policy__container strong,
.text-body b,
.text-body strong {
  font-weight: 700;
}

.rte li strong p,
.rte-sec p,
.sentence-large p,
.sentence-product p,
.sentence-small p,
.shopify-policy__container p,
.text-body p {
  font: inherit;
  margin-bottom: 1em;
  margin-top: 0;
}

.rte li strong p:last-of-type,
.rte-sec p:last-of-type,
.sentence-large p:last-of-type,
.sentence-product p:last-of-type,
.sentence-small p:last-of-type,
.shopify-policy__container p:last-of-type,
.text-body p:last-of-type {
  margin-bottom: 0;
}

.rte li strong a,
.rte-sec a,
.sentence-large a,
.sentence-product a,
.sentence-small a,
.shopify-policy__container a,
.text-body a {
  color: inherit;
}

.rte li strong,
.sentence-product,
.shopify-policy__body,
.shopify-policy__container li,
.shopify-policy__container p {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  line-height: 1.5;
  text-transform: unset;
}

@media only screen and (min-width: 990px) {
  .rte li strong,
  .sentence-product,
  .shopify-policy__body,
  .shopify-policy__container li,
  .shopify-policy__container p {
    font-size: calc(var(--ax) * 15);
  }
}

.sentence-large,
.sentence-small {
  letter-spacing: 0.02em;
}

.sentence-product,
.sentence-small {
  font-weight: 400;
}

.body-label {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .body-label {
    font-size: calc(var(--ax) * 18);
  }
}

.text-body {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
}

@media only screen and (min-width: 990px) {
  .text-body {
    font-size: calc(var(--ax) * 16);
  }
}

.label-large,
.label-medium {
  font-family: Agenda, "sans-serif";
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.label-large {
  font-size: calc(var(--ax) * 14);
}

@media only screen and (min-width: 990px) {
  .label-large {
    font-size: calc(var(--ax) * 18);
  }
}

.label-medium {
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  line-height: 1.1;
}

@media only screen and (min-width: 990px) {
  .label-medium {
    font-size: calc(var(--ax) * 14);
  }
}

.label-small {
  font-family: Agenda, "sans-serif";
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .label-small {
    font-size: 1.2rem;
    font-size: clamp(1rem, 0.83328vw, 1.2rem);
  }
}

.tag {
  font-size: calc(var(--ax) * 16);
  line-height: 1;
  padding: 0.4em 1em;
}

@media only screen and (min-width: 990px) {
  .tag {
    font-size: calc(var(--ax) * 24);
  }
}

.tag--artext {
  font-family: Agenda, "sans-serif";
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
}

@media only screen and (min-width: 990px) {
  .tag--artext {
    font-size: 1.2rem;
    font-size: clamp(1rem, 0.83328vw, 1.2rem);
  }
}

.footer-menu-item,
.header-menu-item {
  font-family: Agenda, "sans-serif";
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.0607em;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .footer-menu-item,
  .header-menu-item {
    font-size: calc(var(--ax) * 14);
  }
}

.footer-menu-item {
  letter-spacing: 0;
}

.footer-menu-item,
.legal-menu-item {
  color: var(--color-foreground);
  display: block;
  font-size: calc(var(--ax) * 14);
  margin-bottom: calc(var(--ax) * 20);
  text-decoration: none;
}

@media only screen and (min-width: 990px) {
  .footer-menu-item,
  .legal-menu-item {
    font-size: calc(var(--ax) * 16);
  }
}

.footer-menu-item:hover,
.legal-menu-item:hover {
  text-decoration: underline;
}

.legal-menu-item {
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-top: calc(var(--ax) * -1);
}

.label-option,
.legal-menu-item {
  font-family: Agenda, "sans-serif";
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
}

.label-option {
  font-size: calc(var(--ax) * 12.5);
  font-weight: 500;
}

@media only screen and (min-width: 990px) {
  .label-option {
    font-size: 1.25rem;
    font-size: clamp(1.25rem, 0.868vw, 1.25rem);
  }
}

.grid-title {
  display: block;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .grid-title {
    font-size: calc(var(--ax) * 16);
  }
}

.grid-text {
  display: block;
  font-size: clamp(1rem, 2.564vw, 1.2rem);
  letter-spacing: 0.02em;
  line-height: 1.475;
}

.cart-info,
.grid-text {
  font-family: Agenda, "sans-serif";
  font-style: normal;
  font-weight: 400;
}

.cart-info {
  font-size: calc(var(--ax) * 13);
  line-height: 1;
}

@media only screen and (min-width: 990px) {
  .cart-info {
    font-size: calc(var(--ax) * 13);
  }

  .h1,
  .rte h1 {
    line-height: 0.88;
  }

  .sentence-small {
    line-height: 1.15;
  }

  .sentence-product,
  .text-body {
    line-height: 1.45;
  }

  .grid-text {
    font-size: clamp(1rem, 0.8593vw, 1.56rem);
  }
}

a:empty:not(.empty),
article:empty:not(.empty),
div:empty:not(.empty),
dl:empty:not(.empty),
h1:empty:not(.empty),
h2:empty:not(.empty),
h3:empty:not(.empty),
h4:empty:not(.empty),
h5:empty:not(.empty),
h6:empty:not(.empty),
p:empty:not(.empty),
section:empty:not(.empty),
ul:empty:not(.empty) {
  display: none;
}

.customer a,
.link {
  font-size: 1.4rem;
  text-underline-offset: 0.3rem;
}

.customer a,
.link,
.link--text,
.link--text:hover {
  color: var(--color-foreground);
}

.link-with-icon {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 4.5rem;
}

.link-with-icon .icon {
  margin-left: 1rem;
  width: 1.5rem;
}

.italic-heading {
  font-family: Chiswick, "sans-serif";
  font-size: calc(var(--ax) * 22);
  font-style: italic;
  font-weight: 100;
  letter-spacing: 0.05em;
  line-height: 1.15;
  text-transform: none;
}

@media only screen and (min-width: 990px) {
  .italic-heading {
    font-size: calc(var(--ax) * 40);
  }
}

.italic-heading--sm {
  font-size: calc(var(--ax) * 18);
  letter-spacing: 0.0625em;
}

@media only screen and (min-width: 990px) {
  .italic-heading--sm {
    font-size: calc(var(--ax) * 32);
  }
}

.customer a,
.link {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #272b1a;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: calc(var(--ax) * 14);
  text-decoration: underline;
  text-underline-offset: calc(var(--ax) * 3);
}

@media only screen and (min-width: 990px) {
  .customer a,
  .link {
    font-size: calc(var(--ax) * 14);
  }
}

.link--text {
  color: #272b1a;
}

.link--text:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.link-with-icon {
  display: inline-flex;
  font-size: calc(var(--ax) * 14);
  font-weight: 600;
  letter-spacing: 0.007em;
  margin-bottom: calc(var(--ax) * 45);
  text-decoration: none;
  white-space: nowrap;
}

@media only screen and (min-width: 990px) {
  .link-with-icon {
    font-size: calc(var(--ax) * 14);
  }
}

.link-with-icon .icon {
  margin-left: calc(var(--ax) * 10);
  width: calc(var(--ax) * 15);
}

a:not([href]) {
  cursor: not-allowed;
}

blockquote {
  border-left: 0.2rem solid rgba(var(--color-foreground), 0.2);
  color: rgba(var(--color-foreground), 0.75);
  font-style: italic;
  padding-left: calc(var(--ax) * 10);
}

@media only screen and (min-width: 750px) {
  blockquote {
    padding-left: calc(var(--ax) * 15);
  }
}

.caption {
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  letter-spacing: 0.007em;
  line-height: 1.7;
}

@media only screen and (min-width: 990px) {
  .caption {
    font-size: 1rem;
    font-size: clamp(1rem, 0.6944vw, 1rem);
  }
}

@media only screen and (min-width: 750px) {
  .caption {
    font-size: 1.2rem;
    font-size: clamp(1rem, 3.0768vw, 1.2rem);
  }
}

@media only screen and (min-width: 750px) and (min-width: 990px) {
  .caption {
    font-size: 1.2rem;
    font-size: clamp(1.2rem, 0.83328vw, 1.2rem);
  }
}

.eyebrow {
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  font-weight: 400;
  letter-spacing: 0.143em;
  line-height: 1.2;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .eyebrow {
    font-size: calc(var(--ax) * 14);
  }
}

#tripleseat_embed_form tr h2,
.eyebrow--large {
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  letter-spacing: 0.215em;
}

@media only screen and (min-width: 990px) {
  #tripleseat_embed_form tr h2,
  .eyebrow--large {
    font-size: calc(var(--ax) * 16);
  }
}

.eyebrow--xl {
  font-size: calc(var(--ax) * 16);
  letter-spacing: 0.14375em;
}

@media only screen and (min-width: 990px) {
  .eyebrow--xl {
    font-size: calc(var(--ax) * 24);
  }
}

.color-foreground {
  color: #272b1a;
}

table:not([class]) {
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2);
  font-size: calc(var(--ax) * 14);
  table-layout: fixed;
}

@media only screen and (min-width: 990px) {
  table:not([class]) {
    font-size: calc(var(--ax) * 14);
  }
}

table:not([class]) td,
table:not([class]) th {
  border: 0.1rem solid rgba(var(--color-foreground), 0.2);
  padding: 1em;
}

.full-unstyled-link {
  color: currentColor;
  display: block;
  text-decoration: none;
}

.placeholder {
  fill: rgba(var(--color-foreground), 0.55);
  background-color: rgba(var(--color-foreground), 0.04);
  color: rgba(var(--color-foreground), 0.55);
}

.customer a,
.underlined-link {
  color: rgba(var(--color-foreground), 1);
  text-decoration-thickness: calc(var(--ax) * 1);
  text-underline-offset: calc(var(--ax) * 3);
  transition: text-decoration-thickness 0.1s ease;
}

.customer a:hover,
.underlined-link:hover {
  color: #272b1a;
  text-decoration-thickness: calc(var(--ax) * 2);
}

.pf-h2 {
  font-family: Poppins, "sans-serif";
  font-size: calc(var(--ax) * 30);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01875em;
  line-height: 1.2;
  text-transform: unset;
}

@media only screen and (min-width: 990px) {
  .pf-h2 {
    font-size: calc(var(--ax) * 40);
  }
}

.pf-h3 {
  font-family: Poppins, "sans-serif";
  font-size: calc(var(--ax) * 24);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.3;
  text-transform: unset;
}

@media only screen and (min-width: 990px) {
  .pf-h3 {
    font-size: calc(var(--ax) * 30);
  }
}

.pf-h4 {
  font-family: Poppins, "sans-serif";
  font-size: calc(var(--ax) * 20);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.06em;
  line-height: 1.6;
  text-transform: unset;
}

@media only screen and (min-width: 990px) {
  .pf-h4 {
    font-size: calc(var(--ax) * 28);
  }
}

.pf-h5 {
  font-family: Poppins, "sans-serif";
  font-size: calc(var(--ax) * 16);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.0375em;
  line-height: 1;
}

@media only screen and (min-width: 990px) {
  .pf-h5 {
    font-size: calc(var(--ax) * 20);
  }
}

.pf-eyebrow-large {
  font-family: Montserrat, "sans-serif";
  font-size: calc(var(--ax) * 16);
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 1;
}

@media only screen and (min-width: 990px) {
  .pf-eyebrow-large {
    font-size: calc(var(--ax) * 20);
  }
}

.pf-eyebrow {
  font-family: Montserrat, "sans-serif";
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  font-weight: 400;
  letter-spacing: 0.145em;
  line-height: 1;
}

@media only screen and (min-width: 990px) {
  .pf-eyebrow {
    font-size: calc(var(--ax) * 14);
  }
}

.pf-price {
  font-family: Poppins, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-weight: 600;
  letter-spacing: 0.0555555556em;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .pf-price {
    font-size: calc(var(--ax) * 18);
  }
}

.pf-text-small {
  font-family: Montserrat, "sans-serif";
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media only screen and (min-width: 990px) {
  .pf-text-small {
    font-size: calc(var(--ax) * 13);
  }
}

.pf-text-small b,
.pf-text-small strong {
  font-weight: 700;
}

.pf-text-small p {
  font: inherit;
  margin-bottom: 1em;
  margin-top: 0;
}

.pf-text-small p:last-of-type {
  margin-bottom: 0;
}

.pf-text-small a {
  color: inherit;
}

.pf-text-body {
  font-family: Montserrat, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.485;
}

@media only screen and (min-width: 990px) {
  .pf-text-body {
    font-size: calc(var(--ax) * 16);
  }
}

.pf-text-body b,
.pf-text-body strong {
  font-weight: 700;
}

.pf-text-body p {
  font: inherit;
  margin-bottom: 1em;
  margin-top: 0;
}

.pf-text-body p:last-of-type {
  margin-bottom: 0;
}

.pf-text-body a {
  color: inherit;
}

.video__container:not(.absolute-fill) {
  position: relative;
}

.video video {
  background: transparent;
}

.announcement-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: calc(var(--ax) * 10) calc(var(--ax) * 20);
  position: relative;
  text-decoration: none;
  z-index: 111132;
}

.cart-open .announcement-bar {
  z-index: 0;
}

@media only screen and (max-width: 989px) {
  .mobile-menu-open .announcement-bar {
    z-index: 0;
  }
}

.announcement-bar__text {
  padding: 0;
}

.bar {
  --unit: calc(var(--ax) * 6);
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 990px) {
  .bar {
    --unit: calc(var(--ax) * 7);
  }
}

.bar--vertical {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.bar--vertical.section-animated .bar__inner {
  animation-name: vertical-bar;
}

.bar--horizontal {
  width: 100%;
}

.bar--horizontal.section-animated .bar__inner {
  animation-name: horizontal-bar;
}

.bar__inner {
  align-items: center;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
  display: flex;
  justify-content: center;
  opacity: 0;
  position: relative;
}

.bar__circle,
.bar__inner {
  height: var(--unit);
  width: var(--unit);
}

.bar__circle {
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  position: absolute;
}

.bar__circle--top {
  left: 0;
  top: 0;
}

.bar__circle--bottom {
  bottom: 0;
  left: 0;
}

.bar__circle--left {
  left: 0;
  top: 0;
}

.bar__circle--right {
  right: 0;
  top: 0;
}

.bar__line--vertical {
  height: calc(100% - var(--unit) * 2);
  width: calc(var(--ax) * 1);
}

.bar__line--horizontal {
  height: calc(var(--ax) * 1);
  width: calc(100% - var(--unit) * 2);
}

@keyframes vertical-bar {
  0% {
    height: var(--unit);
    opacity: 0;
  }

  3% {
    height: calc(var(--ax) * 20);
    opacity: 1;
  }

  to {
    height: 100%;
    opacity: 1;
  }
}

@keyframes horizontal-bar {
  0% {
    opacity: 0;
    width: var(--unit);
  }

  3% {
    opacity: 1;
    width: calc(var(--ax) * 20);
  }

  to {
    opacity: 1;
    width: 100%;
  }
}

.cart-count-bubble {
  align-items: center;
  border-radius: 100%;
  color: #272b1a;
  display: flex;
  height: 0.41667em;
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0.1em;
  width: 0.41667em;
}

@media only screen and (min-width: 990px) {
  .cart-count-bubble {
    right: -0.2em;
    top: -0.35em;
  }
}

.cart-count-bubble span {
  font-family: Agenda, "sans-serif";
  font-size: 0.2778em;
  position: relative;
  top: 0.055em;
}

.cart-count-bubble:empty {
  display: none;
}

.cart-drawer__bnpl {
  display: block;
  margin-top: 1em;
  text-align: center;
}

.cart-drawer__overlay {
  backdrop-filter: blur(0);
  opacity: 0;
  position: fixed;
  transition: opacity 0s cubic-bezier(0.32, 0.24, 0.15, 1),
    backdrop-filter 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.drawer.animate.active .cart-drawer__overlay {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.32, 0.24, 0.15, 1),
    backdrop-filter 0.3s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.cart-drawer__overlay:empty {
  display: flex;
}

.cart-drawer__empty {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.cart-drawer__form {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.cart-drawer__footer > * + * {
  margin-top: 1rem;
}

.cart-drawer .cart-items,
.cart-drawer tbody {
  display: block;
  width: 100%;
}

.cart-drawer .unit-price {
  margin-top: 0.6rem;
}

.cart-drawer .totals {
  justify-content: space-between;
}

.cart-drawer .price {
  line-height: 1;
}

.cart-drawer .tax-note {
  margin: 1.2rem 0 1rem auto;
  text-align: left;
}

.cart-drawer .product-option dd {
  word-break: break-word;
}

.cart-drawer details[open] > summary .icon-caret {
  transform: rotate(180deg);
}

.cart-drawer .cart__checkout-button {
  /* margin-top: 1.75em; */
  max-width: 100%;
  width: 100%;
}

cart-drawer {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.cart-item {
  align-items: flex-start;
  background-color: hsla(0, 0%, 100%, 0.8);
  display: flex;
  filter: blur(0);
  -webkit-filter: blur(0);
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 0;
  margin-top: calc(var(--ax) * 14);
  min-height: calc(var(--ax) * 80);
  overflow: hidden;
  padding: calc(var(--ax) * 10) calc(var(--ax) * 10) calc(var(--ax) * 10)
    calc(var(--ax) * 76);
  position: relative;
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .cart-item {
    min-height: calc(var(--ax) * 130);
    padding: calc(var(--ax) * 15) calc(var(--ax) * 15) calc(var(--ax) * 15)
      calc(var(--ax) * 125);
  }
}

.cart-item.loading {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  transition: filter 0.3s ease-in-out, -webkit-filter 0.3s ease-in-out;
}

.cart-item:first-child {
  margin-top: calc(var(--ax) * 20);
}

@media only screen and (min-width: 990px) {
  .cart-item:first-child {
    margin-top: calc(var(--ax) * 34);
  }
}

.cart-item__name {
  color: #272b1a;
  text-decoration: none;
}

.cart-item__media {
  aspect-ratio: 1/1;
  bottom: calc(var(--ax) * 10);
  height: calc(var(--ax) * 58);
  left: calc(var(--ax) * 10);
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: calc(var(--ax) * 10);
  width: calc(var(--ax) * 58);
}

@media only screen and (min-width: 990px) {
  .cart-item__media {
    bottom: calc(var(--ax) * 15);
    height: calc(var(--ax) * 100);
    left: calc(var(--ax) * 15);
    margin-bottom: auto;
    margin-top: auto;
    top: calc(var(--ax) * 15);
    width: calc(var(--ax) * 100);
  }
}

.cart-item__image {
  aspect-ratio: 1/1;
  border-radius: 1rem;
  display: block;
  height: auto;
  width: 100%;
}

.cart-item__image--transparent img {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.cart-item__details {
  width: calc(100% - var(--ax) * 36);
}

.cart-item__details .product-option {
  font-size: 1.1rem;
  font-size: clamp(1rem, 2.8204vw, 1.1rem);
  text-transform: capitalize;
}

@media only screen and (min-width: 990px) {
  .cart-item__details .product-option {
    font-size: calc(var(--ax) * 13);
  }

  .cart-item__details {
    width: calc(100% - var(--ax) * 52);
  }
}

.cart-item__remove {
  margin-left: auto;
  margin-right: calc(var(--ax) * 5 * -1);
  margin-top: calc(var(--ax) * 10 * -1);
}

@media only screen and (min-width: 990px) {
  .cart-item__remove {
    margin-top: calc(var(--ax) * 5 * -1);
  }
}

.cart-item__remove button {
  background: transparent;
  cursor: pointer;
  padding: calc(var(--ax) * 10);
  position: relative;
  z-index: 3;
}

.cart-item__remove svg {
  width: calc(var(--ax) * 10);
}

.cart-item__remove svg path {
  stroke: #272b1a;
}

.cart-item__price {
  margin: auto 0.3em 0.6em auto;
}

.cart-item__price .product-option {
  font-size: calc(var(--ax) * 16);
}

@media only screen and (min-width: 990px) {
  .cart-item__price {
    margin: auto 0.3em 0.3em auto;
  }
}

.cart-item__discounted-prices {
  display: flex;
  flex-direction: column;
}

.cart-item dd,
.cart-item dl {
  margin: 0;
}

.cart-item dd {
  display: inline-block;
}

.cart-item__quantity {
  margin-top: auto;
  padding-top: 0;
}

@media only screen and (min-width: 990px) {
  .cart-item__quantity .quantity {
    width: calc(var(--ax) * 100);
  }

  .cart-item__details,
  .cart-item__quantity {
    padding-left: calc(var(--ax) * 20);
  }
}

.cart-items .cart-items td {
  padding-top: 2rem;
}

.cart-items .cart-items th {
  border-bottom: 0.1rem solid #272b1a;
}

cart-drawer-items {
  flex: 1;
  margin-bottom: calc(var(--ax) * 14);
  overflow-y: scroll;
}

.circle {
  margin: calc(var(--ax) * 45) 0;
  position: relative;
  width: 100%;
}

.circle__text {
  bottom: 0;
  font-size: calc(var(--ax) * 14);
  font-weight: 500;
  height: max-content;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: max-content;
}

@media only screen and (min-width: 990px) {
  .circle__text {
    font-size: calc(var(--ax) * 20);
  }
}

.circle__svg {
  display: block;
  height: auto;
  overflow: visible;
  transform: scale(1.1043);
  transform-origin: center;
  width: 100%;
}

.circle__svg circle {
  position: relative;
  z-index: 2;
}

.deferred-media__poster {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.media > .deferred-media__poster {
  align-items: center;
  display: flex;
  justify-content: center;
}

.deferred-media__poster img {
  height: 100%;
  max-width: 100%;
  width: auto;
}

.deferred-media {
  overflow: hidden;
}

.deferred-media:not([loaded]) template {
  z-index: -1;
}

.deferred-media[loaded] > .deferred-media__poster {
  display: none;
}

.deferred-media__poster:focus-visible {
  outline: none;
}

.deferred-media__poster:focus {
  outline: none;
}

@media (forced-colors: active) {
  .deferred-media__poster:focus {
    outline: 1px solid transparent;
  }
}

.deferred-media__poster:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

.deferred-media__poster-button {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #272b1a;
  display: flex;
  height: 6.2rem;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.1 ease, color 0.1 ease;
  width: 6.2rem;
  z-index: 1;
}

.deferred-media__poster-button:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.deferred-media__poster-button .icon {
  height: 2rem;
  width: 2rem;
}

.deferred-media__poster-button .icon-play {
  margin-left: 0.2rem;
}

.details-modal__overlay {
  cursor: pointer;
}

.details-modal__toggle {
  list-style-type: none;
}

.no-js details[open] .details-modal__toggle {
  position: absolute;
  z-index: 5;
}

.details-modal__close.drawer-close {
  cursor: pointer;
  opacity: 1;
  pointer-events: unset;
  position: absolute;
}

.details-modal__content,
.details-modal__overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.details-modal__content {
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 5;
}

.details-modal__wrap {
  background: #fff;
  border-radius: calc(var(--ax) * 10);
  box-shadow: 0 4px 4px rgba(39, 43, 26, 0.25);
  margin-left: calc(var(--ax) * 7);
  max-width: calc(var(--ax) * 460);
  padding: calc(var(--ax) * 30);
  position: relative;
  width: calc(100% - var(--ax) * 14);
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .details-modal__wrap {
    align-items: center;
    display: flex;
    gap: calc(var(--ax) * 30);
    justify-content: center;
    max-width: calc(var(--ax) * 860);
    padding: calc(var(--ax) * 30);
  }
}

@media only screen and (max-width: 989px) {
  .details-modal__wrap {
    max-height: calc(100vh - var(--ax) * 150);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.details-modal__image {
  border-radius: calc(var(--ax) * 10);
  max-width: calc(var(--ax) * 300);
  overflow: hidden;
  width: 100%;
  width: calc(100% - var(--ax) * 48);
}

@media only screen and (min-width: 990px) {
  .details-modal__image {
    width: calc(50% - var(--ax) * 15);
  }
}

@media only screen and (max-width: 989px) {
  .details-modal__image {
    margin-bottom: calc(var(--ax) * 24);
  }
}

.details-modal__image img {
  position: static;
}

.details-modal__text {
  width: calc(100% - var(--ax) * 48);
}

@media only screen and (min-width: 990px) {
  .details-modal__text {
    max-height: calc(100vh - var(--ax) * 400);
    overflow-y: scroll;
    width: calc(50% - var(--ax) * 15);
  }
}

.details-modal__toggle-close {
  display: none;
}

.no-js details[open] .details-modal__toggle-close {
  display: flex;
  height: 1.7rem;
  width: 1.7rem;
  z-index: 1;
}

.details-modal__toggle-open {
  display: flex;
}

.no-js details[open] .details-modal__toggle-open {
  display: none;
}

.details-modal__close-button {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 4.4rem;
  justify-content: center;
  padding: 0;
  width: 4.4rem;
}

.no-js .details-modal__close-button {
  display: none;
}

.drawer {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: -1;
}

.drawer.animate {
  visibility: visible;
}

.drawer.active {
  z-index: 1000;
}

.drawer__inner {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: calc(var(--ax) * 60) calc(var(--ax) * 20) calc(var(--ax) * 22);
  transform: translateX(100%);
}

.drawer.animate .drawer__inner {
  transform: translateX(100%);
  transition: transform 0.15s linear;
}

.drawer.animate.active .drawer__inner {
  transform: translateX(0);
  transition: transform 0.25s linear;
}

@media only screen and (max-width: 989px) {
  .drawer__inner {
    height: 100%;
    margin: 0 0 0 auto;
    width: calc(100% - var(--ax) * 40);
  }
}

@media only screen and (max-width: 749px) {
  .drawer__inner {
    margin-bottom: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 990px) {
  .drawer__inner {
    height: 100vh;
    padding: calc(var(--ax) * 60) calc(var(--ax) * 36) calc(var(--ax) * 32);
    width: calc(var(--ax) * 525);
  }
}

.drawer__inner-empty {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0 calc(var(--ax) * 15);
}

.drawer__contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.drawer__footer {
  border-top: 0.1rem solid #272b1a;
}

.drawer__footer summary {
  display: flex;
  line-height: 1;
  padding: calc(var(--ax) * 15) 0;
  position: relative;
}

@media only screen and (min-width: 990px) {
  cart-drawer-items.is-empty + .drawer__footer {
    display: none;
  }

  .drawer__cart-items-wrapper {
    flex-grow: 1;
  }
}

.drawer-close {
  background: transparent;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  right: calc(var(--ax) * 23);
  top: calc(var(--ax) * 23);
  transition: opacity 0.1s ease-out;
  width: calc(var(--ax) * 14.4);
}

.cart-drawer .drawer-close,
.mobile-menu-open .header .drawer-close {
  cursor: pointer;
  opacity: 1;
  pointer-events: unset;
  transition: opacity 0.1s ease-out;
  transition-delay: 0.2s;
  z-index: 5;
}

@media only screen and (min-width: 990px) {
  .drawer-close {
    right: calc(var(--ax) * 35);
    top: calc(var(--ax) * 26);
    width: calc(var(--ax) * 13.38);
  }
}

@media only screen and (max-width: 989px) {
  .drawer-close {
    box-sizing: content-box;
    margin: calc(var(--ax) * -30);
    padding: calc(var(--ax) * 30);
  }
}

.klaviyo-email__form {
  font-size: calc(var(--ax) * 14);
  position: relative;
}

@media only screen and (min-width: 990px) {
  .klaviyo-email__form {
    font-size: calc(var(--ax) * 14);
  }
}

.klaviyo-email__input {
  background-color: #fff;
  border: 1px solid #272b1a;
  border-radius: 20rem;
  color: #272b1a;
  padding: 0.75em 1.73em 0.93em;
  width: 100%;
}

.klaviyo-email input::-webkit-input-placeholder,
.klaviyo-email input::placeholder,
.klaviyo-email input:not([type="submit"]) {
  font-family: Agenda, "sans-serif" !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1 !important;
  overflow: visible;
}

.klaviyo-email input::-webkit-input-placeholder,
.klaviyo-email input::placeholder,
.klaviyo-email__input:not([type="submit"]) {
  color: rgba(39, 43, 26, 0.5) !important;
  font-size: calc(var(--ax) * 14);
}

@media only screen and (min-width: 990px) {
  .klaviyo-email input::-webkit-input-placeholder,
  .klaviyo-email input::placeholder,
  .klaviyo-email__input:not([type="submit"]) {
    font-size: calc(var(--ax) * 14);
  }
}

.klaviyo-email__submit {
  background: transparent;
  cursor: pointer;
  font-size: 1em;
  height: 2.5em;
  position: absolute;
  right: 0.45em;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5em;
}

.klaviyo-email__spinner {
  opacity: 0;
}

.mega__overlay {
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media only screen and (min-width: 990px) {
  .mega__overlay {
    height: 100vh;
  }
}

.mega.menu-opening .mega__overlay {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.35s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.mega__content {
  background: #fff;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  left: 0;
  max-height: var(--viewport-height, 100vh);
  padding: calc(var(--ax) * 75) calc(var(--ax) * 15) calc(var(--ax) * 20);
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform 0.15s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 1;
}

.mega.menu-opening .mega__content {
  transform: translateX(0);
  transition: transform 0.25s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media only screen and (min-width: 990px) {
  .mega__content {
    padding: calc(var(--ax) * 90) calc(var(--ax) * 37) calc(var(--ax) * 38);
  }
}

@media only screen and (max-width: 989px) {
  .mega__content {
    flex-wrap: wrap;
    overflow-y: scroll;
  }

  .mobile-menu-open .mega__content {
    z-index: 4;
  }
}

.mega__list {
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.mega.menu-opening .mega__list {
  opacity: 1;
  transition: opacity 0.15s ease-out;
  transition-delay: 0.12s;
}

@media only screen and (min-width: 990px) {
  .mega__list {
    flex-shrink: 0;
  }

  .mega__content {
    align-items: stretch;
    padding: calc(var(--ax) * 90) calc(var(--ax) * 28) calc(var(--ax) * 38);
    transform: translateY(-100%);
  }

  .mega.menu-opening .mega__content {
    transform: translateY(0);
  }

  .mega__close {
    display: none;
  }
}

@media only screen and (max-width: 989px) {
  .mega ul.mega__list {
    display: block;
    width: 100%;
  }
}

.mobile-nav {
  align-items: center;
  background: #c3beb3;
  border-top: 1px solid rgba(39, 43, 26, 0.25);
  display: flex;
  height: calc(var(--viewport-height) - var(--header-height));
  justify-content: center;
  left: 0;
  padding: calc(var(--ax) * 20);
  pointer-events: none;
  position: absolute;
  top: var(--header-height);
  transform: translateX(-100%);
  transition: transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
  width: 100%;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .mobile-nav {
    display: none;
  }
}

.mobile-menu-open .mobile-nav {
  pointer-events: unset;
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.mobile-nav ul {
  position: relative;
  width: 100%;
  z-index: 2;
}

.mobile-nav__item {
  border-bottom: 1px solid rgba(39, 43, 26, 0.1);
  padding: calc(var(--ax) * 20) 0;
  position: relative;
}

.mobile-nav__item a {
  color: #272b1a;
  display: block;
  font-weight: 300;
  letter-spacing: 0.08em;
  padding: 0 calc(var(--ax) * 20);
}

.mobile-nav__item a[aria-expanded] {
  position: relative;
}

.mobile-nav__item a[aria-expanded]:before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  font-weight: 200;
  height: calc(var(--ax) * 10);
  opacity: 0.75;
  position: absolute;
  right: calc(var(--ax) * 20);
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: calc(var(--ax) * 10);
}

.notification {
  border-radius: calc(var(--ax) * 10);
  bottom: calc(var(--ax) * 20);
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  left: 0;
  margin: 0 auto;
  padding: calc(var(--ax) * 10);
  position: fixed;
  right: 0;
  transform: translateY(calc(100% + var(--ax) * 20));
  width: max-content;
  z-index: 15;
}

.notification,
.notification.show {
  transition: transform 0.2s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.notification.show {
  transform: translateY(0);
}

.rte:after {
  clear: both;
  content: "";
  display: block;
}

.rte > p:first-child {
  margin-top: 0;
}

.rte > p:last-child {
  margin-bottom: 0;
}

.rte table {
  table-layout: fixed;
}

@media only screen and (min-width: 990px) {
  .rte table td {
    padding-left: calc(var(--ax) * 12);
    padding-right: calc(var(--ax) * 12);
  }
}

.rte img {
  height: auto;
  margin-bottom: 3rem;
  max-width: 100%;
}

.rte ul {
  padding-left: calc(var(--ax) * 20);
}

.rte li {
  list-style: inherit;
}

.rte li:last-child {
  margin-bottom: 0;
}

.rte a {
  color: #272b1a;
  text-decoration-thickness: calc(var(--ax) * 1);
  text-underline-offset: calc(var(--ax) * 3);
  transition: text-decoration-thickness 0.1 ease;
}

.rte a:hover {
  color: #272b1a;
  text-decoration-thickness: calc(var(--ax) * 2);
}

.rte blockquote {
  display: inline-flex;
}

.rte blockquote > * {
  margin: calc(var(--ax) * 5 * -1) 0 calc(var(--ax) * 5 * -1) 0;
}

.shopify-policy__container {
  margin-top: calc(var(--ax) * 160);
  max-width: calc(var(--ax) * 800);
}

.shopify-policy__title {
  margin-bottom: calc(var(--ax) * 40);
}

.stars {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stars__item {
  display: block;
  height: auto;
  width: 1.5rem;
  width: clamp(1.2795rem, 3.999vw, 1.5rem);
}

@media only screen and (min-width: 990px) {
  .stars__item {
    width: 1.5rem;
    width: clamp(1.2rem, 1.0416vw, 1.5rem);
  }
}

.styled-select {
  cursor: pointer;
}

.styled-select__options {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  border: none !important;
  box-shadow: 1px 4px 15px 8px rgba(0, 0, 0, 0.19);
  cursor: pointer !important;
  display: none;
  padding: calc(var(--ax) * 15) 0;
  position: absolute;
  transform: translateY(calc(var(--ax) * 15));
  width: 100%;
  z-index: 10;
}

.styled-select__options.active {
  display: block;
}

.styled-select__option {
  background: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #e6e5e4;
  display: block;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-weight: 300;
  height: auto;
  line-height: 1;
  padding: calc(var(--ax) * 5) calc(var(--ax) * 10);
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .styled-select__option {
    font-size: calc(var(--ax) * 15);
  }
}

.styled-select__option.hover,
.styled-select__option:hover {
  background: #e6e5e4;
  color: #272b1a;
}

@media only screen and (min-width: 990px) {
  .styled-select__option:focus-visible {
    outline: 0.1rem solid #e6e5e4;
    outline-offset: 0;
  }
}

.styled-select button {
  background: transparent;
  display: block;
  width: 100%;
}

.thumbnails {
  height: 100%;
  padding: calc(var(--ax) * 10) 0;
  position: absolute;
}

.thumbnails,
.thumbnails__list {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.thumbnails__list {
  margin-left: 1rem;
}

.thumbnails__item {
  border-radius: 14.95%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.64);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.64);
  height: 0;
  list-style: none;
  margin: 0 calc(var(--ax) * 4) 0 0;
  opacity: 1;
  overflow: hidden;
  padding: 0 0 calc(var(--ax) * 35) 0;
  position: relative;
  transition: opacity 0.15s ease;
  width: calc(var(--ax) * 35);
  z-index: 2;
}

.thumbnails__item:hover {
  opacity: 0.85;
  transition: opacity 0.15s ease;
}

.thumbnails__item button {
  cursor: pointer;
  padding: 0;
  z-index: 2;
}

.thumbnails__item button:focus {
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media only screen and (min-width: 990px) {
  .thumbnails__item {
    margin: 0 calc(var(--ax) * 7) 0 0;
    padding-bottom: calc(var(--ax) * 70);
    width: calc(var(--ax) * 70);
  }
}

div#tripleseat_embed_form {
  color: #e6e5e4;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  padding: 0 calc(var(--ax) * 5);
  transition: max-height 0.6s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media only screen and (min-width: 990px) {
  div#tripleseat_embed_form {
    font-size: calc(var(--ax) * 16);
  }
}

[data-loaded="false"] div#tripleseat_embed_form {
  opacity: 0;
}

div#tripleseat_embed_form .styled-select__option {
  border: none;
}

div#tripleseat_embed_form table {
  box-shadow: none;
  display: block;
}

div#tripleseat_embed_form table tbody {
  display: block;
  width: 100%;
}

div#tripleseat_embed_form table td,
div#tripleseat_embed_form table th {
  border: none;
}

div#tripleseat_embed_form table td input,
div#tripleseat_embed_form table td input.error,
div#tripleseat_embed_form table td input::placeholder,
div#tripleseat_embed_form table td select,
div#tripleseat_embed_form table td textarea,
div#tripleseat_embed_form table th input,
div#tripleseat_embed_form table th input.error,
div#tripleseat_embed_form table th input::placeholder,
div#tripleseat_embed_form table th select,
div#tripleseat_embed_form table th textarea {
  border-color: #e6e5e4;
  box-sizing: border-box;
  color: #e6e5e4;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-weight: 300;
  letter-spacing: 0.0625em;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  div#tripleseat_embed_form table td input,
  div#tripleseat_embed_form table td input.error,
  div#tripleseat_embed_form table td input::placeholder,
  div#tripleseat_embed_form table td select,
  div#tripleseat_embed_form table td textarea,
  div#tripleseat_embed_form table th input,
  div#tripleseat_embed_form table th input.error,
  div#tripleseat_embed_form table th input::placeholder,
  div#tripleseat_embed_form table th select,
  div#tripleseat_embed_form table th textarea {
    font-size: calc(var(--ax) * 16);
  }
}

div#tripleseat_embed_form table td .timeselect_input,
div#tripleseat_embed_form table th .timeselect_input {
  cursor: pointer;
}

div#tripleseat_embed_form table td input,
div#tripleseat_embed_form table td input.error,
div#tripleseat_embed_form table td select,
div#tripleseat_embed_form table th input,
div#tripleseat_embed_form table th input.error,
div#tripleseat_embed_form table th select {
  padding: 0 calc(var(--ax) * 27);
}

div#tripleseat_embed_form table td input,
div#tripleseat_embed_form table td input.error,
div#tripleseat_embed_form table td textarea,
div#tripleseat_embed_form table th input,
div#tripleseat_embed_form table th input.error,
div#tripleseat_embed_form table th textarea {
  background: transparent;
}

div#tripleseat_embed_form table td input,
div#tripleseat_embed_form table td select,
div#tripleseat_embed_form table th input,
div#tripleseat_embed_form table th select {
  height: calc(var(--ax) * 50);
}

div#tripleseat_embed_form table td select,
div#tripleseat_embed_form table th select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

div#tripleseat_embed_form table td textarea,
div#tripleseat_embed_form table th textarea {
  min-height: calc(var(--ax) * 183);
  padding: calc(var(--ax) * 27);
  resize: none;
}

div#tripleseat_embed_form table td,
div#tripleseat_embed_form table th {
  display: block;
}

div#tripleseat_embed_form table td {
  padding: calc(var(--ax) * 6);
  position: relative;
  width: 50%;
}

div#tripleseat_embed_form table td:first-child {
  padding-left: 0;
}

div#tripleseat_embed_form table td:last-child {
  padding-right: 0;
}

div#tripleseat_embed_form table td[colspan="2"] {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

div#tripleseat_embed_form table tr {
  display: flex;
}

div#tripleseat_embed_form table #ui-datepicker-div {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  border: none !important;
  border-radius: 0;
  box-shadow: 1px 4px 15px 8px rgba(0, 0, 0, 0.19);
  cursor: pointer !important;
  left: 0 !important;
  padding: calc(var(--ax) * 15) 0;
  top: calc(var(--ax) * 50) !important;
  transform: translateY(calc(var(--ax) * 15));
  width: 100%;
  z-index: 10;
}

div#tripleseat_embed_form table #ui-datepicker-div thead {
  border: 1px solid #e6e5e4;
  display: block;
  margin: calc(var(--ax) * 6) auto;
  padding: calc(var(--ax) * 4) 0;
  width: calc(100% - var(--ax) * 30);
}

div#tripleseat_embed_form table #ui-datepicker-div tbody {
  margin: calc(var(--ax) * 15) 0;
}

div#tripleseat_embed_form table #ui-datepicker-div th {
  color: #e6e5e4;
  width: 50%;
}

div#tripleseat_embed_form table #ui-datepicker-div th span {
  font-weight: 300;
  text-transform: uppercase;
}

div#tripleseat_embed_form table #ui-datepicker-div td .ui-state-default {
  background: transparent;
  border: none;
  color: #e6e5e4;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  text-align: center;
}

@media only screen and (min-width: 990px) {
  div#tripleseat_embed_form table #ui-datepicker-div td .ui-state-default {
    font-size: calc(var(--ax) * 16);
  }
}

div#tripleseat_embed_form
  table
  #ui-datepicker-div
  td
  .ui-state-default.ui-state-active {
  background: #e6e5e4;
  color: #272b1a;
}

div#tripleseat_embed_form table #ui-datepicker-div td .ui-state-active,
div#tripleseat_embed_form table #ui-datepicker-div td .ui-state-highlight {
  font-style: normal;
}

div#tripleseat_embed_form table #ui-datepicker-div td a:hover {
  outline: 1px solid #e6e5e4;
  text-decoration: none;
}

div#tripleseat_embed_form table #ui-datepicker-div tr {
  padding: 0 calc(var(--ax) * 15);
}

div#tripleseat_embed_form table #ui-datepicker-div .ui-datepicker-next,
div#tripleseat_embed_form table #ui-datepicker-div .ui-datepicker-prev {
  display: none;
}

div#tripleseat_embed_form table #ui-datepicker-div table .ui-widget-content {
  background: transparent;
}

div#tripleseat_embed_form table #ui-datepicker-div .styled-select__options {
  left: calc(var(--ax) * 14);
}

div#tripleseat_embed_form
  table
  #ui-datepicker-div
  .ui-datepicker-year
  + .styled-select__options {
  left: unset;
  right: calc(var(--ax) * 14);
}

div#tripleseat_embed_form
  table
  #ui-datepicker-div
  button.styled-select__option {
  background: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #e6e5e4;
  display: block;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-weight: 300;
  font-weight: 500;
  height: auto;
  line-height: 1;
  padding: calc(var(--ax) * 5) calc(var(--ax) * 10);
  width: 100%;
}

@media only screen and (min-width: 990px) {
  div#tripleseat_embed_form
    table
    #ui-datepicker-div
    button.styled-select__option {
    font-size: calc(var(--ax) * 15);
  }
}

div#tripleseat_embed_form
  table
  #ui-datepicker-div
  button.styled-select__option.hover,
div#tripleseat_embed_form
  table
  #ui-datepicker-div
  button.styled-select__option:hover {
  background: #e6e5e4;
  color: #272b1a;
}

div#tripleseat_embed_form table #ui-datepicker-div .ui-datepicker-buttonpane {
  display: none;
}

div#tripleseat_embed_form table #ui-datepicker-div .ui-datepicker-title {
  display: flex;
  margin: 0;
  padding: 0 calc(var(--ax) * 15);
}

div#tripleseat_embed_form table #ui-datepicker-div .ui-datepicker-title select {
  padding: 0 calc(var(--ax) * 27);
  width: calc(50% - var(--ax) * 6);
}

div#tripleseat_embed_form
  table
  #ui-datepicker-div
  .ui-datepicker-title
  select:first-child {
  margin-right: auto;
}

div#tripleseat_embed_form
  table
  #ui-datepicker-div
  .ui-datepicker-title
  select:last-child {
  margin-left: auto;
}

div#tripleseat_embed_form table #ui-datepicker-div .ui-widget-header {
  background: none;
  border: none;
}

div#tripleseat_embed_form table #lead_guest_count,
div#tripleseat_embed_form table #lead_phone_number {
  width: 100% !important;
}

div#tripleseat_embed_form table #lead_phone_number_extension {
  min-width: calc(var(--ax) * 100);
}

div#tripleseat_embed_form table label {
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-weight: 300;
  letter-spacing: 0.0625em;
}

@media only screen and (min-width: 990px) {
  div#tripleseat_embed_form table label {
    font-size: calc(var(--ax) * 16);
  }
}

div#tripleseat_embed_form table label .required {
  display: none;
}

div#tripleseat_embed_form .row_details_header {
  margin-top: calc(var(--ax) * 30);
}

div#tripleseat_embed_form #lead_form_submit a,
div#tripleseat_embed_form .danger {
  display: none !important;
}

body div.timeselect {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  border: none !important;
  box-shadow: 1px 4px 15px 8px rgba(0, 0, 0, 0.19);
  cursor: pointer !important;
  padding: calc(var(--ax) * 15) 0;
  transform: translateY(calc(var(--ax) * 15));
  transform: translateY(calc(var(--ax) * 5));
  width: 100%;
  z-index: 10;
}

body div.timeselect a {
  background: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #e6e5e4;
  display: block;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 14);
  font-weight: 300;
  height: auto;
  line-height: 1;
  padding: calc(var(--ax) * 5) calc(var(--ax) * 10);
  width: 100%;
}

@media only screen and (min-width: 990px) {
  body div.timeselect a {
    font-size: calc(var(--ax) * 15);
  }
}

body div.timeselect a.hover,
body div.timeselect a:hover {
  background: #e6e5e4;
  color: #272b1a;
}

.errorExplanation {
  background: #fff !important;
}

.tripleseat-continue {
  margin-left: calc(var(--ax) * 5);
  margin-top: calc(var(--ax) * 8);
}

.text-block__eyebrow + .text-block__heading {
  margin-top: calc(var(--ax) * 25);
}

@media only screen and (min-width: 990px) {
  .text-block__eyebrow + .text-block__heading {
    margin-top: calc(var(--ax) * 34);
  }
}

.text-block__heading + .text-block__text {
  margin-top: calc(var(--ax) * 18);
}

@media only screen and (min-width: 990px) {
  .text-block__heading + .text-block__text {
    margin-top: calc(var(--ax) * 30);
  }
}

.text-block__text p {
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  letter-spacing: -0.02em;
}

@media only screen and (min-width: 990px) {
  .text-block__text p {
    font-size: calc(var(--ax) * 16);
  }
}

.text-block__button-group {
  column-gap: calc(var(--ax) * 14);
}

@media only screen and (min-width: 990px) {
  .text-block__button-group {
    column-gap: calc(var(--ax) * 28);
  }
}

.text-block__button {
  margin-top: calc(var(--ax) * 20);
}

@media only screen and (min-width: 990px) {
  .text-block__button {
    margin-top: calc(var(--ax) * 30);
  }
}

.text-block__above-button-text {
  margin-top: calc(var(--ax) * 20);
  width: max-content;
}

@media only screen and (min-width: 990px) {
  .text-block__above-button-text {
    margin-top: calc(var(--ax) * 30);
  }
}

.accordion {
  border-bottom: 1px solid rgba(39, 43, 26, 0.2);
  border-top: 1px solid rgba(39, 43, 26, 0.2);
  display: block;
  margin-bottom: 0;
  margin-top: calc(var(--ax) * 40);
}

.accordion__title {
  display: inline-block;
  margin: 0;
  min-height: calc(var(--ax) * 16);
  position: relative;
  word-break: break-word;
}

.accordion__title h3 {
  font-family: Agenda, "sans-serif";
  font-weight: 700;
  letter-spacing: 0.04em;
  max-width: calc(100% - var(--ax) * 60);
}

@media only screen and (max-width: 989px) {
  .accordion__title h3 {
    line-height: 1.4;
  }
}

.accordion__content {
  padding: 0 0 calc(var(--ax) * 20);
}

.accordion__content img {
  max-width: 100%;
}

.accordion summary {
  cursor: pointer;
  display: flex;
  line-height: 1;
  padding: calc(var(--ax) * 20) 0;
  position: relative;
}

.accordion + .accordion {
  border-top: none;
  margin-top: 0;
}

.accordion details[open] > summary .icon-caret {
  transform: rotate(180deg);
}

.accordion .summary__title {
  align-items: center;
  display: flex;
}

.accordion .summary__title + .icon-caret {
  height: calc(var(--font-heading-scale) * var(--ax) * 6);
}

.accordion .icon-accordion {
  fill: #272b1a;
  align-self: center;
  height: calc(var(--font-heading-scale) * var(--ax) * 20);
  margin-right: calc(var(--font-heading-scale) * var(--ax) * 10);
  width: calc(var(--font-heading-scale) * var(--ax) * 20);
}

.collapsible-content {
  position: relative;
  z-index: 0;
}

.collapsible-content__media--small {
  height: 19.4rem;
}

.collapsible-content__media--large {
  height: 43.5rem;
}

@media only screen and (min-width: 750px) {
  .collapsible-content__media--small {
    height: 31.4rem;
  }

  .collapsible-content__media--large {
    height: 69.5rem;
  }

  .collapsible-content__grid--reverse {
    flex-direction: row-reverse;
  }
}

.collapsible-content-wrapper-narrow {
  margin: 0 auto;
  max-width: 73.4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.collapsible-content__header {
  word-break: break-word;
}

.collapsible-content__heading {
  margin-bottom: 2rem;
  margin-top: 0;
}

@media only screen and (min-width: 750px) {
  .collapsible-content__heading {
    margin-bottom: 3rem;
  }
}

.collapsible-none-layout .accordion + .accordion {
  border-top: 0;
}

.collapsible-row-layout .accordion:not(:first-child):not(.color-background-1) {
  margin-top: 1rem;
}

@media only screen and (min-width: 750px) {
  .collapsible-content .accordion {
    margin-top: 0;
  }
}

.collapsible-row-layout .accordion {
  border: var(--text-boxes-border-width) solid
    rgba(var(--color-foreground), var(--text-boxes-border-opacity));
  margin-bottom: 1.5rem;
}

.collapsible-row-layout .accordion .accordion__content,
.collapsible-row-layout .accordion summary {
  padding: 1.5rem;
}

.collapsible-row-layout .accordion .accordion__content {
  padding-top: 0;
}

.collapsible-content summary:hover {
  background: rgba(var(--color-foreground), 0.04);
}

.collapsible-content summary:hover .accordion__title {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.card-wrapper {
  color: inherit;
  height: 100%;
  position: relative;
  padding-bottom: 40px;
}

.card,
.card-wrapper {
  text-decoration: none;
}

.card {
  text-align: var(--text-alignment);
}

.card:not(.ratio) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card--card {
  height: 100%;
}

.card__inner {
  border: var(--border-width) solid
    rgba(var(--color-foreground), var(--border-opacity));
  border-radius: 0;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.card__inner:not(.ratio) > .card__content {
  height: 100%;
}

.card__media {
  border-radius: calc(0 - var(--border-width) - var(--image-padding));
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.card__media__link {
  display: block !important;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card__content {
  display: grid;
  flex-grow: 1;
  grid-template-rows: minmax(0, 1fr) max-content minmax(0, 1fr);
  padding: 1rem;
  position: relative;
  width: 100%;
}

.card__media {
  margin: var(--image-padding) var(--image-padding) 0;
  width: calc(100% - var(--image-padding) * 2);
}

.card .media,
.card__media {
  bottom: 0;
  position: absolute;
  top: 0;
}

.card .media {
  width: 100%;
}

.card .media img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}

.card__information {
  grid-row-start: 2;
  padding-bottom: calc(var(--ax) * 15);
  padding-top: calc(var(--ax) * 15);
}

.card:not(.ratio) > .card__content {
  grid-template-rows: max-content minmax(0, 1fr) max-content auto;
}

.card__badge {
  align-self: flex-end;
  grid-row-start: 3;
  justify-self: flex-start;
  margin: 1.3rem;
}

.card__heading {
  margin-top: 0;
}

.card__heading,
.card__heading:last-child {
  margin-bottom: 0;
}

.card--card.card--media > .card__content {
  margin-top: calc(0rem - var(--image-padding));
}

.card--card .card__heading a:after,
.card--standard.card--text a:after {
  bottom: calc(var(--border-width) * -1);
  left: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  top: calc(var(--border-width) * -1);
}

.card__heading a {
  position: relative;
}

.card__heading a:after {
  bottom: 0;
  content: "";
  left: 0;
  outline-offset: 0.3rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.card__heading a:focus:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
}

.card__heading a:focus-visible:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
}

.card__heading a:focus:not(:focus-visible):after {
  box-shadow: none;
  outline: 0;
}

.card__heading a:focus {
  box-shadow: none;
  outline: 0;
}

@media only screen and (min-width: 990px) {
  .card .media.media--hover-effect > img:only-child,
  .card-wrapper .media.media--hover-effect > img:only-child {
    transition: transform var(--duration-long) ease;
  }

  .card-wrapper:hover .media.media--hover-effect > img:first-child:only-child,
  .card:hover .media.media--hover-effect > img:first-child:only-child {
    transform: scale(1.03);
  }

  .card-wrapper:hover
    .media.media--hover-effect
    > img:first-child:not(:only-child) {
    opacity: 0;
  }

  .card-wrapper:hover .media.media--hover-effect > img + img {
    opacity: 1;
    transform: scale(1.03);
    transition: transform var(--duration-long) ease;
  }

  .underline-links-hover a:hover {
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }
}

.card--standard.card--media .card__inner .card__information,
.card--standard.card--text.article-card > .card__content .card__information,
.card--standard.card--text:not(.card--horizontal)
  > .card__content
  .card__heading,
.card--standard:not(.card--horizontal) > .card__content .card__badge,
.card--standard > .card__content .card__caption {
  display: none;
}

.card--standard > .card__content {
  padding: 0 var(--image-padding);
}

.card--standard > .card__content .card__information {
  padding-left: 0;
  padding-right: 0;
}

.active-facets-mobile {
  margin-bottom: 0.5rem;
}

.mobile-facets__list {
  overflow-y: auto;
}

@media only screen and (min-width: 750px) {
  .facets-container > * + * {
    margin-top: 0;
  }

  .facets__form .product-count {
    align-self: flex-start;
    grid-column-start: 3;
  }
}

@media screen and (max-width: 989px) {
  .facets-container {
    column-gap: 2rem;
    grid-template-columns: auto minmax(0, max-content);
  }
}

.facet-filters {
  align-items: flex-start;
  display: flex;
  grid-column: 2;
  grid-row: 1;
  padding-left: 2.5rem;
}

@media only screen and (min-width: 990px) {
  .facet-filters {
    padding-left: 3rem;
  }
}

.facet-filters__label {
  display: block;
  margin: 0 2em 0 0;
}

@media only screen and (max-width: 989px) {
  .facet-filters__label {
    display: none;
  }
}

.facet-filters__summary {
  align-items: center;
  border: 0.1rem solid rgba(var(--color-foreground), 0.55);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.facet-filters__summary:after {
  position: static;
}

.facet-filters__field {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.facet-filters__field .select {
  width: auto;
}

.facet-filters__field .select:after,
.facet-filters__field .select:before,
.mobile-facets__sort .select:after,
.mobile-facets__sort .select:before {
  content: none;
}

.facet-filters__field .select__select,
.mobile-facets__sort .select__select {
  border-radius: 0;
  min-height: auto;
  min-width: auto;
  transition: none;
}

.facet-filters button {
  margin-left: 2.5rem;
}

.facet-filters__sort {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  height: auto;
  margin: 0;
  padding-left: 0;
}

.facet-filters__sort + .icon-caret {
  right: 0;
}

@media screen and (forced-colors: active) {
  .facet-filters__sort {
    border: none;
  }
}

.facet-filters__sort,
.facet-filters__sort:hover {
  box-shadow: none;
  filter: none;
  transition: none;
}

.mobile-facets__sort .select__select:focus-visible {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
}

.mobile-facets__sort .select__select.focused,
.no-js .mobile-facets__sort .select__select:focus {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
}

.facet-filters__sort:focus-visible {
  box-shadow: 0 0 0 1rem rgb(var(--color-background)),
    0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
}

.facet-filters__sort.focused,
.no-js .facet-filters__sort:focus {
  box-shadow: 0 0 0 1rem rgb(var(--color-background)),
    0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
}

.no-js .facet-filters__sort:focus:not(:focus-visible),
.no-js .mobile-facets__sort .select__select:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

.facets {
  display: block;
  grid-column-start: span 2;
}

.facets__form {
  display: grid;
  gap: 0 3.5rem;
  grid-template-columns: 1fr max-content max-content;
  margin-bottom: 0.5rem;
}

.facets__wrapper {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  grid-column: 1;
  grid-row: 1;
}

.facets__heading {
  display: block;
  margin: 0 2em 0 0;
}

@media only screen and (max-width: 989px) {
  .facets__heading {
    display: none;
  }
}

.facets__reset {
  margin-left: auto;
}

.facets__disclosure {
  margin-right: 3.5rem;
}

.facets__summary {
  padding: 0 1.5em 0 0;
  position: relative;
  z-index: 1;
}

.facets__disclosure fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.facets__disclosure[open] .facets__summary,
.facets__summary:hover {
  color: #272b1a;
}

.facets__disclosure-vertical[open] .facets__display-vertical,
.facets__disclosure[open] .facets__display {
  animation: animateMenuOpen var(--duration-default) ease;
}

.disclosure-has-popup[open] > .facets__summary:before {
  z-index: 2;
}

.facets__summary .icon-caret {
  right: 0;
}

.facets__display {
  background-color: rgb(var(--color-background));
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  left: 0;
  max-height: 55rem;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + var(--ax) * 5);
  width: calc(var(--ax) * 350);
}

@media only screen and (max-width: 989px) {
  .facets__display {
    left: calc(var(--ax) * 15);
    max-height: var(--viewport-height);
    position: fixed;
    top: calc(var(--header-height) + var(--ax) * 30);
    width: calc(100% - var(--ax) * 30);
    z-index: 3;
  }
}

.facets__header {
  background-color: rgb(var(--color-background));
  border-bottom: 1px solid rgba(var(--color-foreground), 0.2);
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.facets__list {
  padding: 0.5rem 2rem;
}

.facets__item {
  align-items: center;
  display: flex;
}

.facets__item input[type="checkbox"],
.facets__item label {
  cursor: pointer;
}

.facet-checkbox {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 1rem;
  padding: 1rem 2rem 1rem 0;
  position: relative;
  word-break: break-word;
}

.facet-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 1.6rem;
  left: -0.4rem;
  opacity: 1;
  position: absolute;
  top: 0.7rem;
  width: 1.6rem;
  z-index: -1;
}

.no-js .facet-checkbox input[type="checkbox"] {
  z-index: 0;
}

.facet-checkbox > svg {
  background-color: rgb(var(--color-background));
  flex-shrink: 0;
  margin-right: 1.2rem;
}

.facet-checkbox .icon-checkmark {
  left: 0.3rem;
  position: absolute;
  top: 1.4rem;
  visibility: hidden;
  z-index: 5;
}

.facet-checkbox > input[type="checkbox"]:checked ~ .icon-checkmark {
  visibility: visible;
}

@media screen and (forced-colors: active) {
  .facet-checkbox > svg {
    background-color: inherit;
    border: 0.1rem solid rgb(var(--color-background));
  }

  .facet-checkbox > input[type="checkbox"]:checked ~ .icon-checkmark {
    border: none;
  }
}

.facet-checkbox--disabled {
  color: rgba(var(--color-foreground), 0.4);
}

.facets__price {
  display: flex;
  padding: 2rem;
}

.facets__price .field__input {
  min-width: unset;
  padding: calc(var(--ax) * 5);
}

.facets__price .field + .field-currency {
  margin-left: 2rem;
}

.facets__price .field {
  align-items: center;
}

.facets__price .field-currency {
  align-self: center;
  margin-right: 0.6rem;
}

.facets__price .field__label {
  left: 1.5rem;
}

button.facets__button {
  box-shadow: none;
  margin: 0 0 0 0.5rem;
  min-height: 0;
  padding-bottom: 1.4rem;
  padding-top: 1.4rem;
}

.facets__button-no-js {
  transform: translateY(-0.6rem);
}

.active-facets {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1/-1;
  grid-row: 2;
  margin-top: -0.5rem;
  width: 100%;
}

.active-facets__button {
  display: block;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  text-decoration: none;
}

span.active-facets__button-inner {
  align-items: center;
  box-shadow: 0 0 0 0.1rem #272b1a;
  color: #272b1a;
  display: flex;
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  min-height: 0;
  min-width: 0;
  padding: 0.5em 1em;
}

@media only screen and (min-width: 990px) {
  span.active-facets__button-inner {
    font-size: 1rem;
    font-size: clamp(1rem, 0.6944vw, 1rem);
  }
}

span.active-facets__button-inner:after,
span.active-facets__button-inner:before {
  display: none;
}

.active-facets__button-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.active-facets__button-wrapper * {
  font-size: 1rem;
}

@media only screen and (min-width: 990px) {
  .active-facets__button {
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 989px) {
  .active-facets {
    margin: 0 -1.2rem -1.2rem;
  }

  .active-facets__button,
  .active-facets__button-remove {
    margin: 0;
    padding: 1.2rem;
  }

  span.active-facets__button-inner {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
  }

  .active-facets__button-wrapper {
    margin-left: 1.2rem;
    padding-top: 0;
  }
}

a.active-facets__button:focus-visible {
  box-shadow: none;
  outline: none;
}

@media (forced-colors: active) {
  a.active-facets__button:focus-visible {
    outline: 1px solid transparent;
  }
}

.no-js a.active-facets__button:focus,
a.active-facets__button.focused {
  box-shadow: none;
  outline: none;
}

a.active-facets__button:focus-visible .active-facets__button-inner {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2),
    0 0 0 0.2rem rgb(var(--color-background)), 0 0 0 0.4rem #272b1a;
  outline: none;
}

.no-js a.active-facets__button:focus .active-facets__button-inner,
a.active-facets__button.focused .active-facets__button-inner {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2),
    0 0 0 0.2rem rgb(var(--color-background)), 0 0 0 0.4rem #272b1a;
  outline: none;
}

.active-facets__button svg {
  align-self: center;
  flex-shrink: 0;
  margin-left: 0.6rem;
  margin-right: -0.2rem;
  pointer-events: none;
  width: 1.2rem;
}

@media (min-width: 990px) {
  .active-facets__button svg {
    margin-right: -0.4rem;
    margin-top: 0.1rem;
    width: 1.4rem;
  }
}

.active-facets facet-remove:only-child,
.facets-vertical
  .active-facets
  .active-facets-vertical-filter:only-child
  > facet-remove {
  display: none;
}

.facets-vertical .active-facets-vertical-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.facets-vertical .active-facets-vertical-filter .active-facets__button-wrapper {
  align-items: flex-start;
  display: flex;
  padding-top: 0;
}

.facets-vertical .active-facets__button {
  margin-top: 0;
}

.active-facets__button.disabled,
.mobile-facets__clear.disabled {
  pointer-events: none;
}

.mobile-facets__clear-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.mobile-facets {
  background-color: rgba(var(--color-foreground), 0.5);
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.mobile-facets__disclosure {
  display: flex;
}

.mobile-facets__wrapper {
  display: flex;
  margin-left: 0;
}

.mobile-facets__wrapper .disclosure-has-popup[open] > summary:before {
  height: 100vh;
  z-index: 3;
}

.mobile-facets__inner {
  background-color: rgb(var(--color-background));
  border-color: rgba(var(--color-foreground), var(--drawer-border-opacity));
  border-style: solid;
  border-width: 0 0 0 var(--drawer-border-width);
  display: flex;
  filter: drop-shadow(
    var(--drawer-shadow-horizontal-offset) var(--drawer-shadow-vertical-offset)
      var(--drawer-shadow-blur-radius)
      rgba(var(--color-shadow), var(--drawer-shadow-opacity))
  );
  flex-direction: column;
  height: 100%;
  margin-left: auto;
  max-width: 37.5rem;
  overflow-y: auto;
  pointer-events: all;
  transition: transform var(--duration-short) ease;
  width: calc(100% - 5rem);
}

.menu-opening .mobile-facets__inner {
  transform: translateX(0);
}

.js .disclosure-has-popup:not(.menu-opening) .mobile-facets__inner {
  transform: translateX(105vw);
}

.mobile-facets__header {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  display: flex;
  padding: 1rem 2.5rem;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 2;
}

.mobile-facets__header-inner {
  flex-grow: 1;
  position: relative;
}

.mobile-facets__info {
  padding: 0 2.6rem;
}

.mobile-facets__heading {
  font-size: calc(var(--font-heading-scale) * 1.4rem);
  margin: 0;
}

.mobile-facets__count {
  color: rgba(var(--color-foreground), 0.7);
  flex-grow: 1;
  font-size: 1.3rem;
  margin: 0;
}

.mobile-facets__open-wrapper {
  display: inline-block;
}

.mobile-facets__open {
  align-items: center;
  color: rgba(var(--color-foreground), 1);
  display: flex;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: left;
  width: 100%;
}

.mobile-facets__open:hover {
  color: #272b1a;
}

.mobile-facets__open:hover circle,
.mobile-facets__open:hover line {
  stroke: #272b1a;
}

.mobile-facets__open-label {
  transition: text-decoration var(--duration-short) ease;
}

.mobile-facets__open:hover .mobile-facets__open-label {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.mobile-facets__open > * + * {
  margin-left: 1rem;
}

.mobile-facets__open svg {
  width: 2rem;
}

.mobile-facets__open circle,
.mobile-facets__open line {
  stroke: rgba(var(--color-foreground), 1);
}

.mobile-facets__close {
  align-items: center;
  display: none;
  height: 4.4rem;
  justify-content: center;
  opacity: 0;
  position: fixed;
  right: 1rem;
  top: 0.7rem;
  transition: opacity var(--duration-short) ease;
  width: 4.4rem;
  z-index: 101;
}

.mobile-facets__close svg {
  width: 2.2rem;
}

details.menu-opening .mobile-facets__close {
  display: flex;
  opacity: 1;
}

details.menu-opening .mobile-facets__close svg {
  margin: 0;
}

.mobile-facets__close-button {
  align-items: center;
  background-color: transparent;
  display: flex;
  font-size: 1.4rem;
  font: inherit;
  letter-spacing: inherit;
  margin-top: 1.5rem;
  padding: 1.2rem 2.6rem;
  text-decoration: none;
  width: calc(100% - 5.2rem);
}

.no-js .mobile-facets__close-button {
  display: none;
}

.mobile-facets__close-button .icon-arrow {
  margin-right: 1rem;
  transform: rotate(180deg);
}

.mobile-facets__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 2.7rem 0 0;
  position: relative;
  z-index: 1;
}

.mobile-facets__details[open] .icon-caret {
  transform: rotate(180deg);
}

.no-js .mobile-facets__details {
  border-bottom: 1px solid rgba(var(--color-foreground), 0.04);
}

.mobile-facets__highlight {
  opacity: 0;
  visibility: hidden;
}

.mobile-facets__checkbox:checked + .mobile-facets__highlight {
  background-color: rgba(var(--color-foreground), 0.04);
  bottom: 0;
  display: block;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  visibility: visible;
}

.mobile-facets__summary {
  padding: 1.3rem 2.5rem;
}

.mobile-facets__summary svg {
  margin-left: auto;
}

.facets__summary > div,
.mobile-facets__summary > div {
  align-items: center;
  display: flex;
}

.js .mobile-facets__submenu {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  visibility: hidden;
  width: 100%;
  z-index: 3;
}

.js details[open] > .mobile-facets__submenu {
  transition: transform 0.4s cubic-bezier(0.29, 0.63, 0.44, 1),
    visibility 0.4s cubic-bezier(0.29, 0.63, 0.44, 1);
}

.js details[open].menu-opening > .mobile-facets__submenu {
  transform: translateX(0);
  visibility: visible;
}

.js .menu-drawer__submenu .mobile-facets__submenu {
  overflow-y: auto;
}

.js .mobile-facets .submenu-open {
  visibility: hidden;
}

.mobile-facets__item {
  position: relative;
}

input.mobile-facets__checkbox {
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  height: 1.6rem;
  left: 2.1rem;
  position: absolute;
  top: 1.2rem;
  width: 1.6rem;
  z-index: 0;
}

.mobile-facets__label {
  display: flex;
  padding: 1.5rem 2rem 1.5rem 2.5rem;
  transition: background-color 0.2s ease;
  width: 100%;
  word-break: break-word;
}

.mobile-facets__label > svg {
  background-color: rgb(var(--color-background));
  flex-shrink: 0;
  margin-right: 1.2rem;
  position: relative;
  z-index: 2;
}

.mobile-facets__label .icon-checkmark {
  left: 2.8rem;
  position: absolute;
  top: 1.9rem;
  visibility: hidden;
}

.mobile-facets__label > input[type="checkbox"]:checked ~ .icon-checkmark {
  visibility: visible;
}

.mobile-facets__arrow,
.mobile-facets__summary .icon-caret {
  display: block;
  margin-left: auto;
}

.mobile-facets__label--disabled {
  opacity: 0.5;
}

.mobile-facets__footer {
  background-color: rgb(var(--color-background));
  background: var(--gradient-background);
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
  bottom: 0;
  display: flex;
  margin-top: auto;
  padding: 2rem;
  position: sticky;
  z-index: 2;
}

.mobile-facets__footer > * + * {
  margin-left: 1rem;
}

.mobile-facets__footer > * {
  width: 50%;
}

.mobile-facets__footer noscript .button {
  width: 100%;
}

.mobile-facets__sort {
  display: flex;
  justify-content: space-between;
}

.mobile-facets__sort label {
  flex-shrink: 0;
}

.mobile-facets__sort .select {
  width: auto;
}

.no-js .mobile-facets__sort .select {
  position: relative;
  right: -1rem;
}

.mobile-facets__sort .select .icon-caret {
  right: 0;
}

.mobile-facets__sort .select__select {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
}

.product-count {
  align-self: center;
  display: none;
  position: relative;
  text-align: right;
}

.product-count__text {
  font-size: 1.4rem;
  line-height: calc(1 + 0.5 / var (--font-body-scale));
  margin: 0;
}

.product-count__text.loading {
  visibility: hidden;
}

.product-count .loading-overlay__spinner,
.product-count-vertical .loading-overlay__spinner {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.8rem;
}

.product-count__text.loading + .loading-overlay__spinner {
  display: block;
}

@media only screen and (min-width: 750px) {
  .facets-vertical {
    display: flex;
  }

  .facets-wrap-vertical {
    border: none;
    padding-left: 0;
  }

  .facets__form-vertical {
    display: flex;
    flex-direction: column;
    width: 26rem;
  }

  .facets__disclosure-vertical {
    border-top: 0.1rem solid rgba(var(--color-foreground), 0.1);
    margin-right: 0;
  }

  .facets-vertical .facets__summary {
    margin-bottom: 0;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .facets__heading--vertical {
    font-size: 1.5rem;
    margin: 0 0 1.5rem;
  }

  .facets__header-vertical {
    font-size: 1.4rem;
    padding: 1.5rem 2rem 1.5rem 0;
  }

  .facets__display-vertical {
    padding-bottom: 1.5rem;
  }

  .facets-vertical .facets-wrapper {
    padding-right: 3rem;
  }

  .facets-vertical .facets-wrapper--no-filters {
    display: none;
  }

  .no-js .facets-vertical .facets-wrapper--no-filters {
    display: block;
  }

  .facets-vertical .product-grid-container {
    width: 100%;
  }

  .facets-vertical-form {
    display: flex;
    justify-content: flex-end;
  }

  .product-count-vertical {
    margin-left: 3.5rem;
  }

  .facets-vertical .active-facets__button-wrapper {
    margin-bottom: 2rem;
  }

  .facets-vertical .no-js .facets__button-no-js {
    margin-left: 0;
    transform: none;
  }

  .facets-vertical .no-js .facet-filters__field {
    justify-content: flex-start;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }

  .facets-vertical .facets__price {
    padding: 0.5rem 0.5rem 0.5rem 0;
  }

  .facets-vertical .facets__price .field:last-of-type {
    margin-left: 1rem;
  }

  .facets-vertical .active-facets__button {
    margin-bottom: 1.5rem;
  }

  .no-js .facets-vertical .facet-filters.sorting {
    flex-direction: column;
    padding-left: 0;
  }

  .facets-vertical .facet-checkbox input[type="checkbox"] {
    z-index: 0;
  }

  .no-js .facets-vertical .facets-container {
    display: flex;
    flex-direction: column;
  }

  .facets-vertical .active-facets facet-remove:last-of-type {
    margin-bottom: 1rem;
  }

  .facets-vertical .active-facets {
    align-items: flex-start;
    margin: 0;
  }

  .facets__disclosure-vertical[open] .facets__summary .icon-caret {
    transform: rotate(180deg);
  }

  .facets-container-drawer {
    align-items: center;
    column-gap: 0;
    display: flex;
    flex-flow: row wrap;
  }

  .facets-container-drawer .mobile-facets__wrapper {
    flex-grow: 1;
    margin-right: 2rem;
  }

  .facets-container-drawer .product-count {
    margin: 0 0 0.5rem 3.5rem;
  }

  .facets-container-drawer .facets-pill {
    width: 100%;
  }

  .facets-container-drawer .facets__form {
    display: block;
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .facets-vertical .active-facets__button {
    margin-bottom: 0;
    margin-left: -0.5rem;
    padding: 1rem;
  }

  .facets-vertical .active-facets__button-remove {
    padding: 0 1rem 1rem;
  }
}

.sorting {
  display: none !important;
}

.customer .field input:-webkit-autofill ~ label,
.customer .field input:focus ~ label,
.customer .field input:not(:placeholder-shown) ~ label,
.field__input:-webkit-autofill ~ .field__label,
.field__input:focus ~ .field__label,
.field__input:not(:placeholder-shown) ~ .field__label {
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  left: 2.1rem;
  letter-spacing: 0.04rem;
  top: 0.8em;
}

@media only screen and (min-width: 990px) {
  .customer .field input:-webkit-autofill ~ label,
  .customer .field input:focus ~ label,
  .customer .field input:not(:placeholder-shown) ~ label,
  .field__input:-webkit-autofill ~ .field__label,
  .field__input:focus ~ .field__label,
  .field__input:not(:placeholder-shown) ~ .field__label {
    font-size: 1rem;
    font-size: clamp(1rem, 0.6944vw, 1rem);
  }
}

.customer .field input:-webkit-autofill,
.customer .field input:focus,
.customer .field input:not(:placeholder-shown),
.field__input:-webkit-autofill,
.field__input:focus,
.field__input:not(:placeholder-shown) {
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  padding: calc(var(--ax) * 10) calc(var(--ax) * 22) calc(var(--ax) * 2);
  text-transform: none;
}

@media only screen and (min-width: 990px) {
  .customer .field input:-webkit-autofill,
  .customer .field input:focus,
  .customer .field input:not(:placeholder-shown),
  .field__input:-webkit-autofill,
  .field__input:focus,
  .field__input:not(:placeholder-shown) {
    font-size: 1.2rem;
    font-size: clamp(1rem, 0.83328vw, 1.2rem);
  }
}

.customer .field input::-webkit-search-cancel-button,
.field__input::-webkit-search-cancel-button {
  display: none;
}

.customer .field input::placeholder,
.field__input::placeholder {
  opacity: 0;
}

.field__button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: currentColor;
  cursor: pointer;
  display: flex;
  height: calc(var(--ax) * 37);
  justify-content: center;
  letter-spacing: 0.032em;
  margin-left: calc(var(--ax) * 10);
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(var(--ax) * 104);
}

.field__button > svg {
  height: 2.5rem;
  width: 2.5rem;
}

.customer .field input:-webkit-autofill ~ label,
.field__input:-webkit-autofill ~ .field__button,
.field__input:-webkit-autofill ~ .field__label {
  color: #000;
}

.text-area {
  font-family: Agenda, "sans-serif";
  font-style: var(--font-body-style);
  font-weight: var(--font-body-weight);
  min-height: 10rem;
  resize: none;
}

input[type="checkbox"] {
  display: inline-block;
  margin-right: 0.5rem;
  width: auto;
}

.form__label {
  display: block;
  margin-bottom: 0.6rem;
}

.form__message {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  line-height: 1;
  margin-top: 1rem;
}

.form__message--large {
  font-size: 1.6rem;
}

.customer .field .form__message {
  font-size: 1.4rem;
  text-align: left;
}

.customer .form__message svg,
.form__message .icon {
  flex-shrink: 0;
  height: 1.3rem;
  margin-right: 0.5rem;
  width: 1.3rem;
}

.customer .form__message svg,
.form__message--large .icon {
  height: 1.5rem;
  margin-right: 1rem;
  width: 1.5rem;
}

.customer .field .form__message svg {
  align-self: start;
}

.form-status {
  font-size: 1.6rem;
  margin: 0;
}

.form-status-list {
  margin: 2rem 0 4rem;
  padding: 0;
}

.form-status-list li {
  list-style-position: inside;
}

.form-status-list .link:first-letter {
  text-transform: capitalize;
}

.caption-large,
.customer .field input,
.customer select,
.field__input,
.form__label,
.select__select {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: #272b1a;
  font-family: Poppins, "sans-serif";
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  font-weight: 500;
  letter-spacing: 0.143em;
  line-height: 1.2;
  position: relative;
  text-transform: uppercase;
  transition: box-shadow var(--duration-short) ease;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .caption-large,
  .customer .field input,
  .customer select,
  .field__input,
  .form__label,
  .select__select {
    font-size: calc(var(--ax) * 16);
  }
}

.customer .field input,
.customer select,
.field__input {
  height: calc(var(--ax) * 40);
  min-width: calc(var(--ax) * 154);
}

.select__select {
  height: auto;
}

.customer .field:before,
.customer select:before,
.field:before,
.localization-form__select:before,
.select:before {
  border: 1px solid #272b1a;
  border-radius: 0;
  bottom: 0;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.customer .field:after,
.customer select:after,
.field:after,
.localization-form__select:after,
.select:after {
  border: 0.1rem solid transparent;
  border-radius: 0;
  bottom: 0.1rem;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 1);
  content: "";
  left: 0.1rem;
  pointer-events: none;
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

.customer .field:hover.field:after,
.customer select:hover.select:after,
.field:hover.field:after,
.localization-form__select:hover.localization-form__select:after,
.select:hover.select:after,
.select__select:hover.select__select:after {
  border-radius: 0;
  outline: 0;
}

.customer .field input:focus-visible,
.customer select:focus-visible,
.field__input:focus-visible,
.localization-form__select:focus-visible.localization-form__select:after,
.select__select:focus-visible {
  border-radius: 0;
  outline: 0;
}

.customer .field input:focus,
.customer select:focus,
.field__input:focus,
.localization-form__select:focus.localization-form__select:after,
.select__select:focus {
  border-radius: 0;
  outline: 0;
}

.localization-form__select:focus {
  box-shadow: none;
  outline: 0;
}

.select,
.text-area {
  display: flex;
  position: relative;
  width: 100%;
}

.customer select + svg,
.select .icon-caret {
  height: 0.6rem;
  pointer-events: none;
  position: absolute;
  right: 1.6rem;
  top: calc(50% - 0.2rem);
}

.customer select,
.select__select {
  cursor: pointer;
}

.field {
  position: relative;
  transition: box-shadow var(--duration-short) ease;
  width: 100%;
}

.customer .field,
.field {
  display: flex;
}

.field--with-error {
  flex-wrap: wrap;
}

.customer .field input,
.field__input {
  flex-grow: 1;
  padding: 0;
  text-align: left;
  transition: box-shadow var(--duration-short) ease;
}

.customer .field label,
.field__label {
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  left: calc(var(--ax) * 15);
  letter-spacing: 0.0715em;
  letter-spacing: 0.1rem;
  line-height: 1;
  margin-bottom: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: top var(--duration-short) ease,
    font-size var(--duration-short) ease;
}

@media only screen and (min-width: 990px) {
  .customer .field label,
  .field__label {
    font-size: calc(var(--ax) * 14);
  }
}

.grid {
  column-gap: calc(var(--ax) * 5);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: calc(var(--ax) * 20);
  padding: 0;
  row-gap: calc(var(--ax) * 5);
}

@media only screen and (min-width: 750px) {
  .grid {
    column-gap: calc(var(--ax) * 5);
    row-gap: calc(var(--ax) * 5);
  }
}

.grid:last-child {
  margin-bottom: 0;
}

.grid__item {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: calc(50% - var(--ax) * 5 / 2);
  width: calc(25% - var(--ax) * 5 * 3 / 4);
}

@media only screen and (min-width: 750px) {
  .grid__item {
    max-width: calc(50% - var(--ax) * 5 / 2);
    width: calc(25% - var(--ax) * 5 * 3 / 4);
  }
}

.grid--gapless.grid {
  column-gap: 0;
  row-gap: 0;
}

@media only screen and (max-width: 749px) {
  .grid__item.slider__slide--full-width {
    max-width: none;
    width: 100%;
  }
}

.grid--1-col .grid__item {
  max-width: 100%;
  width: 100%;
}

.grid--3-col .grid__item {
  width: calc(33.33% - var(--ax) * 5 * 2 / 3);
}

@media only screen and (min-width: 750px) {
  .grid--3-col .grid__item {
    width: calc(33.33% - var(--ax) * 5 * 2 / 3);
  }
}

.grid--2-col .grid__item {
  width: calc(50% - var(--ax) * 5 / 2);
}

@media only screen and (min-width: 750px) {
  .grid--2-col .grid__item {
    width: calc(50% - var(--ax) * 5 / 2);
  }

  .grid--4-col-tablet .grid__item {
    width: calc(25% - var(--ax) * 5 * 3 / 4);
  }

  .grid--3-col-tablet .grid__item {
    width: calc(33.33% - var(--ax) * 5 * 2 / 3);
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - var(--ax) * 5 / 2);
  }
}

@media only screen and (max-width: 989px) {
  .grid--1-col-tablet-down .grid__item {
    max-width: 100%;
    width: 100%;
  }

  .slider--tablet.grid--peek {
    margin: 0;
    width: 100%;
  }

  .slider--tablet.grid--peek .grid__item {
    box-sizing: content-box;
    margin: 0;
  }
}

@media only screen and (min-width: 990px) {
  .grid--6-col-desktop .grid__item {
    max-width: calc(16.66% - var(--ax) * 5 * 5 / 6);
    width: calc(16.66% - var(--ax) * 5 * 5 / 6);
  }

  .grid--5-col-desktop .grid__item {
    max-width: calc(20% - var(--ax) * 5 * 4 / 5);
    width: calc(20% - var(--ax) * 5 * 4 / 5);
  }

  .grid--4-col-desktop .grid__item {
    max-width: calc(25% - var(--ax) * 5 * 3 / 4);
    width: calc(25% - var(--ax) * 5 * 3 / 4);
  }

  .grid--3-col-desktop .grid__item {
    max-width: calc(33.33% - var(--ax) * 5 * 2 / 3);
    width: calc(33.33% - var(--ax) * 5 * 2 / 3);
  }

  .grid--2-col-desktop .grid__item {
    max-width: calc(50% - var(--ax) * 5 / 2);
    width: calc(50% - var(--ax) * 5 / 2);
  }

  .grid--1-col-desktop {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .grid--1-col-desktop .grid__item {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 749px) {
  .grid--peek.slider--mobile {
    margin: 0;
    width: 100%;
  }

  .grid--peek.slider--mobile .grid__item {
    box-sizing: content-box;
    margin: 0;
  }

  .grid--peek .grid__item {
    min-width: 35%;
  }

  .grid--peek.slider .grid__item:first-of-type {
    margin-left: calc(var(--ax) * 15);
  }

  .grid--peek.slider:after {
    margin-left: calc(var(--ax) * 5 * -1);
  }

  .grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--ax) * 5 / 2);
  }

  .grid--peek .grid__item,
  .slider--tablet.grid--peek.grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--ax) * 5 - var(--ax) * 30);
  }

  .slider--mobile.grid--peek.grid--1-col-tablet-down .grid__item,
  .slider--tablet.grid--peek.grid--1-col-tablet-down .grid__item {
    width: calc(100% - var(--ax) * 5 - var(--ax) * 30);
  }
}

@media only screen and (min-width: 750px) and (max-width: 989px) {
  .slider--tablet.grid--peek .grid__item {
    width: calc(25% - var(--ax) * 5 - var(--ax) * 30);
  }

  .slider--tablet.grid--peek.grid--3-col-tablet .grid__item {
    width: calc(33.33% - var(--ax) * 5 - var(--ax) * 30);
  }

  .slider--tablet.grid--peek.grid--2-col-tablet .grid__item,
  .slider--tablet.grid--peek.grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--ax) * 5 - var(--ax) * 30);
  }

  .slider--tablet.grid--peek .grid__item:first-of-type {
    margin-left: calc(var(--ax) * 15);
  }

  .grid--2-col-tablet-down .grid__item {
    width: calc(50% - var(--ax) * 5 / 2);
  }

  .grid--1-col-tablet-down.grid--peek .grid__item {
    width: calc(100% - var(--ax) * 5 - var(--ax) * 30);
  }
}

.media {
  background-color: rgba(var(--color-foreground), 0.1);
  display: block;
  overflow: hidden;
  position: relative;
}

.media--transparent {
  background-color: transparent;
}

.media model-viewer,
.media > :not(.zoom):not(.deferred-media__poster-button) {
  display: block;
  height: 100%;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.media > img {
  object-fit: cover;
  object-position: center center;
  transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.media--square {
  padding-bottom: 100%;
}

.media--portrait {
  padding-bottom: 125%;
}

.media--landscape {
  padding-bottom: 66.6%;
}

.media--cropped {
  padding-bottom: 56%;
}

.media--16-9 {
  padding-bottom: 56.25%;
}

.media--circle {
  border-radius: 50%;
  padding-bottom: 100%;
}

.media.media--hover-effect > img + img {
  opacity: 0;
}

@media only screen and (min-width: 990px) {
  .media--cropped {
    padding-bottom: 63%;
  }
}

deferred-media {
  display: block;
}

.modal__toggle {
  list-style-type: none;
}

.no-js details[open] .modal__toggle {
  position: absolute;
  z-index: 5;
}

.modal__toggle-close {
  display: none;
}

.no-js details[open] svg.modal__toggle-close {
  display: flex;
  height: 1.7rem;
  width: 1.7rem;
  z-index: 1;
}

.modal__toggle-open {
  display: flex;
}

.no-js .modal__close-button.link,
.no-js details[open] .modal__toggle-open {
  display: none;
}

.modal__close-button.link {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 4.4rem;
  justify-content: center;
  padding: 0;
  width: 4.4rem;
}

.modal__close-button .icon {
  height: 1.7rem;
  width: 1.7rem;
}

.modal__content {
  align-items: center;
  background: rgb(var(--color-background));
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

.media-modal {
  cursor: zoom-out;
}

.media-modal .deferred-media {
  cursor: auto;
}

.modal__close-button:hover .icon {
  transform: scale(1.07);
}

.pagination-wrapper {
  margin-top: 4rem;
}

@media only screen and (min-width: 990px) {
  .pagination-wrapper {
    margin-top: 5rem;
  }
}

.pagination__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination__list > li {
  flex: 1 0 4.4rem;
  max-width: 4.4rem;
}

.pagination__list > li:not(:last-child) {
  margin-right: 1rem;
}

.pagination__item {
  align-items: center;
  color: #272b1a;
  display: inline-flex;
  height: 4.4rem;
  justify-content: center;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 100%;
}

a.pagination__item:hover:after {
  height: 0.1rem;
}

.pagination__item .icon-caret {
  height: 0.6rem;
}

.pagination__item--current:after {
  height: 0.1rem;
}

.pagination__item--current:after,
.pagination__item:hover:after {
  background-color: currentColor;
  bottom: 8px;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 2rem;
}

.pagination__item--next .icon {
  margin-left: -0.2rem;
  transform: rotate(90deg);
}

.pagination__item--next:hover .icon {
  transform: rotate(90deg) scale(1.07);
}

.pagination__item--prev .icon {
  margin-right: -0.2rem;
  transform: rotate(-90deg);
}

.pagination__item--prev:hover .icon {
  transform: rotate(-90deg) scale(1.07);
}

.pagination__item-arrow:hover:after {
  display: none;
}

pickup-availability {
  display: block;
}

pickup-availability[available] {
  min-height: 8rem;
}

.pickup-availability-preview {
  align-items: flex-start;
  display: flex;
  gap: 0.2rem;
  padding: 1rem 2rem 0 0;
}

.pickup-availability-preview .icon {
  flex-shrink: 0;
  height: 1.8rem;
}

.pickup-availability-preview .icon-unavailable {
  height: 1.6rem;
  margin-top: 0.1rem;
}

.pickup-availability-button {
  background-color: transparent;
  color: rgba(var(--color-foreground), 0.75);
  letter-spacing: 0.06rem;
  padding: 0 0 0.2rem;
  text-align: left;
  text-decoration: underline;
}

.pickup-availability-button:hover {
  color: #272b1a;
}

.pickup-availability-info * {
  margin: 0 0 0.6rem;
}

pickup-availability-drawer {
  background-color: rgb(var(--color-background));
  border-color: rgba(var(--color-foreground), var(--drawer-border-opacity));
  border-style: solid;
  border-width: 0 0 0 var(--drawer-border-width);
  filter: drop-shadow(
    var(--drawer-shadow-horizontal-offset) var(--drawer-shadow-vertical-offset)
      var(--drawer-shadow-blur-radius)
      rgba(var(--color-shadow), var(--drawer-shadow-opacity))
  );
  height: 100%;
  opacity: 0;
  overflow-y: auto;
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: opacity var(--duration-default) ease,
    transform var(--duration-default) ease;
  width: 100%;
  z-index: 100;
}

pickup-availability-drawer[open] {
  opacity: 1;
  transform: translateX(0);
}

@media only screen and (min-width: 750px) {
  pickup-availability-drawer {
    transform: translateX(100%);
    width: 37.5rem;
  }

  pickup-availability-drawer[open] {
    animation: animateDrawerOpen var(--duration-default) ease;
    opacity: 1;
    transform: translateX(0);
  }
}

.pickup-availability-header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.pickup-availability-drawer-title {
  margin: 0.5rem 0 0;
}

.pickup-availability-header .icon {
  width: 2rem;
}

.pickup-availability-drawer-button {
  background-color: transparent;
  border: none;
  color: #272b1a;
  cursor: pointer;
  display: block;
  height: 4.4rem;
  padding: 1.2rem;
  width: 4.4rem;
}

.pickup-availability-drawer-button:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.pickup-availability-variant {
  font-size: 1.3rem;
  line-height: calc(1 + 0.2 / var (--font-body-scale));
  margin: 0 0 1.2rem;
  text-transform: capitalize;
}

.pickup-availability-variant > * + strong {
  margin-left: 1rem;
}

.pickup-availability-list__item {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  padding: 2rem 0;
}

.pickup-availability-list__item:first-child {
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.pickup-availability-list__item > * {
  margin: 0;
}

.pickup-availability-list__item > * + * {
  margin-top: 1rem;
}

.pickup-availability-address {
  font-size: 1.2rem;
  font-style: normal;
  line-height: calc(1 + 0.5 / var (--font-body-scale));
}

.pickup-availability-address p {
  margin: 0;
}

@keyframes animateDrawerOpen {
  @media only screen and (max-width: 749px) {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media only screen and (min-width: 750px) {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.predictive-search {
  background-color: rgb(var(--color-background));
  border-bottom-left-radius: var(--popup-corner-radius);
  border-bottom-right-radius: var(--popup-corner-radius);
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-style: solid;
  border-width: var(--popup-border-width);
  box-shadow: var(--popup-shadow-horizontal-offset)
    var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
    rgba(var(--color-shadow), var(--popup-shadow-opacity));
  display: none;
  left: -0.1rem;
  position: absolute;
  top: calc(100% + 0.1rem);
  z-index: 3;
}

.predictive-search--search-template {
  width: calc(100% + 0.2rem);
  z-index: 2;
}

@media screen and (max-width: 749px) {
  .predictive-search--header {
    left: 0;
    right: 0;
    top: 100%;
  }
}

@media screen and (max-width: 989px) {
  .predictive-search {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 750px) {
  .predictive-search {
    border-top: none;
    width: calc(100% + 0.2rem);
  }

  .header predictive-search {
    position: relative;
  }
}

predictive-search[loading] .predictive-search,
predictive-search[open] .predictive-search {
  display: block;
}

.predictive-search__heading {
  align-items: center;
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  color: rgba(var(--color-foreground), 0.7);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1.5rem 0 0.75rem;
  width: calc(100% - 4rem);
}

predictive-search .spinner {
  height: 1.5rem;
  line-height: 0;
  width: 1.5rem;
}

.predictive-search__heading .spinner {
  margin: 0 0.2rem 0 2rem;
}

predictive-search:not([loading]) .predictive-search-status__loading,
predictive-search:not([loading]) .predictive-search__heading .spinner,
predictive-search:not([loading]) .predictive-search__loading-state {
  display: none;
}

predictive-search[loading] .predictive-search__loading-state {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

predictive-search[loading]
  .predictive-search__heading
  ~ .predictive-search__loading-state,
predictive-search[loading] .predictive-search__results-list:first-child {
  display: none;
}

.predictive-search__list-item:nth-last-child(2) {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.predictive-search__list-item:hover > *,
.predictive-search__list-item[aria-selected="true"] > * {
  background-color: rgba(var(--color-foreground), 0.04);
  color: #272b1a;
}

.predictive-search__list-item:hover .predictive-search__item-heading,
.predictive-search__list-item[aria-selected="true"]
  .predictive-search__item-heading {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.predictive-search__item {
  display: flex;
  padding: 1rem 2rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.predictive-search__item--link {
  grid-column-gap: 2rem;
  display: grid;
  grid-template-areas: "product-image product-content";
  grid-template-columns: 5rem 1fr;
}

.predictive-search__item-content {
  display: flex;
  flex-direction: column;
  grid-area: product-content;
}

.predictive-search__item-content--centered {
  justify-content: center;
}

.predictive-search__item-vendor {
  font-size: 0.9rem;
}

.predictive-search__item-heading {
  margin: 0;
}

.predictive-search__item .price {
  color: rgba(var(--color-foreground), 0.7);
  font-size: 1.2rem;
}

.predictive-search .price,
.predictive-search__item-vendor + .predictive-search__item-heading {
  margin-top: 0.5rem;
}

.predictive-search__item--term {
  align-items: center;
  justify-content: space-between;
  line-height: calc(1 + 0.4 / var (--font-body-scale));
  padding: 1.3rem 2rem;
  word-break: break-all;
}

@media only screen and (min-width: 750px) {
  .predictive-search__item--term {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

.predictive-search__item--term .icon-arrow {
  color: #272b1a;
  flex-shrink: 0;
  height: calc(var(--font-heading-scale) * 1.4rem);
  margin-left: calc(var(--font-heading-scale) * 2rem);
  width: calc(var(--font-heading-scale) * 1.4rem);
}

.predictive-search__image {
  font-family: "object-fit: contain";
  grid-area: product-image;
  object-fit: contain;
}

.price {
  letter-spacing: 0.1rem;
}

.price--unavailable {
  visibility: hidden;
}

.price--sold-out .price__availability,
.price__regular {
  display: block;
}

.price .price__badge-sale,
.price .price__badge-sold-out,
.price--on-sale .price__availability,
.price--on-sale .price__regular,
.price__availability,
.price__sale {
  display: none;
}

.price--on-sale .price__badge-sale,
.price--sold-out .price__badge-sold-out {
  display: inline-block;
}

.price--on-sale .price__sale {
  display: initial;
  flex-direction: row;
  flex-wrap: wrap;
}

.price--center {
  display: initial;
  justify-content: center;
}

.price--on-sale .price-item--regular {
  text-decoration: line-through;
}

.unit-price {
  display: block;
}

.rating {
  display: inline-block;
  margin: 0;
}

.product .rating-star {
  --letter-spacing: 0.8;
  --font-size: 1.7;
}

.card-wrapper .rating-star {
  --letter-spacing: 0.7;
  --font-size: 1.4;
}

.rating-star {
  --percent: calc(
    (
        var(--rating) / var(--rating-max) + var(--rating-decimal) *
          var(--font-size) /
          (var(--rating-max) * (var(--letter-spacing) + var(--font-size)))
      ) * 100%
  );
  display: inline-block;
  font-family: Times;
  font-size: calc(var(--font-size) * 1rem);
  letter-spacing: calc(var(--letter-spacing) * 1rem);
  line-height: 1;
  margin: 0;
}

.rating-star:before {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-icon) var(--percent),
    rgba(var(--color-foreground), 0.15) var(--percent)
  );
  -webkit-background-clip: text;
  content: "★★★★★";
}

.rating-text {
  display: none;
}

.rating-count {
  display: inline-block;
  margin: 0;
}

@media (forced-colors: active) {
  .rating {
    display: none;
  }

  .rating-text {
    display: block;
  }
}

.search__input.field__input {
  padding-right: 5rem;
}

.search__button {
  right: 0.1rem;
  top: 0.1rem;
}

.search__button:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.search__button:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.search__button:not(:focus-visible):not(.focused) {
  background-color: inherit;
  box-shadow: inherit;
}

.search__button .icon {
  height: 1.8rem;
  width: 1.8rem;
}

input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.button-show-more {
  justify-content: flex-start;
  padding-bottom: 1.1rem;
  padding-left: 0;
}

.button-show-less,
.button-show-more {
  margin-top: 1.5rem;
}

slider-component {
  --desktop-margin-left-first-item: max(
    5rem,
    calc(50vw - var(--container) / 2 + 5rem - var(--ax) * 5 / 2)
  );
  display: block;
  position: relative;
}

slider-component.slider-component-full-width {
  --desktop-margin-left-first-item: 1.5rem;
}

@media screen and (max-width: 749px) {
  slider-component.container {
    padding: 0 calc(var(--ax) * 10);
  }
}

@media screen and (min-width: 749px) and (max-width: 990px) {
  slider-component.container {
    padding: 0 calc(var(--ax) * 20);
  }
}

@media screen and (max-width: 989px) {
  .no-js slider-component .slider {
    padding-bottom: 3rem;
  }
}

.slider__slide {
  --focus-outline-padding: 0.5rem;
  --shadow-padding-top: calc(
    (var(--shadow-vertical-offset) * -1 + var(--shadow-blur-radius)) *
      var(--shadow-visible)
  );
  --shadow-padding-bottom: calc(
    (var(--shadow-vertical-offset) + var(--shadow-blur-radius)) *
      var(--shadow-visible)
  );
  flex-shrink: 0;
  padding-bottom: 0;
  scroll-snap-align: start;
}

@media screen and (max-width: 749px) {
  .slider.slider--mobile {
    -webkit-overflow-scrolling: touch;
    flex-wrap: inherit;
    margin-bottom: 1rem;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
    scroll-padding-left: 1.5rem;
    scroll-snap-type: x mandatory;
  }

  .slider--mobile:after {
    content: "";
    padding-left: 1.5rem;
    width: 0;
  }

  .slider.slider--mobile .slider__slide {
    margin-bottom: 0;
    padding-bottom: max(
      var(--focus-outline-padding),
      var(--shadow-padding-bottom)
    );
    padding-top: max(var(--focus-outline-padding), var(--shadow-padding-top));
  }

  .slider.slider--mobile.contains-card--standard
    .slider__slide:not(.collection-list__item--no-media) {
    padding-bottom: var(--focus-outline-padding);
  }

  .slider.slider--mobile.contains-content-container .slider__slide {
    --focus-outline-padding: 0rem;
  }
}

@media only screen and (min-width: 750px) {
  .slider.slider--tablet-up {
    -webkit-overflow-scrolling: touch;
    flex-wrap: inherit;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
    scroll-padding-left: 1rem;
    scroll-snap-type: x mandatory;
  }

  .slider.slider--tablet-up .slider__slide {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 989px) {
  .slider.slider--tablet {
    -webkit-overflow-scrolling: touch;
    flex-wrap: inherit;
    margin-bottom: 1rem;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
    scroll-padding-left: 1.5rem;
    scroll-snap-type: x mandatory;
  }

  .slider--tablet:after {
    content: "";
    margin-left: calc(var(--ax) * 5 * -1);
    padding-left: 1.5rem;
    width: 0;
  }

  .slider.slider--tablet .slider__slide {
    margin-bottom: 0;
    padding-bottom: max(
      var(--focus-outline-padding),
      var(--shadow-padding-bottom)
    );
    padding-top: max(var(--focus-outline-padding), var(--shadow-padding-top));
  }

  .slider.slider--tablet.contains-card--standard
    .slider__slide:not(.collection-list__item--no-media) {
    padding-bottom: var(--focus-outline-padding);
  }

  .slider.slider--tablet.contains-content-container .slider__slide {
    --focus-outline-padding: 0rem;
  }
}

.slider--everywhere {
  -webkit-overflow-scrolling: touch;
  flex-wrap: inherit;
  margin-bottom: 1rem;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.slider.slider--everywhere .slider__slide {
  margin-bottom: 0;
  scroll-snap-align: center;
}

@media only screen and (min-width: 990px) {
  .slider-component-desktop.container {
    max-width: none;
  }

  .slider--desktop {
    -webkit-overflow-scrolling: touch;
    flex-wrap: inherit;
    margin-bottom: 1rem;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
    scroll-padding-left: var(--desktop-margin-left-first-item);
    scroll-snap-type: x mandatory;
  }

  .slider--desktop:after {
    content: "";
    margin-left: calc(var(--ax) * 5 * -1);
    padding-left: 5rem;
    width: 0;
  }

  .slider.slider--desktop .slider__slide {
    margin-bottom: 0;
    padding-bottom: max(
      var(--focus-outline-padding),
      var(--shadow-padding-bottom)
    );
    padding-top: max(var(--focus-outline-padding), var(--shadow-padding-top));
  }

  .slider--desktop .slider__slide:first-child {
    margin-left: var(--desktop-margin-left-first-item);
    scroll-margin-left: var(--desktop-margin-left-first-item);
  }

  .slider-component-full-width .slider--desktop {
    scroll-padding-left: 1.5rem;
  }

  .slider-component-full-width .slider--desktop .slider__slide:first-child {
    margin-left: 1.5rem;
    scroll-margin-left: 1.5rem;
  }

  .slider-component-full-width .slider--desktop:after {
    padding-left: 1.5rem;
  }

  .slider--desktop.grid--5-col-desktop .grid__item {
    width: calc(
      (100% - var(--desktop-margin-left-first-item)) / 5 - var(--ax) * 5 * 2
    );
  }

  .slider--desktop.grid--4-col-desktop .grid__item {
    width: calc(
      (100% - var(--desktop-margin-left-first-item)) / 4 - var(--ax) * 5 * 3
    );
  }

  .slider--desktop.grid--3-col-desktop .grid__item {
    width: calc(
      (100% - var(--desktop-margin-left-first-item)) / 3 - var(--ax) * 5 * 4
    );
  }

  .slider--desktop.grid--2-col-desktop .grid__item {
    width: calc(
      (100% - var(--desktop-margin-left-first-item)) / 2 - var(--ax) * 5 * 5
    );
  }

  .slider--desktop.grid--1-col-desktop .grid__item {
    width: calc(
      100% - var(--desktop-margin-left-first-item) - var(--ax) * 5 * 9
    );
  }

  .slider.slider--desktop.contains-card--standard
    .slider__slide:not(.collection-list__item--no-media) {
    padding-bottom: var(--focus-outline-padding);
  }

  .slider.slider--desktop.contains-content-container .slider__slide {
    --focus-outline-padding: 0rem;
  }
}

@media (prefers-reduced-motion) {
  .slider {
    scroll-behavior: auto;
  }
}

.slider {
  -ms-overflow-style: none;
  scrollbar-color: #272b1a rgba(var(--color-foreground), 0.04);
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  display: none;
  height: 0.4rem;
  width: 0.4rem;
}

.no-js .slider {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.no-js .slider::-webkit-scrollbar {
  display: initial;
}

.slider::-webkit-scrollbar-thumb {
  background-color: #272b1a;
  border: 0;
  border-radius: 0.4rem;
}

.slider::-webkit-scrollbar-track {
  background: rgba(var(--color-foreground), 0.04);
  border-radius: 0.4rem;
}

.slider-counter {
  display: flex;
  justify-content: center;
  min-width: 4.4rem;
}

@media only screen and (min-width: 750px) {
  .slider-counter--dots {
    margin: 0;
  }
}

.slider-counter__link {
  padding: calc(var(--ax) * 5);
}

@media screen and (max-width: 749px) {
  .slider-counter__link {
    padding: calc(var(--ax) * 7);
  }
}

.slider-counter__link--dots .dot {
  background-color: #000;
  border-radius: 50%;
  display: block;
  height: calc(var(--ax) * 8);
  opacity: 0.25;
  padding: 0;
  width: calc(var(--ax) * 8);
}

.slider-counter__link--active.slider-counter__link--dots .dot {
  background-color: #444934;
  opacity: 0.7;
}

@media screen and (forced-colors: active) {
  .slider-counter__link--active.slider-counter__link--dots .dot {
    background-color: CanvasText;
  }
}

.slider-counter__link--dots:not(.slider-counter__link--active):hover .dot {
  border-color: #272b1a;
}

.slider-counter__link--dots .dot,
.slider-counter__link--numbers {
  transition: transform 0.2s ease-in-out;
}

.slider-counter__link--active.slider-counter__link--numbers,
.slider-counter__link--dots:not(.slider-counter__link--active):hover .dot,
.slider-counter__link--numbers:hover {
  transform: scale(1.1);
}

.slider-counter__link--numbers {
  color: rgba(var(--color-foreground), 0.5);
  text-decoration: none;
}

.slider-counter__link--numbers:hover {
  color: #272b1a;
}

.slider-counter__link--active.slider-counter__link--numbers {
  color: #272b1a;
  text-decoration: underline;
}

.slider-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 990px) {
  .slider:not(.slider--everywhere):not(.slider--desktop) + .slider-buttons {
    display: none;
  }
}

@media screen and (max-width: 989px) {
  .slider--desktop:not(.slider--tablet) + .slider-buttons {
    display: none;
  }
}

@media only screen and (min-width: 750px) {
  .slider--mobile + .slider-buttons {
    display: none;
  }
}

.slider-button {
  align-items: center;
  background: transparent;
  border: none;
  color: rgba(var(--color-foreground), 0.75);
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.slider-button:not([disabled]):hover {
  color: #272b1a;
}

.slider-button .icon {
  height: 0.6rem;
}

.slider-button[disabled] .icon {
  color: rgba(var(--color-foreground), 0.3);
  cursor: not-allowed;
}

.slider-button--next .icon {
  transform: rotate(-90deg);
}

.slider-button--prev .icon {
  transform: rotate(90deg);
}

.slider-button--next:not([disabled]):hover .icon {
  transform: rotate(-90deg) scale(1.1);
}

.slider-button--prev:not([disabled]):hover .icon {
  transform: rotate(90deg) scale(1.1);
}

slideshow-component {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media screen and (max-width: 989px) {
  .no-js slideshow-component .slider {
    padding-bottom: 3rem;
  }
}

slideshow-component .slideshow.banner {
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0;
  margin: 0;
}

.slideshow__slide {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
}

@media screen and (max-width: 749px) {
  .slideshow--placeholder.banner--adapt_image:not(.banner--mobile-bottom),
  .slideshow--placeholder.banner--mobile-bottom.banner--adapt_image
    .slideshow__media {
    height: 28rem;
  }
}

@media only screen and (min-width: 750px) {
  .slideshow--placeholder.banner--adapt_image {
    height: 56rem;
  }
}

.slideshow__text.banner__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 54.5rem;
}

.slideshow__text > * {
  max-width: 100%;
}

@media screen and (max-width: 749px) {
  slideshow-component.container .slideshow__text {
    border-left: var(--text-boxes-border-width) solid
      rgba(var(--color-foreground), var(--text-boxes-border-opacity));
    border-right: var(--text-boxes-border-width) solid
      rgba(var(--color-foreground), var(--text-boxes-border-opacity));
  }

  .banner--mobile-bottom .slideshow__text.banner__box {
    max-width: 100%;
  }

  .banner--mobile-bottom .slideshow__text-wrapper {
    flex-grow: 1;
  }

  .banner--mobile-bottom .slideshow__text.banner__box {
    height: 100%;
  }

  .banner--mobile-bottom .slideshow__text .button {
    flex-grow: 0;
  }

  .slideshow__text.slideshow__text-mobile--left {
    align-items: flex-start;
    text-align: left;
  }

  .slideshow__text.slideshow__text-mobile--right {
    align-items: flex-end;
    text-align: right;
  }
}

@media only screen and (min-width: 750px) {
  .slideshow__text.slideshow__text--left {
    align-items: flex-start;
    text-align: left;
  }

  .slideshow__text.slideshow__text--right {
    align-items: flex-end;
    text-align: right;
  }
}

.slideshow:not(.banner--mobile-bottom) .slideshow__text-wrapper {
  height: 100%;
}

@media only screen and (min-width: 750px) {
  .slideshow__text-wrapper.banner__content {
    height: 100%;
    padding: 5rem;
  }
}

.slideshow__controls {
  border: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.slideshow__controls--top {
  order: 2;
  z-index: 1;
}

@media screen and (max-width: 749px) {
  .slideshow__controls--radius-radius-mobile {
    border-bottom-left-radius: var(--text-boxes-radius);
    border-bottom-right-radius: var(--text-boxes-radius);
  }
}

.spaced-section--full-width:last-child
  slideshow-component:not(.container)
  .slideshow__controls {
  border-bottom: none;
}

@media only screen and (min-width: 750px) {
  .slideshow__controls {
    position: relative;
  }
}

slideshow-component:not(.container) .slider-buttons {
  border-left: 0;
  border-right: 0;
}

.slideshow__control-wrapper {
  display: flex;
}

.slideshow__autoplay {
  align-items: center;
  border-left: none;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 749px) {
  slideshow-component.container .slideshow__autoplay {
    right: 1.5rem;
  }
}

@media only screen and (min-width: 750px) {
  .slideshow__autoplay.slider-button {
    border-left: 0.1rem solid rgba(var(--color-foreground), 0.08);
    margin-left: 0.6rem;
    padding: 0 0 0 0.6rem;
    position: inherit;
  }
}

.slideshow__autoplay .icon.icon-pause,
.slideshow__autoplay .icon.icon-play {
  display: block;
  height: 1.2rem;
  opacity: 1;
  position: absolute;
  transform: scale(1);
  transition: transform 0.15s ease, opacity 0.15s ease;
  width: 0.8rem;
}

.slideshow__autoplay .icon.icon-play {
  height: 1rem;
}

.slideshow__autoplay path {
  fill: rgba(var(--color-foreground), 0.75);
}

.slideshow__autoplay:hover path {
  fill: #272b1a;
}

@media screen and (forced-colors: active) {
  .slideshow__autoplay path,
  .slideshow__autoplay:hover path {
    fill: CanvasText;
  }
}

.slideshow__autoplay:hover svg {
  transform: scale(1.1);
}

.slideshow__autoplay--paused .icon-pause,
.slideshow__autoplay:not(.slideshow__autoplay--paused) .icon-play {
  opacity: 0;
  transform: scale(0.8);
  visibility: hidden;
}

.customer:not(.account):not(.order) {
  margin: 0 auto;
  max-width: 33.4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
}

@media only screen and (min-width: 750px) {
  .customer:not(.account):not(.order) {
    max-width: 47.8rem;
  }
}

.customer > h1,
.customer__title {
  margin-top: 0;
}

.customer form {
  margin-top: 4rem;
}

.customer button {
  margin: 4rem 0 1.5rem;
}

.customer ul {
  line-height: calc(1 + 0.6 / var (--font-body-scale));
  margin-bottom: 4rem;
  padding-left: 4.4rem;
  text-align: left;
}

.customer ul a {
  display: inline;
}

.customer strong {
  color: #272b1a;
  font-weight: 400;
}

.customer h2.form__message {
  font-size: calc(var(--font-heading-scale) * 1.8rem);
}

@media only screen and (min-width: 750px) {
  .customer h2.form__message {
    font-size: calc(var(--font-heading-scale) * 2.2rem);
  }
}

.customer .field {
  margin: 2rem 0 0;
}

.customer .field:first-of-type {
  margin-top: 0;
}

.customer table {
  border-bottom: 0.01rem solid rgba(var(--color-foreground), 0.08);
  border-collapse: collapse;
  box-shadow: none;
  font-size: 1.6rem;
  position: relative;
  table-layout: auto;
  width: 100%;
}

@media only screen and (min-width: 750px) {
  .customer table {
    border: none;
    box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.08);
  }
}

@media screen and (forced-colors: active) {
  .customer table {
    border-bottom: 0.1rem solid transparent;
    border-top: 0.1rem solid transparent;
  }
}

.customer tbody {
  color: #272b1a;
}

.customer td,
.customer th {
  border: none;
  font-weight: 400;
  line-height: 1;
  padding: 0;
}

@media only screen and (min-width: 750px) {
  .customer td {
    padding-right: 2.2rem;
  }
}

.customer tbody td {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.customer td:empty {
  display: initial;
}

.customer thead th {
  font-size: 1.2rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}

.customer tbody td:first-of-type {
  padding-top: 4rem;
}

@media only screen and (min-width: 750px) {
  .customer td:first-of-type,
  .customer th {
    padding-left: 0;
    padding-right: 2.2rem;
    text-align: left;
  }

  .customer tbody td,
  .customer thead th {
    padding-bottom: 2.4rem;
    padding-top: 2.4rem;
  }

  .customer td:first-of-type,
  .customer th:first-of-type {
    padding-left: 2.2rem;
  }

  .customer tbody td {
    vertical-align: top;
  }

  .customer tbody td:first-of-type {
    padding-top: 2.4rem;
  }
}

.customer tbody td:last-of-type {
  padding-bottom: 4rem;
}

@media only screen and (min-width: 750px) {
  .customer tbody td:last-of-type {
    padding-bottom: 0;
  }
}

.customer tbody tr {
  border-top: 0.01rem solid rgba(var(--color-foreground), 0.08);
}

@media only screen and (min-width: 750px) {
  .customer tbody tr:first-of-type {
    border-top: none;
  }
}

@media screen and (forced-colors: active) {
  .customer tbody tr {
    border-top: 0.1rem solid transparent;
  }
}

.customer tfoot td,
.customer tfoot td:first-of-type {
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}

.customer tfoot td:first-of-type {
  text-align: left;
}

.customer tfoot tr:first-of-type td {
  padding-top: 4rem;
}

@media only screen and (min-width: 750px) {
  .customer tfoot tr:first-of-type td,
  .customer tfoot tr:first-of-type th {
    padding-top: 2.4rem;
  }
}

.customer tfoot tr:last-of-type td {
  padding-bottom: 4rem;
}

@media only screen and (min-width: 750px) {
  .customer tfoot tr:last-of-type td,
  .customer tfoot tr:last-of-type th {
    padding-bottom: 2.4rem;
  }
}

.customer tfoot:before,
.customer thead:after {
  background: rgba(var(--color-foreground), 0.08);
  content: " ";
  display: block;
  height: 0.1rem;
  left: 0;
  position: absolute;
  width: 100%;
}

@media screen and (forced-colors: active) {
  .customer tfoot:before,
  .customer thead:after {
    background: CanvasText;
  }
}

@media screen and (max-width: 749px) {
  .customer tfoot td:first-of-type,
  .customer th,
  .customer thead {
    display: none;
  }

  .customer td {
    display: flex;
    text-align: right;
  }

  .customer td:before {
    color: rgba(var(--color-foreground), 0.75);
    content: attr(data-label);
    flex-grow: 1;
    font-size: 1.4rem;
    padding-right: 2rem;
    text-align: left;
    text-transform: uppercase;
  }

  .customer td:first-of-type {
    align-items: center;
    display: flex;
  }

  .customer tr {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
}

.customer .pagination {
  margin-bottom: 7rem;
  margin-top: 5rem;
}

@media only screen and (min-width: 990px) {
  .customer .pagination {
    margin-bottom: 10rem;
    margin-top: 7rem;
  }
}

.customer .pagination ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.customer .pagination li {
  flex: 1 1;
  max-width: 4rem;
}

.customer .pagination li:not(:last-child) {
  margin-right: 1rem;
}

.customer .pagination li :first-child {
  align-items: center;
  display: inline-flex;
  height: 4rem;
  justify-content: center;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.customer .pagination li :first-child svg {
  height: 0.6rem;
}

.customer .pagination li:first-of-type svg {
  margin-left: -0.2rem;
  transform: rotate(90deg);
}

.customer .pagination li:last-of-type svg {
  margin-right: -0.2rem;
  transform: rotate(-90deg);
}

.customer .pagination li [aria-current]:after {
  background-color: currentColor;
  bottom: 0.08rem;
  content: "";
  display: block;
  height: 0.01rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 2rem;
}

.login a {
  display: block;
  margin: 0 auto;
  width: fit-content;
}

.login a[href="#recover"] {
  margin-left: 0;
  margin-right: 0;
}

.login .field + a {
  margin-top: 1rem;
}

.login p {
  margin: 1.5rem 0;
}

.login h3 {
  font-size: calc(var(--font-heading-scale) * 1.6rem);
  margin-top: 1.5rem;
  text-align: left;
}

#customer_login_guest button {
  margin-top: 0;
}

#recover,
#recover + div {
  display: none;
}

#recover:target,
#recover:target + div {
  display: block;
}

#recover:target ~ #login,
#recover:target ~ #login + div {
  display: none;
}

#login,
#recover {
  scroll-margin-top: 20rem;
}

#recover {
  margin-bottom: 0;
}

.activate button[name="decline"],
.addresses form button[type],
.addresses li > button {
  background-color: rgba(
    var(--color-background),
    var(--alpha-button-background)
  );
  color: #272b1a;
}

@media only screen and (min-width: 750px) {
  .activate button[name="decline"] {
    margin-left: 1rem;
    margin-top: inherit;
  }
}

:is(.account, .order) {
  margin: 0 auto;
  max-width: var(--container);
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (min-width: 750px) {
  :is(.account, .order) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media only screen and (min-width: 990px) {
  :is(.account, .order) > div:nth-of-type(2) {
    display: flex;
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 990px) {
  :is(.account, .order) > div:nth-of-type(2) > div:first-of-type {
    flex-grow: 1;
    padding-right: 3.2rem;
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .order > div:nth-of-type(2) > div:last-of-type {
    display: flex;
  }

  .order > div:nth-of-type(2) > div:last-of-type div {
    padding-right: 3.2rem;
  }
}

:is(.account, .order) p {
  font-size: 1.6rem;
  margin: 0 0 2rem;
}

:is(.account, .order) h1 {
  margin-bottom: 1rem;
}

:is(.account, .order) h2 {
  margin-bottom: 1rem;
  margin-top: 4rem;
}

@media only screen and (min-width: 990px) {
  :is(.account, .order) h2 {
    margin-top: 0;
  }
}

.account h1 + a {
  align-items: center;
  display: inline-flex;
}

.account a svg {
  margin-bottom: -0.03rem;
  margin-right: 1rem;
  width: 1.5rem;
}

@media only screen and (min-width: 750px) {
  .account td:last-child,
  .account thead th:last-child {
    text-align: right;
  }

  .account table td:first-of-type {
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;
  }
}

.account table td:first-of-type a {
  border: 0.1rem solid transparent;
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2);
  font-size: 1.2rem;
  padding: 1.1rem 1.5rem;
  text-decoration: none;
}

.account table td:first-of-type a:hover {
  box-shadow: 0 0 0 0.2rem rgba(var(--color-foreground), 0.2);
}

.order td:first-of-type {
  align-items: normal;
}

@media only screen and (min-width: 750px) {
  .order td:nth-last-child(-n + 3),
  .order thead th:nth-last-child(-n + 3) {
    text-align: right;
  }
}

.order tfoot tr:last-of-type td,
.order tfoot tr:last-of-type th {
  font-size: 2.2rem;
  padding-bottom: 4rem;
  padding-top: 1.5rem;
}

@media only screen and (min-width: 750px) {
  .order tfoot tr:last-of-type td,
  .order tfoot tr:last-of-type th {
    padding-bottom: 2.4rem;
  }
}

.order tfoot tr:last-of-type td:before {
  font-size: 2.2rem;
}

.order table p,
.order > div:nth-of-type(2) > div:first-of-type h2,
.order > div:nth-of-type(2) > div:last-of-type h2 + p {
  margin-bottom: 0;
}

.order > div:nth-of-type(2) > div:first-of-type h2 ~ p {
  font-size: 1.4rem;
  margin-bottom: 0;
}

.order > div:nth-of-type(2) > div:first-of-type h2 ~ p:last-of-type {
  margin-bottom: 3rem;
}

.order .item-props {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  margin-top: 0.05px;
}

.order .item-props > span {
  line-height: calc(1 + 0.2 / var (--font-body-scale));
  word-break: break-all;
}

.order .fulfillment {
  border: 0.01rem solid rgba(var(--color-foreground), 0.2);
  font-size: 1.4rem;
  margin-top: 1rem;
  padding: 1rem;
  text-align: left;
  width: fit-content;
}

.order .fulfillment a {
  margin: 0.7rem 0;
}

.order .fulfillment span {
  display: block;
}

.order .cart-discount {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

@media only screen and (min-width: 750px) {
  .order td .cart-discount {
    display: none;
  }
}

.order tbody ul {
  font-size: 1.2rem;
  list-style: none;
  margin-bottom: 0;
  margin-top: 1rem;
  padding-left: 0;
  text-align: right;
}

@media only screen and (min-width: 750px) {
  .order tbody ul {
    text-align: left;
  }
}

.order table a {
  line-height: calc(1 + 0.3 / var (--font-body-scale));
}

.order tbody tr:first-of-type td:first-of-type > div {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 750px) {
  .order tbody tr:first-of-type td:first-of-type > div {
    align-items: flex-start;
  }
}

.order .properties {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.order .properties span {
  display: block;
  line-height: calc(1 + 0.2 / var (--font-body-scale));
}

.order svg {
  color: rgb(var(--color-base-accent-2));
  margin-right: 0.5rem;
  width: 1.1rem;
}

.order dl {
  margin: 0;
}

.order dd {
  line-height: calc(1 + 0.3 / var (--font-body-scale));
  margin-left: 0;
}

.order .unit-price,
.order dd s {
  color: rgba(var(--color-foreground), 0.7);
}

.order .unit-price {
  font-size: 1.1rem;
  letter-spacing: 0.07rem;
  line-height: calc(1 + 0.2 / var (--font-body-scale));
  margin-top: 0.2rem;
  text-transform: uppercase;
}

.order .regular-price {
  font-size: 1.3rem;
}

.addresses li > button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.addresses form button + button,
.addresses li > button + button {
  margin-top: 0;
}

@media only screen and (min-width: 750px) {
  .addresses li > button:first-of-type {
    margin-top: 3rem;
  }
}

.addresses form button:first-of-type {
  margin-right: 1rem;
}

label[for="AddressCountryNew"],
label[for="AddressProvinceNew"] {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
}

.addresses form {
  display: flex;
  flex-flow: row wrap;
}

.addresses form > div {
  text-align: left;
  width: 100%;
}

.addresses ul {
  list-style: none;
  padding-left: 0;
  text-align: center;
}

li[data-address] {
  margin-top: 5rem;
}

.addresses [aria-expanded="false"] ~ div[id] {
  display: none;
}

.addresses [aria-expanded="true"] ~ div[id] {
  display: block;
}

.addresses h2 {
  text-align: left;
}

li[data-address] > h2 {
  font-size: calc(var(--font-heading-scale) * 1.8rem);
  margin-bottom: 0;
  text-align: center;
}

@media only screen and (min-width: 750px) {
  li[data-address] > h2 {
    font-size: calc(var(--font-heading-scale) * 2.2rem);
  }
}

.addresses ul p {
  margin-bottom: 0;
}

.addresses input[type="checkbox"] {
  margin-left: 0;
  margin-top: 2rem;
}

@media only screen and (min-width: 750px) {
  .addresses form > div:first-of-type {
    margin-right: 2rem;
  }

  .addresses form > div:nth-of-type(2) {
    margin-top: 0;
  }

  .addresses form > div:first-of-type,
  .addresses form > div:nth-of-type(2) {
    box-sizing: border-box;
    flex-basis: calc(50% - 1rem);
  }
}

.addresses form > div:nth-of-type(7),
.addresses form > div:nth-of-type(7) + div[id] {
  margin-top: 1.5rem;
}

.disclosure {
  position: relative;
}

.disclosure__button {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  font-size: 1.3rem;
  height: 4rem;
  padding: 0 1.5rem;
}

.disclosure__list-wrapper {
  background-color: rgb(var(--color-background));
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-radius: var(--popup-corner-radius);
  border-style: solid;
  border-width: var(--popup-border-width);
  bottom: 100%;
  box-shadow: var(--popup-shadow-horizontal-offset)
    var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
    rgba(var(--color-shadow), var(--popup-shadow-opacity));
  overflow: hidden;
  position: absolute;
  transform: translateY(-1rem);
  z-index: 2;
}

.disclosure__list {
  font-size: 1.4rem;
  max-height: 19rem;
  max-width: 22rem;
  min-height: 8.2rem;
  min-width: 12rem;
  overflow-y: auto;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: relative;
  scroll-padding: 0.5rem 0;
  width: max-content;
}

.disclosure__item {
  position: relative;
}

.disclosure__link {
  display: block;
  line-height: calc(1 + 0.8 / var (--font-body-scale));
  padding: 0.5rem 2.2rem;
  text-decoration: none;
}

.quantity {
  border: 1px solid #272b1a;
  border-radius: 0;
  color: rgba(var(--color-foreground));
  display: flex;
  margin-top: auto;
  min-height: calc(var(--ax) * 38);
  position: relative;
  width: calc(var(--ax) * 80);
}

.product .quantity {
  height: calc(var(--ax) * 40);
  min-width: calc(var(--ax) * 175);
  padding: 0;
}

@media only screen and (min-width: 990px) {
  .quantity {
    margin-top: calc(var(--ax) * 10);
    min-height: calc(0.2rem + var(--ax) * 35);
    width: calc(var(--ax) * 120 + 0.2rem);
  }
}

.quantity__input {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: currentColor;
  flex-grow: 1;
  font-weight: 500;
  opacity: 0.85;
  padding: 0 calc(var(--ax) * 5);
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.quantity__input:-webkit-autofill,
.quantity__input:-webkit-autofill:active,
.quantity__input:-webkit-autofill:hover {
  box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
  -webkit-box-shadow: 0 0 0 10rem rgb(var(--color-background)) inset !important;
}

.quantity__button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #272b1a;
  cursor: pointer;
  display: flex;
  flex: 50%;
  justify-content: center;
  margin: 0 calc(0.1rem * var(--ax));
  padding: 0 0.8em;
  position: relative;
  z-index: 2;
}

.quantity__button svg {
  pointer-events: none;
  width: calc(var(--ax) * 10);
}

@media only screen and (min-width: 990px) {
  .quantity__button {
    padding: 0 1em;
  }
}

.quantity__input,
.quantity__text {
  display: block;
  font-family: Agenda, "sans-serif";
  font-size: calc(var(--ax) * 12.52);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  margin-top: -0.3em;
}

.quantity__input::-webkit-inner-spin-button,
.quantity__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity__input[type="number"] {
  align-items: center;
  -moz-appearance: textfield;
  display: flex;
  justify-content: center;
  margin-top: -0.15em;
  pointer-events: none;
  text-align: center;
}

.quantity__button,
.quantity__input {
  font-size: 1.1rem;
  font-size: clamp(1rem, 2.8204vw, 1.1rem);
}

@media only screen and (min-width: 990px) {
  .quantity__button,
  .quantity__input {
    font-size: calc(var(--ax) * 14);
  }
}

.quantity__button:focus-visible,
.quantity__input:focus-visible {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.quantity__button:focus,
.quantity__input:focus {
  background-color: rgb(var(--color-background));
  z-index: 2;
}

.quantity__button:not(:focus-visible):not(.focused),
.quantity__input:not(:focus-visible):not(.focused) {
  background-color: inherit;
  box-shadow: inherit;
}

.quick-add {
  grid-row-start: 4;
  margin: 0 0 1rem;
  position: relative;
  z-index: 1;
}

.card--card .quick-add {
  margin: 0 1.3rem 1rem;
}

.quick-add-modal {
  background: rgba(var(--color-foreground), 0.2);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}

.quick-add-modal[open] {
  opacity: 1;
  visibility: visible;
  z-index: 101;
}

.quick-add-modal__content {
  background-color: rgb(var(--color-background));
  left: 50%;
  margin: 3.2rem auto 0;
  max-width: var(--container);
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  width: calc(100% - 3rem);
}

@media only screen and (min-width: 750px) {
  .quick-add-modal__content {
    margin-top: 10rem;
    max-height: calc(100% - 20rem);
    overflow-y: auto;
    width: 80%;
  }

  quick-add-modal .quick-add-modal__toggle {
    right: 2rem;
    top: 2rem;
  }
}

@media only screen and (min-width: 990px) {
  .quick-add-modal__content {
    width: 70%;
  }
}

.quick-add-modal__content img {
  max-width: 100%;
}

.quick-add-modal__content-info {
  display: flex;
  height: 100%;
  overflow-y: auto;
  padding: 2.5rem;
}

.quick-add-modal__content-info > * {
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 749px) {
  quick-add-modal .slider .product__media-item.grid__item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .quick-add-modal__content {
    bottom: 3.2rem;
  }

  .quick-add-modal__content-info > * {
    max-height: 100%;
  }

  quick-add-modal .product--mobile-columns .product__media-item {
    width: calc(100% - 3rem - var(--ax) * 5);
  }
}

.quick-add-modal__toggle {
  align-items: center;
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  width: 4rem;
  z-index: 5;
}

.quick-add-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

quick-add-modal .product:not(.featured-product) .product__view-details {
  display: block;
}

quick-add-modal .product__media-item:not(:first-child),
quick-add-modal .product__modal-opener:not(.product__modal-opener--image),
quick-add-modal .quick-add-hidden {
  display: none !important;
}

quick-add-modal .slider.slider--mobile {
  overflow: visible;
}

quick-add-modal .product__media-gallery .product__media-list {
  margin-bottom: 0;
}

quick-add-modal .product__media-list .deferred-media {
  display: block;
  width: 100%;
}

quick-add-modal .product--columns .product__info-container--sticky,
quick-add-modal .product--stacked .product__info-container--sticky,
quick-add-modal .product--thumbnail .product__media-gallery,
quick-add-modal .product--thumbnail_slider .product__media-gallery {
  position: relative;
  top: 0;
}

@media only screen and (min-width: 750px) {
  quick-add-modal .product:not(.product--no-media) .product__media-wrapper {
    max-width: 45%;
    width: calc(45% - var(--ax) * 5 / 2);
  }

  quick-add-modal .product:not(.product--no-media) .product__info-wrapper {
    max-width: 54%;
    padding-left: 4rem;
    width: calc(54% - var(--ax) * 5 / 2);
  }

  quick-add-modal
    .product--columns
    .product__media-item:not(.product__media-item--single):not(:only-child) {
    max-width: 100%;
    width: 100%;
  }

  quick-add-modal .thumbnail-slider .thumbnail-list.slider--tablet-up {
    display: none;
  }
}

quick-add-modal .container {
  padding: 0;
}

quick-add-modal .product__title > h1 {
  display: none;
}

quick-add-modal .product__title > a {
  display: block;
  text-decoration: none;
}

quick-add-modal .product__title > a:hover {
  color: #272b1a;
  text-decoration: underline;
  text-decoration-thickness: 0.3rem;
  text-underline-offset: 0.2rem;
}

quick-add-modal .product-form__buttons {
  max-width: none;
}

.quick-add__submit {
  box-sizing: border-box;
  min-width: 100%;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .quick-add__submit {
    margin-bottom: calc(var(--ax) * 10);
  }
}

menu-drawer + .header__search {
  display: none;
}

.header > .header__search {
  grid-area: left-icon;
  justify-self: start;
}

.header:not(.header--has-menu) * > .header__search {
  display: none;
}

.header__search {
  display: inline-flex;
  line-height: 0;
}

.header--top-center > .header__search {
  display: none;
}

.header--top-center * > .header__search {
  display: inline-flex;
}

@media only screen and (min-width: 990px) {
  .header--top-center > .header__search,
  .header:not(.header--top-center) * > .header__search {
    display: inline-flex;
  }

  .header--top-center * > .header__search,
  .header:not(.header--top-center) > .header__search {
    display: none;
  }
}

.no-js .predictive-search {
  display: none;
}

details[open] > .search-modal {
  animation: animateMenuOpen var(--duration-default) ease;
  opacity: 1;
}

details[open] .modal-overlay {
  display: block;
}

details[open] .modal-overlay:after {
  background-color: rgba(39, 43, 26, 0.5);
  content: "";
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
}

.no-js details[open] > .header__icon--search {
  right: 0.5rem;
  top: 1rem;
}

.search-modal {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  height: 100%;
  min-height: calc(100% + var(--radius-margin-offset) + 0.2rem);
  opacity: 0;
}

.search-modal__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: calc(1 + 0.8 / var (--font-body-scale));
  padding: 0 5rem 0 1rem;
  position: relative;
  width: 100%;
}

.search-modal__content-bottom {
  bottom: calc(var(--radius-margin-offset) / 2);
}

.search-modal__content-top {
  top: calc(var(--radius-margin-offset) / 2);
}

.search-modal__form {
  width: 100%;
}

.search-modal__close-button {
  position: absolute;
  right: 0.3rem;
}

@media only screen and (min-width: 750px) {
  .search-modal__close-button {
    right: 1rem;
  }

  .search-modal__content {
    padding: 0 6rem;
  }
}

@media only screen and (min-width: 990px) {
  .search-modal__form {
    max-width: 47.8rem;
  }

  .search-modal__close-button {
    margin-left: 0.5rem;
    position: static;
  }
}

.footer {
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.footer:not(.color-background-1) {
  border-top: none;
}

.footer__content-top {
  display: block;
  padding-bottom: calc(var(--ax) * 50);
}

@media only screen and (max-width: 989px) {
  .footer__content-top {
    display: block;
    padding-bottom: 0;
    padding-left: calc(var(--ax) * 15);
    padding-right: calc(var(--ax) * 15);
  }
}

@media only screen and (min-width: 990px) {
  .footer__content-top .grid {
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 0;
    row-gap: calc(var(--ax) * 60);
  }
}

.footer__content-bottom-wrapper {
  border-top: 1px solid #c6c3c3;
  display: flex;
  margin-top: calc(var(--ax) * 60);
  padding-top: calc(var(--ax) * 20);
  width: 100%;
}

@media only screen and (max-width: 989px) {
  .footer__content-bottom,
  .footer__content-bottom-wrapper {
    flex-wrap: wrap;
    row-gap: 1.5rem;
  }

  .footer__content-bottom {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.footer__localization:empty + .footer__column--info {
  align-items: center;
}

.footer__column {
  align-items: flex-end;
  width: 100%;
}

.footer__column--info {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (min-width: 990px) {
  .footer__column--info {
    align-items: flex-end;
    padding-left: 0;
    padding-right: 0;
  }
}

.footer__brands {
  margin-top: calc(var(--ax) * 30);
}

@media only screen and (min-width: 990px) {
  .footer__brands {
    margin-top: calc(var(--ax) * 17);
  }
}

.footer__brands__item {
  display: block;
}

.footer__brands__item:not(:first-child) {
  margin-top: calc(var(--ax) * 16);
}

.footer-block:only-child:last-child {
  margin: 0 auto;
  max-width: 76rem;
  text-align: center;
}

.footer-block:empty {
  display: none;
}

.footer-block--newsletter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-block--newsletter:only-child {
  margin-top: 0;
}

.footer-block--newsletter > * {
  flex: 1 1 100%;
}

@media only screen and (max-width: 989px) {
  .footer-block--menu:only-child {
    text-align: left;
  }
}

@media only screen and (min-width: 990px) {
  .footer-block--newsletter {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.footer-block__heading {
  display: inline-block;
  font-size: calc(var(--ax) * 14);
  margin-top: 0;
}

@media only screen and (min-width: 990px) {
  .footer-block__heading {
    font-size: calc(var(--ax) * 20);
  }
}

.footer-block__heading--second {
  margin-top: calc(var(--ax) * 42);
}

@media only screen and (max-width: 989px) {
  .footer-block__heading {
    text-align: left;
    width: 100%;
  }
}

@media only screen and (min-width: 990px) {
  .footer-block__heading {
    margin-right: calc(var(--ax) * 80);
    width: max-content;
  }
}

.footer-block__text {
  margin-top: calc(var(--ax) * 8);
}

.footer-block ul {
  max-width: 100%;
  width: max-content;
}

@media only screen and (max-width: 989px) {
  .footer-block ul {
    margin-left: 0;
    margin-right: auto;
    width: 100%;
  }
}

@media only screen and (min-width: 990px) {
  .footer-block--menu.footer-block.grid__item {
    flex: unset;
    width: max-content;
  }

  .footer-block--newsletter.footer-block.grid__item {
    display: block;
    flex: unset;
    max-width: calc(var(--ax) * 395);
    width: max-content;
  }
}

.footer-block__newsletter {
  text-align: center;
}

@media only screen and (max-width: 989px) {
  .footer-block__newsletter.grid__item {
    align-items: center;
  }

  .footer-block.grid__item {
    width: 100%;
  }

  .footer-block.grid__item:first-of-type,
  .footer-block.grid__item:nth-of-type(2) {
    width: 50%;
  }

  .footer-block.grid__item:nth-of-type(3) {
    border-top: 1px solid hsla(30, 4%, 90%, 0.5);
    justify-content: space-between;
    text-align: center;
  }

  .footer-block.grid__item:nth-of-type(3) .footer-block__heading {
    display: none;
    text-align: center;
  }

  .footer-block.grid__item:nth-of-type(3) ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    row-gap: calc(var(--ax) * 10);
    width: 100%;
  }

  .footer-block.grid__item:nth-of-type(3) ul .footer__brands__item {
    margin: 0;
    width: 50%;
  }

  .footer-block.grid__item:nth-of-type(3) ul img {
    margin: calc(var(--ax) * 20) auto 0;
  }

  .footer-block.grid__item:nth-of-type(4) {
    border-top: 1px solid hsla(30, 4%, 90%, 0.5);
    padding-top: calc(var(--ax) * 30);
  }

  .footer-block.grid__item:nth-of-type(4) .footer-block__heading {
    text-align: center;
  }
}

@media only screen and (min-width: 990px) {
  .footer-block__newsletter:not(:only-child) {
    text-align: left;
  }

  .footer-block__newsletter:not(:only-child) .footer__newsletter {
    justify-content: flex-start;
    margin: 0;
  }

  .footer-block__newsletter:not(:only-child)
    .newsletter-form__message--success {
    left: auto;
  }

  .footer-block__newsletter + .footer__list-social {
    margin-top: 0;
  }
}

.footer-block__details-content {
  margin-bottom: calc(var(--ax) * 40);
}

.footer-block__details-content > li:first-child {
  margin-top: calc(var(--ax) * 10);
}

@media only screen and (min-width: 990px) {
  .footer-block__details-content {
    margin-bottom: 0;
  }

  .footer-block__details-content > li,
  .footer-block__details-content > p {
    line-height: 1.7;
    padding: 0;
  }

  .footer-block .footer-block:only-child li {
    display: inline;
  }

  .footer-block__details-content > li:not(:last-child) {
    margin-right: calc(var(--ax) * 15);
  }

  .footer-block__details-content > li:first-child {
    margin-top: calc(var(--ax) * 5);
  }

  .footer-block__details-content > li:first-child.footer__brands__item {
    margin-top: calc(var(--ax) * 32);
  }

  .footer-block__details-content > li:not(:first-child).footer__brands__item {
    margin-top: calc(var(--ax) * 25);
  }

  .footer-block__details-content .list-menu__item--active:hover {
    text-decoration-thickness: 0.2rem;
  }
}

@media only screen and (max-width: 989px) {
  .footer-block__details-content .list-menu__item--link {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.footer-block__details-content .list-menu__item--link {
  display: inline-block;
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  font-weight: 300;
}

@media only screen and (min-width: 990px) {
  .footer-block__details-content .list-menu__item--link {
    font-size: 1.2rem;
    font-size: clamp(1rem, 0.83328vw, 1.2rem);
  }

  .footer-block__details-content > :first-child .list-menu__item--link {
    padding-top: 0;
  }

  .footer-block__details-content .placeholder-svg {
    max-width: 20rem;
  }
}

.footer-block__details-content .list-menu__item--link {
  color: #e6e5e4;
  text-decoration: none;
}

.footer-block__details-content .list-menu__item--link:hover {
  color: #e6e5e4;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.footer-block--newsletter:empty,
.footer__list-social:empty {
  display: none;
}

.footer__list-social.list-social:only-child {
  justify-content: center;
}

.newsletter-form__field-wrapper {
  --color-foreground: $colorVirgil;
  margin-top: calc(var(--ax) * 22);
}

.newsletter-form__field-wrapper .field__input {
  border: 1px solid #e6e5e4;
  color: #e6e5e4;
}

.newsletter-form__field-wrapper .field__button {
  border: 1px solid #e6e5e4;
  position: static;
}

.newsletter-form__field-wrapper .field:before {
  border: none;
}

.footer__list-social {
  display: flex;
  margin-top: calc(var(--ax) * 10);
  margin-top: calc(var(--ax) * 15);
}

.footer__list-social .icon {
  color: #e6e5e4;
  display: block;
  height: 100%;
  margin: 0 auto;
  width: auto;
}

.footer__list-social .icon path {
  fill: #e6e5e4;
}

.list-social__item:first-child {
  margin-left: calc(var(--ax) * -5);
}

.list-social__link {
  height: calc(var(--ax) * 23);
  margin-right: calc(var(--ax) * 20);
  width: calc(var(--ax) * 23);
}

@media only screen and (max-width: 989px) {
  .footer__list-social.list-social {
    justify-content: center;
  }

  .footer__blocks-wrapper {
    column-gap: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.footer__localization {
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 1rem 0;
}

.footer__localization:empty {
  display: none;
}

.localization-form {
  display: flex;
  flex: auto 1 0;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
}

.localization-form:only-child {
  display: inline-flex;
  flex: initial;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.localization-form:only-child .button,
.localization-form:only-child .localization-form__select {
  flex-grow: 1;
  margin: 1rem 1rem 0.5rem;
}

.footer__localization h2 {
  color: rgba(var(--color-foreground), 0.75);
  margin: 1rem 1rem 0.5rem;
}

@media only screen and (min-width: 990px) {
  .footer__localization {
    justify-content: flex-start;
    padding: 0.4rem 0;
  }

  .footer__localization h2 {
    margin: 1rem 0 0;
  }
}

.localization-form .button {
  padding: 1rem;
}

@media only screen and (max-width: 989px) {
  .localization-form .button {
    word-break: break-all;
  }
}

.localization-form__currency {
  display: inline-block;
}

.localization-form__select {
  border-radius: 0;
  margin-bottom: 1.5rem;
  min-height: 4.2rem;
  min-width: 7.2rem;
  padding-left: 1rem;
  position: relative;
  text-align: left;
}

.localization-form__select .icon-caret {
  content: "";
  height: 0.6rem;
  position: absolute;
  right: 1.6rem;
  top: calc(50% - 0.2rem);
}

@media only screen and (min-width: 990px) {
  .localization-form {
    padding: 1rem 2rem 1rem 0;
  }

  .localization-form:first-of-type {
    padding-left: 0;
  }

  .localization-form:only-child {
    justify-content: start;
    margin: 0 1rem 0 0;
    width: auto;
  }

  .localization-form:only-child .button,
  .localization-form:only-child .localization-form__select {
    margin: 1rem 0;
  }
}

@media only screen and (max-width: 989px) {
  .footer__localization noscript,
  noscript .localization-form:only-child {
    width: 100%;
  }
}

.disclosure__button.localization-form__select {
  background: rgb(var(--color-background));
  padding: 2.1rem;
}

noscript .localization-form__select {
  padding-left: 0;
}

@media only screen and (min-width: 990px) {
  noscript .localization-form__select {
    min-width: 20rem;
  }
}

.localization-selector.link {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #272b1a;
  padding-bottom: 1.5rem;
  padding-right: 4rem;
  text-decoration: none;
  width: 100%;
}

noscript .localization-selector.link {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
}

.disclosure .localization-form__select {
  padding-top: 1.5rem;
}

.localization-selector option {
  color: #000;
}

.localization-selector + .disclosure__list-wrapper {
  animation: animateLocalization var(--duration-default) ease;
  margin-left: 1rem;
  opacity: 1;
}

@media only screen and (min-width: 990px) {
  .footer__payment {
    margin-top: 1.5rem;
  }
}

.footer__copyright {
  margin-top: 0;
  text-align: center;
}

@media only screen and (min-width: 990px) {
  .footer__copyright {
    text-align: right;
  }
}

.copyright__content,
.copyright__content a {
  color: #e6e5e4;
  font-size: 1.1rem;
  font-size: clamp(1rem, 2.8204vw, 1.1rem);
  font-weight: 500;
  text-decoration: none;
}

@media only screen and (min-width: 990px) {
  .copyright__content,
  .copyright__content a {
    font-size: 1.1rem;
    font-size: clamp(1.1rem, 0.76384vw, 1.1rem);
  }
}

.copyright__content {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.copyright__content svg {
  display: inline-block;
  height: auto;
  margin-left: 0.5em;
  width: 5.7em;
}

@media only screen and (min-width: 990px) {
  .copyright__content a:hover {
    color: #e6e5e4;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }
}

@media only screen and (max-width: 989px) {
  .footer-block-image {
    text-align: center;
  }

  .footer-block-image > img {
    height: auto;
  }
}

.copyright__content a {
  color: currentColor;
  text-decoration: none;
}

@keyframes animateLocalization {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(-1rem);
  }
}

.footer .disclosure__link {
  color: rgba(var(--color-foreground), 0.75);
  padding: 0.95rem 3.5rem 0.95rem 2rem;
}

.footer .disclosure__link:hover {
  color: #272b1a;
}

.footer .disclosure__link--active {
  text-decoration: underline;
}

@supports not (inset: 10px) {
  @media only screen and (max-width: 989px) {
    .footer .grid {
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 990px) {
    .footer__content-top .grid {
      margin-left: calc(var(--ax) * -30);
    }

    .footer__content-top .grid__item {
      padding-left: calc(var(--ax) * 30);
    }
  }
}

@media only screen and (min-width: 990px) {
  .footer__content-bottom-wrapper {
    display: flex;
  }

  .footer__content-bottom-wrapper > div {
    width: 50%;
  }
}

.footer__legal {
  display: flex;
}

@media only screen and (min-width: 990px) {
  .footer__legal {
    margin-top: calc(var(--ax) * 20);
  }
}

.footer__legal__item {
  margin-left: calc(var(--ax) * 25);
}

.footer__legal__item a {
  color: #e6e5e4;
  font-size: 1rem;
  font-size: clamp(1rem, 2.564vw, 1rem);
  text-decoration: none;
}

@media only screen and (min-width: 990px) {
  .footer__legal__item a {
    font-size: 1rem;
    font-size: clamp(1rem, 0.6944vw, 1rem);
  }

  .footer__legal__item:first-child {
    margin-left: auto;
  }
}

.icon-payments {
  display: block;
  width: calc(100% - var(--ax) * 60);
}

@media only screen and (max-width: 989px) {
  .icon-payments {
    margin: 0 auto;
  }
}

@media only screen and (min-width: 990px) {
  .icon-payments {
    width: calc(var(--ax) * 415);
  }
}

.product {
  margin: 0;
}

.product.grid {
  gap: 0;
}

.product--no-media {
  margin: 0 auto;
  max-width: 57rem;
}

.product__media-wrapper {
  padding-left: 0;
}

@media only screen and (min-width: 750px) {
  .product--columns .product__info-container--sticky,
  .product--stacked .product__info-container--sticky,
  .product--thumbnail .product__media-gallery,
  .product--thumbnail_slider .product__media-gallery {
    display: block;
    position: sticky;
    top: 3rem;
    z-index: 2;
  }

  .product--thumbnail .thumbnail-list {
    padding-right: var(--media-shadow-horizontal-offset);
  }

  .product__info-wrapper {
    padding: 0 0 0 5rem;
  }

  .product__info-wrapper--extra-padding {
    padding: 0 0 0 8rem;
  }

  .product--right .product__info-wrapper {
    padding: 0 5rem 0 0;
  }

  .product--right .product__info-wrapper--extra-padding {
    padding: 0 8rem 0 0;
  }

  .product--right .product__media-list {
    margin-bottom: 2rem;
  }

  .product__media-container .slider-buttons {
    display: none;
  }
}

@media only screen and (min-width: 990px) {
  .product--large:not(.product--no-media) .product__media-wrapper {
    max-width: 65%;
    width: calc(65% - var(--ax) * 5 / 2);
  }

  .product--large:not(.product--no-media) .product__info-wrapper {
    max-width: 35%;
    padding: 0 0 0 4rem;
    width: calc(35% - var(--ax) * 5 / 2);
  }

  .product--large:not(.product--no-media).product--right
    .product__info-wrapper {
    padding: 0 4rem 0 0;
  }

  .product--medium:not(.product--no-media) .product__media-wrapper,
  .product--small:not(.product--no-media) .product__info-wrapper {
    max-width: 55%;
    width: calc(55% - var(--ax) * 5 / 2);
  }

  .product--medium:not(.product--no-media) .product__info-wrapper,
  .product--small:not(.product--no-media) .product__media-wrapper {
    max-width: 45%;
    width: calc(45% - var(--ax) * 5 / 2);
  }
}

.shopify-payment-button__button {
  font-family: inherit;
  min-height: 4.6rem;
}

.no-js .shopify-payment-button__button [role="button"]:focus,
.shopify-payment-button__button [role="button"].focused {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-button), var(--alpha-button-border)),
    0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3) !important;
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5) !important;
  outline-offset: 0.3rem;
}

.shopify-payment-button__button [role="button"]:focus:not(:focus-visible) {
  box-shadow: none !important;
  outline: 0;
}

.shopify-payment-button__button [role="button"]:focus-visible {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-button), var(--alpha-button-border)),
    0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3) !important;
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5) !important;
}

.shopify-payment-button__button--unbranded {
  background-color: rgba(var(--color-button), var(--alpha-button-background));
  color: rgb(var(--color-button-text));
  font-size: 1.4rem;
  letter-spacing: 0.07rem;
  line-height: calc(1 + 0.2 / var (--font-body-scale));
}

.shopify-payment-button__button--unbranded::selection {
  background-color: rgba(var(--color-button-text), 0.3);
}

.shopify-payment-button__button--unbranded:hover,
.shopify-payment-button__button--unbranded:hover:not([disabled]) {
  background-color: rgba(var(--color-button), var(--alpha-button-background));
}

.shopify-payment-button__more-options {
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  line-height: calc(1 + 0.5 / var (--font-body-scale));
  margin: 1.6rem 0 1rem;
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.shopify-payment-button__button + .shopify-payment-button__button--hidden {
  display: none;
}

.product-form {
  display: block;
}

.product-form__error-message-wrapper:not([hidden]) {
  align-items: flex-start;
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.product-form__error-message-wrapper svg {
  flex-shrink: 0;
  height: 1.2rem;
  margin-right: 0.7rem;
  margin-top: 0.5rem;
  width: 1.2rem;
}

.product-form__input {
  border: none;
  flex: 0 0 100%;
  margin: 0 0 1.2rem;
  max-width: 37rem;
  min-width: fit-content;
  padding: 0;
}

variant-radios,
variant-selects {
  display: block;
}

.product variant-radios {
  margin-top: calc(var(--ax) * 20);
}

.product-form__input--dropdown {
  margin-bottom: 1.6rem;
}

.product-form__input .form__label {
  padding-left: 0;
}

fieldset.product-form__input .form__label {
  margin-bottom: 0.2rem;
}

.product-form__input input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.product-form__input input[type="radio"] + label {
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 1);
  border-radius: 0;
  color: rgba(var(--color-foreground));
  color: #272b1a;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 1rem 2rem;
  position: relative;
  text-align: center;
  transition: border var(--duration-short) ease;
}

.product-form__input input[type="radio"] + label:before {
  display: none;
}

.product-form__input input[type="radio"] + label:hover {
  border-color: #272b1a;
}

.product-form__input input[type="radio"]:checked + label {
  background-color: #272b1a;
  color: rgb(var(--color-background));
}

@media screen and (forced-colors: active) {
  .product-form__input input[type="radio"]:checked + label {
    text-decoration: underline;
  }
}

.product-form__input input[type="radio"]:checked + label::selection {
  background-color: rgba(var(--color-background), 0.3);
}

.product-form__input input[type="radio"]:disabled + label {
  border-color: rgba(var(--color-foreground), 0.1);
  color: rgba(var(--color-foreground), 0.4);
  text-decoration: line-through;
}

.product-form__input input[type="radio"]:focus-visible + label {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.55);
}

.no-js .shopify-payment-button__button [role="button"]:focus + label,
.product-form__input input[type="radio"].focused + label {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.55);
}

.no-js
  .product-form__input
  input[type="radio"]:focus:not(:focus-visible)
  + label {
  box-shadow: none;
}

.product-form__input .select {
  max-width: 25rem;
}

.product-form__submit {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 989px) {
  .product-form__submit {
    max-width: calc(var(--ax) * 175);
    width: 100%;
  }
}

.no-js .product-form__submit.button--secondary {
  --color-button: var(--color-base-accent-1);
  --color-button-text: var(--color-base-solid-button-labels);
  --alpha-button-background: 1;
}

.product-form__submit[aria-disabled="true"]
  + .shopify-payment-button
  .shopify-payment-button__button[disabled],
.product-form__submit[disabled]
  + .shopify-payment-button
  .shopify-payment-button__button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

@media screen and (forced-colors: active) {
  .product-form__submit[aria-disabled="true"] {
    color: Window;
  }
}

.shopify-payment-button__more-options {
  color: #272b1a;
}

.shopify-payment-button__button {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}

.product__info-container > * + * {
  margin: 1.5rem 0;
}

.product__info-container iframe {
  max-width: 100%;
}

.product__text {
  margin-bottom: 0;
}

a.product__text {
  color: rgba(var(--color-foreground), 0.75);
  display: block;
  text-decoration: none;
}

.product__text.eyebrow {
  text-transform: uppercase;
}

.product__title {
  margin-bottom: calc(var(--ax) * 20);
  word-break: break-word;
}

@media only screen and (max-width: 989px) {
  .product__title {
    margin-bottom: calc(var(--ax) * -10);
  }
}

.product__title > * {
  letter-spacing: 0.02em;
  margin: 0;
}

.product__title > a {
  display: none;
}

.product__title h1 {
  text-transform: capitalize;
}

.product__title + .product__text.pf-eyebrow {
  margin-top: calc(var(--ax) * -15);
}

.product__text.pf-eyebrow + .product__title {
  margin-top: calc(var(--ax) * 5);
}

.product__accordion .accordion__content {
  padding: 0 calc(var(--ax) * 5) calc(var(--ax) * 15) 0;
}

.product .price {
  margin-top: calc(var(--ax) * 20);
}

.product .price .badge,
.product .price dl,
.product .price__container {
  margin-bottom: 0.5rem;
}

.product .price dl {
  margin-top: 0.5rem;
}

.product .price--sold-out .price__badge-sold-out {
  background: transparent;
  border-color: transparent;
  color: rgb(var(--color-base-text));
}

.product .price--sold-out .price__badge-sale {
  display: none;
}

@media only screen and (min-width: 750px) {
  .product__info-container {
    max-width: 60rem;
  }

  .product__info-container .price--on-sale .price-item--regular {
    font-size: 1.6rem;
  }

  .product__info-container > :first-child {
    margin-top: 0;
  }
}

.product__description-title {
  font-weight: 600;
}

.product--no-media .product__tax,
.product--no-media .product__text,
.product--no-media .product__title,
.product--no-media noscript .product-form__input,
.product--no-media shopify-payment-terms {
  text-align: center;
}

.product--no-media .product__info-wrapper,
.product--no-media .product__media-wrapper {
  padding: 0;
}

.product__tax {
  margin-top: -1.4rem;
}

.product--no-media .share-button,
.product--no-media noscript .product-form__input {
  max-width: 100%;
}

.product--no-media .product-form,
.product--no-media .product-form__input--dropdown,
.product--no-media .product-form__quantity,
.product--no-media .product__pickup-availabilities,
.product--no-media .product__view-details,
.product--no-media .share-button,
.product--no-media fieldset.product-form__input {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.product--no-media .product-form {
  flex-direction: column;
}

.product--no-media .product-form > .form {
  max-width: 30rem;
  width: 100%;
}

.product--no-media .product-form__input--dropdown,
.product--no-media .product-form__quantity {
  flex-direction: column;
  max-width: 100%;
}

.product-form__quantity .form__label {
  margin-bottom: 0.6rem;
}

.product-form__quantity-top .form__label {
  margin-bottom: 1.2rem;
}

.product--no-media fieldset.product-form__input {
  flex-wrap: wrap;
  margin: 0 auto 1.2rem;
}

.product-form__buttons {
  max-width: 44rem;
}

.product--no-media .product__info-container > modal-opener {
  display: block;
  text-align: center;
}

.product--no-media .product-popup-modal__button {
  padding-right: 0;
}

.product--no-media .price {
  text-align: center;
}

.product__media-list video {
  border-radius: calc(0 - var(--media-border-width));
}

@media screen and (max-width: 749px) {
  .product__media-list {
    margin-bottom: 3rem;
    margin-left: -2.5rem;
    width: calc(100% + 4rem);
  }

  .product__media-wrapper slider-component:not(.thumbnail-slider--no-slide) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .slider.product__media-list::-webkit-scrollbar {
    height: 0.2rem;
    width: 0.2rem;
  }

  .product__media-list::-webkit-scrollbar-thumb {
    background-color: #272b1a;
  }

  .product__media-list::-webkit-scrollbar-track {
    background-color: rgba(var(--color-foreground), 0.2);
  }

  .product__media-list .product__media-item {
    width: calc(100% - 3rem - var(--ax) * 5);
  }

  .product--mobile-columns .product__media-item {
    width: calc(50% - 1.5rem - var(--ax) * 5);
  }
}

@media only screen and (min-width: 750px) {
  .product--thumbnail .product__media-list,
  .product--thumbnail_slider .product__media-list {
    padding-bottom: calc(
      var(--media-shadow-vertical-offset) * var(--media-shadow-visible)
    );
  }

  .product__media-list {
    padding-right: calc(
      var(--media-shadow-horizontal-offset) * var(--media-shadow-visible)
    );
  }

  .product--thumbnail .product__media-item:not(.is-active),
  .product--thumbnail_slider .product__media-item:not(.is-active),
  .product-media-modal__content
    > .product__media-item--variant.product__media-item--variant {
    display: none;
  }

  .product-media-modal__content > .product__media-item--variant:first-child {
    display: block;
  }
}

.product__media-item.product__media-item--variant {
  display: none;
}

.product__media-item--variant:first-child {
  display: block;
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .product__media-list .product__media-item:first-child {
    padding-left: 0;
  }

  .product--thumbnail_slider .product__media-list {
    margin-left: 0;
  }

  .product__media-list .product__media-item {
    width: 100%;
  }
}

.product__media-icon .icon {
  height: 1.4rem;
  width: 1.2rem;
}

.product__media-icon,
.thumbnail__badge {
  align-items: center;
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: #272b1a;
  display: flex;
  height: 3rem;
  justify-content: center;
  left: calc(1.2rem + var(--media-border-width));
  position: absolute;
  top: calc(1.2rem + var(--media-border-width));
  transition: color var(--duration-short) ease,
    opacity var(--duration-short) ease;
  width: 3rem;
  z-index: 1;
}

.product__media-video .product__media-icon {
  opacity: 1;
}

.product__modal-opener--image .product__media-toggle:hover {
  cursor: zoom-in;
}

.product__modal-opener:hover .product__media-icon {
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
}

@media only screen and (min-width: 750px) {
  .grid__item.product__media-item--full {
    width: 100%;
  }

  .product--columns
    .product__media-item:not(.product__media-item--single):not(:only-child) {
    max-width: calc(50% - var(--ax) * 5 / 2);
  }

  .product--large.product--columns
    .product__media-item--full
    .deferred-media__poster-button {
    height: 5rem;
    width: 5rem;
  }

  .product--medium.product--columns
    .product__media-item--full
    .deferred-media__poster-button {
    height: 4.2rem;
    width: 4.2rem;
  }

  .product--medium.product--columns
    .product__media-item--full
    .deferred-media__poster-button
    .icon {
    height: 1.8rem;
    width: 1.8rem;
  }

  .product--small.product--columns
    .product__media-item--full
    .deferred-media__poster-button {
    height: 3.6rem;
    width: 3.6rem;
  }

  .product--small.product--columns
    .product__media-item--full
    .deferred-media__poster-button
    .icon {
    height: 1.6rem;
    width: 1.6rem;
  }
}

@media only screen and (min-width: 990px) {
  .product--stacked .product__media-item {
    max-width: calc(50% - var(--ax) * 5 / 2);
  }

  .product:not(.product--columns)
    .product__media-list
    .product__media-item--full,
  .product:not(.product--columns)
    .product__media-list
    .product__media-item:first-child {
    max-width: 100%;
    width: 100%;
  }

  .product__modal-opener .product__media-icon {
    opacity: 0;
  }

  .product__modal-opener:focus .product__media-icon,
  .product__modal-opener:hover .product__media-icon {
    opacity: 1;
  }
}

.product__media-item > * {
  display: block;
  position: relative;
}

.product__media-toggle {
  background-color: transparent;
  border: none;
  color: currentColor;
  display: flex;
  padding: 0;
}

.product__media-toggle:after {
  content: "";
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.product__media-toggle:focus-visible {
  box-shadow: none;
  outline: 0;
}

@media (forced-colors: active) {
  .product-form__input input[type="radio"]:focus-visible + label,
  .product__media-toggle:focus-visible,
  .product__media-toggle:focus-visible:after {
    outline: 1px solid transparent;
    outline-offset: 2px;
  }
}

.product__media-toggle.focused {
  box-shadow: none;
  outline: 0;
}

.product__media-toggle:focus-visible:after {
  border-radius: 0;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.5);
}

.product__media-toggle.focused:after {
  border-radius: 0;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.5);
}

.product-media-modal {
  background-color: rgb(var(--color-background));
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}

.product-media-modal[open] {
  opacity: 1;
  visibility: visible;
  z-index: 101;
}

.product-media-modal__dialog {
  align-items: center;
  display: flex;
  height: 100vh;
}

.product-media-modal__content {
  max-height: 100vh;
  overflow: auto;
  width: 100%;
}

.product-media-modal__content > :not(.active),
.product__media-list .deferred-media {
  display: none;
}

@media only screen and (min-width: 750px) {
  .product-media-modal__content {
    padding-bottom: calc(var(--ax) * 46);
  }

  .product-media-modal__content > :not(.active) {
    display: block;
  }

  .product__modal-opener:not(.product__modal-opener--image) {
    display: none;
  }

  .product__media-list .deferred-media {
    display: block;
  }
}

@media screen and (max-width: 749px) {
  .product--thumbnail
    .is-active
    > .product__modal-opener:not(.product__modal-opener--image),
  .product--thumbnail_slider
    .is-active
    > .product__modal-opener:not(.product__modal-opener--image) {
    display: none;
  }

  .product--thumbnail .is-active .deferred-media,
  .product--thumbnail_slider .is-active .deferred-media {
    display: block;
    width: 100%;
  }
}

.product-media-modal__content > * {
  display: block;
  height: auto;
  margin: auto;
}

.product-media-modal__content .media {
  background: none;
}

.product-media-modal__model {
  width: 100%;
}

.product-media-modal__toggle {
  align-items: center;
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  position: fixed;
  right: 2rem;
  top: 2rem;
  width: 4rem;
  z-index: 2;
}

.product-media-modal__content .deferred-media {
  width: 100%;
}

@media only screen and (min-width: 750px) {
  .product-media-modal__content {
    padding: calc(var(--ax) * 46) calc(var(--ax) * 100);
  }

  .product-media-modal__content > * {
    height: 100%;
    max-height: calc(100vh - var(--ax) * 100);
    width: auto;
  }

  .product-media-modal__content > * + * {
    margin-top: 2rem;
  }

  .product-media-modal__toggle {
    right: 5rem;
    top: 2.2rem;
  }
}

@media only screen and (min-width: 990px) {
  .product-media-modal__content {
    padding: calc(var(--ax) * 46) calc(var(--ax) * 100);
  }

  .product-media-modal__content > * + * {
    margin-top: calc(var(--ax) * 20);
  }

  .product-media-modal__content {
    padding-bottom: calc(var(--ax) * 20);
  }

  .product-media-modal__toggle {
    right: calc(var(--ax) * 50);
  }
}

.product-media-modal__toggle:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.product-media-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

.product-popup-modal {
  background: rgba(var(--color-foreground), 0.2);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}

.product-popup-modal[open] {
  opacity: 1;
  visibility: visible;
  z-index: 101;
}

.product-popup-modal__content {
  background-color: rgb(var(--color-background));
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-radius: var(--popup-corner-radius);
  border-style: solid;
  border-width: var(--popup-border-width);
  box-shadow: var(--popup-shadow-horizontal-offset)
    var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
    rgba(var(--color-shadow), var(--popup-shadow-opacity));
  height: 80%;
  left: 50%;
  margin: 5rem auto 0;
  overflow: auto;
  padding: 0 1.5rem 0 3rem;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 92%;
}

.product-popup-modal__content.focused {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3),
    var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset)
      var(--popup-shadow-blur-radius)
      rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

.product-popup-modal__content:focus-visible {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3),
    var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset)
      var(--popup-shadow-blur-radius)
      rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

@media only screen and (min-width: 750px) {
  .product-popup-modal__content {
    margin-top: 10rem;
    padding: 0 3rem;
    width: 70%;
  }

  .product-media-modal__dialog .global-media-settings--no-shadow {
    overflow: visible !important;
  }
}

.product-popup-modal__content img {
  max-width: 100%;
}

@media screen and (max-width: 749px) {
  .product-popup-modal__content table {
    display: block;
    margin: 0;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
  }

  .product-media-modal__dialog .global-media-settings,
  .product-media-modal__dialog .global-media-settings iframe,
  .product-media-modal__dialog .global-media-settings img,
  .product-media-modal__dialog .global-media-settings model-viewer,
  .product-media-modal__dialog .global-media-settings video {
    border: none;
    border-radius: 0;
  }
}

.product-popup-modal__opener {
  display: inline-block;
}

.product-popup-modal__button {
  font-size: 1.6rem;
  height: 4.4rem;
  padding-left: 0;
  padding-right: 1.3rem;
  text-decoration-thickness: 0.1rem;
  text-underline-offset: 0.3rem;
  transition: text-decoration-thickness var(--duration-short) ease;
}

.product-popup-modal__button:hover {
  text-decoration-thickness: 0.2rem;
}

.product-popup-modal__content-info {
  padding-right: 4.4rem;
}

.product-popup-modal__content-info > * {
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 749px) {
  .product-popup-modal__content-info > * {
    max-height: 100%;
  }
}

.product-popup-modal__toggle {
  align-items: center;
  background-color: rgb(var(--color-background));
  border: 0.1rem solid rgba(var(--color-foreground), 0.1);
  border-radius: 50%;
  color: rgba(var(--color-foreground), 0.55);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 0 0 auto;
  padding: 1.2rem;
  position: sticky;
  top: 1.5rem;
  width: 4rem;
  z-index: 2;
}

.product-popup-modal__toggle:hover {
  color: rgba(var(--color-foreground), 0.75);
}

.product-popup-modal__toggle .icon {
  height: auto;
  margin: 0;
  width: 2.2rem;
}

.product__media-list .media > * {
  overflow: hidden;
}

.thumbnail-list {
  grid-gap: 1rem;
  flex-wrap: wrap;
}

.slider--mobile.thumbnail-list:after {
  content: none;
}

@media only screen and (min-width: 750px) {
  .product--stacked .thumbnail-list {
    display: none;
  }

  .thumbnail-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.thumbnail-list_item--variant:not(:first-child) {
  display: none;
}

@media only screen and (min-width: 990px) {
  .thumbnail-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .product--medium .thumbnail-list {
    grid-template-columns: repeat(5, 1fr);
  }

  .product--large .thumbnail-list {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 749px) {
  .product__media-item {
    align-items: center;
    display: flex;
  }

  .product__modal-opener {
    width: 100%;
  }

  .thumbnail-slider {
    align-items: center;
    display: flex;
  }

  .thumbnail-slider .thumbnail-list.slider {
    display: flex;
    flex: 1;
    padding: 0.5rem;
    scroll-padding-left: 0.5rem;
  }

  .thumbnail-list__item.slider__slide {
    width: calc(33% - 0.6rem);
  }
}

@media only screen and (min-width: 750px) {
  .product--thumbnail_slider .thumbnail-slider {
    align-items: center;
    display: flex;
  }

  .thumbnail-slider .thumbnail-list.slider--tablet-up {
    display: flex;
    flex: 1;
    padding: 0.5rem;
    scroll-padding-left: 0.5rem;
  }

  .product__media-wrapper .slider-mobile-gutter .slider-button {
    display: none;
  }

  .thumbnail-list.slider--tablet-up .thumbnail-list__item.slider__slide {
    width: calc(25% - 0.8rem);
  }

  .product--thumbnail_slider .slider-mobile-gutter .slider-button {
    display: flex;
  }
}

@media screen and (min-width: 900px) {
  .product--small
    .thumbnail-list.slider--tablet-up
    .thumbnail-list__item.slider__slide {
    width: calc(25% - 0.8rem);
  }

  .thumbnail-list.slider--tablet-up .thumbnail-list__item.slider__slide {
    width: calc(20% - 0.8rem);
  }
}

.thumbnail {
  background-color: transparent;
  color: rgb(var(--color-base-text));
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.thumbnail:hover {
  opacity: 0.7;
}

.thumbnail.global-media-settings img {
  border-radius: 0;
}

.thumbnail[aria-current] {
  border-color: #272b1a;
  box-shadow: 0 0 0 0.1rem #272b1a;
}

.thumbnail[aria-current]:focus-visible {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.5);
}

.thumbnail.focused,
.thumbnail[aria-current]:focus {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)),
    0 0 0 0.5rem rgba(var(--color-foreground), 0.5);
  outline: 0;
}

@media (forced-colors: active) {
  .thumbnail.focused,
  .thumbnail[aria-current]:focus {
    outline: 1px solid transparent;
  }
}

.thumbnail[aria-current]:focus:not(:focus-visible) {
  box-shadow: 0 0 0 0.1rem #272b1a;
  outline: 0;
}

.thumbnail img {
  pointer-events: none;
}

.thumbnail--narrow img {
  height: 100%;
  max-width: 100%;
  width: auto;
}

.thumbnail--wide img {
  height: auto;
  width: 100%;
}

.thumbnail__badge .icon {
  height: 1rem;
  width: 1rem;
}

.thumbnail__badge .icon-3d-model {
  height: 1.2rem;
  width: 1.2rem;
}

.thumbnail__badge {
  color: rgba(39, 43, 26, 0.6);
  height: 2rem;
  left: auto;
  right: calc(0.4rem + var(--media-border-width));
  top: calc(0.4rem + var(--media-border-width));
  width: 2rem;
}

@media only screen and (min-width: 750px) {
  .product:not(.product--small) .thumbnail__badge {
    height: 3rem;
    width: 3rem;
  }

  .product:not(.product--small) .thumbnail__badge .icon {
    height: 1.2rem;
    width: 1.2rem;
  }

  .product:not(.product--small) .thumbnail__badge .icon-3d-model {
    height: 1.4rem;
    width: 1.4rem;
  }
}

.thumbnail-list__item {
  border: none;
  position: relative;
}

.thumbnail-list__item img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.thumbnail-list__item .thumbnail,
.thumbnail-list__item .thumbnail[aria-current] {
  border: none;
  box-shadow: none;
}

.thumbnail-list__item:before {
  content: "";
  display: block;
  padding-bottom: 125%;
}

.product:not(.featured-product) .product__view-details {
  display: none;
}

.product__view-details {
  display: block;
  text-decoration: none;
}

.product__view-details:hover {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.product__view-details .icon {
  flex-shrink: 0;
  margin-left: 1.2rem;
  width: 1.2rem;
}

.product__media {
  aspect-ratio: 0.82 !important;
  padding-top: 0 !important;
}

@media only screen and (min-width: 990px) {
  .product--thumbnail_slider .product__media-gallery {
    display: flex;
    flex-wrap: wrap;
  }

  .product--thumbnail_slider .product__media-gallery [role="status"] {
    order: 3;
  }

  .product--thumbnail_slider .product__media-gallery slider-component {
    order: 2;
    width: calc(100% - var(--ax) * 105);
  }

  .product--thumbnail_slider
    .product__media-gallery
    slider-component.thumbnail-slider {
    align-items: flex-start;
    order: 1;
    width: calc(var(--ax) * 105);
  }

  .product--thumbnail_slider
    .product__media-gallery
    slider-component.thumbnail-slider
    .thumbnail-list.slider--tablet-up {
    display: block;
    padding: 0;
  }

  .product--thumbnail_slider
    .product__media-gallery
    slider-component.thumbnail-slider
    .thumbnail-list.slider--tablet-up
    .thumbnail-list__item.slider__slide {
    width: calc(var(--ax) * 85);
  }

  .product--thumbnail_slider
    .product__media-gallery
    slider-component.thumbnail-slider
    .thumbnail-list.slider--tablet-up
    .thumbnail-list__item.slider__slide:not(:first-of-type) {
    margin-top: var(--ax20);
  }
}

.product-recommendations {
  display: block;
}

.product-recommendations__heading {
  margin: 0 0 3rem;
}

@media screen and (max-width: 749px) {
  .collection .grid__item:only-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 989px) {
  .collection .slider.slider--tablet {
    margin-bottom: 1.5rem;
  }
}

.collection {
  position: relative;
  z-index: 1;
}

.collection .loading-overlay {
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0.7;
  padding: 0 1.5rem;
  right: 0;
  top: 0;
  width: 100%;
}

@media only screen and (min-width: 750px) {
  .collection .loading-overlay {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.collection.loading .loading-overlay {
  display: block;
}

.collection--empty .title-wrapper {
  margin-bottom: 15rem;
  margin-top: 10rem;
}

@media screen and (max-width: 989px) {
  .collection .slider--tablet.product-grid {
    scroll-padding-left: 1.5rem;
  }
}

.collection__description > * {
  margin: 0;
}

.collection__title.title-wrapper {
  margin-bottom: 2.5rem;
}

.collection__title .title:not(:only-child) {
  margin-bottom: 1rem;
}

@media only screen and (min-width: 990px) {
  .collection__title--desktop-slider .title {
    margin-bottom: 2.5rem;
  }

  .collection__title.title-wrapper--self-padded-tablet-down {
    padding: 0 5rem;
  }

  .collection slider-component:not(.container-desktop) {
    padding: 0;
  }

  .collection--full-width slider-component:not(.slider-component-desktop) {
    max-width: none;
    padding: 0 1.5rem;
  }
}

.collection__view-all a:not(.link) {
  margin-top: 1rem;
}

.cb {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 989px) {
  .cb > div:last-child {
    margin-top: calc(var(--ax) * 6);
  }

  .cb--reverse-mobile > div {
    order: 1;
  }

  .cb--reverse-mobile > div:first-child {
    margin-top: calc(var(--ax) * 6);
    order: 2;
  }

  .cb--reverse-mobile > div:last-child {
    margin-top: 0;
  }
}

.cb__bg {
  height: 100%;
  top: 0;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .cb--it2 .cb__bg {
    height: calc(100% - var(--ax) * 124);
    top: calc(var(--ax) * 62);
  }
}

@media only screen and (max-width: 989px) {
  .cb .block-bg {
    margin-top: calc(var(--ax) * 120);
  }

  .cb .block-bg:first-child {
    margin-top: 0;
  }
}

.cb__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .cb__item {
    padding: calc(var(--ax) * 60) 0;
  }

  .cb__item--it1 {
    overflow: hidden;
  }

  .cb__item--it2 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1512px) {
  .cb__item--image-left {
    margin-left: 0;
  }

  .cb__item--image-right {
    margin-right: 0;
  }
}

.cb__content {
  padding: calc(var(--ax) * 30) calc(var(--ax) * 15);
  position: relative;
  z-index: 3;
}

.cb__content__bg {
  pointer-events: none;
  z-index: 0;
}

.cb__content--it2 {
  color: #e6e5e4;
  padding: calc(var(--ax) * 45) calc(var(--ax) * 30);
}

@media only screen and (min-width: 990px) {
  .cb__content {
    align-self: center;
    padding: calc(var(--ax) * 95) calc(var(--ax) * 85);
    width: calc(32.778% + var(--ax) * 130);
  }

  .cb__content--it2 {
    align-items: center;
    box-sizing: content-box;
    margin: auto;
    max-width: calc(var(--ax) * 522);
    padding: calc(var(--ax) * 62) calc(var(--ax) * 85);
    width: 52.222%;
  }

  .cb__content--it1 {
    margin-bottom: calc(var(--ax) * 67);
    margin-top: calc(var(--ax) * 67);
  }

  .cb__content--it1.wide {
    width: calc(32.778% + var(--ax) * 330);
  }

  .cb__content--it1.image-left {
    margin-left: calc(var(--ax) * -193);
    margin-right: calc(var(--ax) * -193);
  }

  .cb__content--it1.image-left.wide {
    margin-left: calc(var(--ax) * -393);
    margin-right: calc(var(--ax) * -393);
  }

  .cb__content--it1.image-right {
    margin-left: auto;
  }
}

@media only screen and (max-width: 989px) {
  .cb__content {
    order: 2;
  }
}

.cb__title {
  margin-top: 0.5em;
}

.cb__eyebrow,
.cb__text,
.cb__title {
  position: relative;
  z-index: 1;
}

.cb__text {
  margin-top: 1.5625em;
  padding-left: 2em;
}

@media only screen and (min-width: 990px) {
  .cb__text {
    padding-left: 3.125em;
  }
}

.cb__text--it2 {
  margin-top: 2em;
}

.cb__buttons {
  display: flex;
  margin-top: calc(var(--ax) * 30);
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .cb__buttons {
    margin-top: calc(var(--ax) * 48);
  }
}

.cb__button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.cb__button--2 {
  margin-left: calc(var(--ax) * 16);
}

@media only screen and (min-width: 990px) {
  .cb__button {
    min-width: calc(var(--ax) * 98);
  }
}

@media only screen and (max-width: 989px) {
  .cb__button {
    width: 100%;
  }
}

.cb__media {
  position: relative;
  width: 100%;
  z-index: 2;
}

.cb__media img {
  display: block;
}

@media only screen and (min-width: 990px) {
  .cb__media--it1 {
    min-height: calc(52.36vw - var(--ax) * 134);
    width: 67.222%;
  }

  .cb__media--it1 img {
    bottom: 0;
    height: 100%;
    top: 0;
  }

  .cb__media--it1.image-right {
    margin-left: calc(var(--ax) * -193);
    margin-right: 0;
  }

  .cb__media--it1.image-right.wide {
    margin-left: calc(var(--ax) * -393);
  }

  .cb__media--it2 {
    min-height: 50vw;
    width: 45%;
  }

  .cb__media--it2 img {
    bottom: calc(var(--ax) * 67);
    height: calc(100% + var(--ax) * 134);
    top: calc(var(--ax) * -67);
  }
}

@media only screen and (min-width: 1512px) {
  .cb__media {
    min-height: calc(var(--ax) * 658);
  }
}

@media only screen and (max-width: 989px) {
  .cb__media {
    order: 1;
  }

  .cb__media--it2 {
    margin: calc(var(--ax) * -90) auto 0;
    width: calc(100% - var(--ax) * 60);
  }

  .cb__media--it2 img {
    height: auto;
  }

  .cb__media img {
    object-fit: unset;
    position: static;
  }

  .cb__circle-block .circle {
    margin: 0 auto;
    max-width: calc(var(--ax) * 250);
  }
}

@media only screen and (min-width: 990px) {
  .cb__circle-block {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .cb__circle-block .circle {
    max-width: calc(var(--ax) * 345);
    width: 25%;
  }
}

.circle-bg {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: flex-start;
  min-height: 61.5vw;
  position: relative;
  width: 100%;
}

.circle-bg .svg-circle {
  bottom: calc(var(--ax) * -30);
  display: block;
  height: auto;
  left: -5%;
  position: absolute;
  width: 155%;
  z-index: 1;
}

@media only screen and (max-width: 749px) {
  .circle-bg .svg-circle {
    opacity: 0.15;
  }

  .circle-bg .svg-circle circle,
  .circle-bg .svg-circle line {
    stroke-width: 2vw;
  }
}

@media only screen and (min-width: 750px) {
  .circle-bg .svg-circle {
    bottom: unset;
    height: 100%;
    left: -5.13%;
    top: 0;
    width: auto;
  }
}

.circle-bg__media,
.circle-bg__media:before {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.circle-bg__media:before {
  background: #000;
  content: "";
  display: block;
  opacity: 0;
}

.circle-bg__main {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 750px) {
  .circle-bg__main {
    overflow: hidden;
    width: 105.13%;
  }
}

.circle-bg__content {
  align-items: flex-start;
  aspect-ratio: 1;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(var(--ax) * 30);
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 750px) {
  .circle-bg__content {
    left: -5.13%;
    max-width: calc(var(--ax) * 478);
    min-height: calc(var(--ax) * 474);
    padding: calc(var(--ax) * 226) calc(var(--ax) * 212) calc(var(--ax) * 185);
  }
}

.circle-bg__title--has-eyebrow {
  margin-top: calc(var(--ax) * 20);
}

.circle-bg__text {
  margin-top: calc(var(--ax) * 32);
  padding-left: calc(var(--ax) * 30);
  position: relative;
}

@media only screen and (min-width: 750px) {
  .circle-bg__text {
    padding-left: calc(var(--ax) * 50);
  }
}

.circle-bg__button {
  margin-top: calc(var(--ax) * 50);
}

.columns {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--ax) * 10);
  justify-content: center;
}

@media only screen and (max-width: 989px) {
  .columns--3 {
    display: block;
  }
}

.columns--event {
  row-gap: calc(var(--ax) * 55);
}

.columns__media {
  aspect-ratio: 0.8097;
  width: 100%;
}

.columns__media--4 {
  aspect-ratio: 0.6113;
}

.columns__media--text-below {
  aspect-ratio: 0.8718;
}

.columns__media--event {
  aspect-ratio: 0.913;
}

.columns__wrap {
  grid-column: span 3;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.columns__wrap__title {
  margin-bottom: calc(var(--ax) * 46);
}

.columns__wrap__title--with-eyebrow {
  margin-top: calc(var(--ax) * 22);
}

.columns__wrap__title--with-subtitle {
  margin-bottom: 0;
}

.columns__wrap__title--with-subtitle + h3 {
  margin: calc(var(--ax) * 10) auto 0;
  max-width: calc(var(--ax) * 550);
}

.columns__wrap--4 {
  grid-column: span 4;
}

.columns__button {
  margin-top: calc(var(--ax) * 50);
  position: relative;
  z-index: 2;
}

.columns__content--text-rotate {
  left: 0;
  padding: calc(var(--ax) * 10);
  position: absolute;
  top: 0;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .columns__content--text-rotate {
    padding: calc(var(--ax) * 20);
  }
}

.columns__content--text-below {
  display: block;
  margin: calc(var(--ax) * 10) auto 0;
  max-width: calc(var(--ax) * 265);
  padding: 0 calc(var(--ax) * 20);
  text-align: center;
}

@media only screen and (min-width: 990px) {
  .columns__content--text-below {
    margin-top: calc(var(--ax) * 26);
  }
}

.columns__content--event {
  max-width: 100%;
  text-align: left;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .columns__content--event {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 989px) {
  .columns__content--event {
    padding: calc(var(--ax) * 10) 0;
  }
}

.columns__event-date {
  margin-top: calc(var(--ax) * 5);
}

.columns__event-button {
  margin-top: calc(var(--ax) * 15);
}

.columns__title {
  margin: 0;
}

.columns__item {
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 2;
}

.columns__item .shortbar {
  display: inline-block;
  height: calc(var(--ax) * 20);
  left: calc(var(--ax) * -75);
  margin-left: calc(var(--ax) * 10);
  opacity: 0;
  position: absolute;
  top: calc(var(--ax) * 17.5);
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: top left;
  transition: opacity 0.2s cubic-bezier(0.32, 0.24, 0.15, 1) 0.25s;
  width: auto;
}

@media only screen and (min-width: 990px) {
  .columns__item .shortbar {
    left: calc(var(--ax) * -75);
    margin-left: 0;
    top: calc(var(--ax) * 30);
  }
}

.columns__item .shortbar path {
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.columns__item.section-animated:not(.section-animated-complete) .shortbar {
  animation: shortbar;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
}

.columns__item.section-animated:not(.section-animated-complete) .shortbar path {
  animation: shortbar-path;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.32, 0.24, 0.15, 1);
}

.columns__item:hover .shortbar {
  animation: none;
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.columns__item:hover .shortbar path {
  animation: none;
  transform: translateY(0);
  transition: transform 0.7s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.columns__item--4 {
  width: calc(50% - var(--ax) * 10);
}

@media only screen and (min-width: 990px) {
  .columns__item--4 {
    width: calc(25% - var(--ax) * 7.5);
  }

  .columns__item--3 {
    width: calc(33.33% - var(--ax) * 6.667);
  }
}

@media only screen and (max-width: 989px) {
  .columns__item--3 {
    display: block;
    margin: calc(var(--ax) * 30) auto 0;
    max-width: calc(var(--ax) * 250);
  }

  .columns__item--3:first-child {
    margin-top: 0;
  }
}

.columns__bg {
  z-index: 1;
}

@keyframes shortbar-path {
  0% {
    transform: translateY(100%);
  }

  60% {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes shortbar {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.contact-form {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.contact-form #tsLeadSuccessMessage,
.contact-form h1,
.contact-form p {
  color: #e6e5e4;
}

.contact-form__bg,
.contact-form__bg:before {
  height: 100%;
  top: 0;
  z-index: 1;
}

.contact-form__bg:before {
  background: #000;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.contact-form__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .contact-form__item {
    overflow: hidden;
    padding: calc(var(--ax) * 67) 0;
  }

  .contact-form__item--it2 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1512px) {
  .contact-form__item--image-left {
    margin-left: 0;
  }

  .contact-form__item--image-right {
    margin-right: 0;
  }
}

.contact-form__content {
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 990px) {
  .contact-form__content {
    align-items: center;
    box-sizing: content-box;
    margin: auto;
    width: 50%;
  }
}

.contact-form__content__inner {
  padding: calc(var(--ax) * 30) calc(var(--ax) * 15);
}

@media only screen and (min-width: 990px) {
  .contact-form__content__inner {
    box-sizing: content-box;
    margin: 0 auto;
    max-width: calc(var(--ax) * 532);
    padding: calc(var(--ax) * 62) calc(var(--ax) * 25);
  }
}

.contact-form__title {
  margin-top: 0.5em;
  position: relative;
  z-index: 1;
}

.contact-form__text {
  margin-top: 2.5em;
  padding-left: 2em;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .contact-form__text {
    padding-left: 3.125em;
  }
}

.contact__fields {
  --color-foreground: $colorVirgil;
  column-gap: calc(var(--ax) * 8);
  margin-top: calc(var(--ax) * 10);
  row-gap: calc(var(--ax) * 15);
}

.contact__fields .field:after {
  border-color: #e6e5e4;
}

.contact__fields .field:before {
  display: none;
}

.contact__fields .field__input {
  border-color: #e6e5e4;
  color: #e6e5e4;
  height: calc(var(--ax) * 50);
  padding: 0 calc(var(--ax) * 27);
}

.contact__fields .field__input::-webkit-input-placeholder,
.contact__fields .field__input::placeholder {
  color: #e6e5e4;
  font-size: 1em;
  opacity: 1;
  text-transform: unset;
}

.contact__fields .field__input.text-area {
  padding-top: calc(var(--ax) * 14);
}

.contact .field__input:not(:placeholder-shown):not(.text-area) {
  color: #e6e5e4;
  padding-top: 0;
}

.contact .field--textarea {
  grid-column-end: 3;
  grid-column-start: 1;
}

@media only screen and (min-width: 990px) {
  .contact {
    max-width: calc(var(--ax) * 770);
  }
}

@media only screen and (max-width: 989px) {
  .contact {
    padding: 0 calc(var(--ax) * 10);
  }
}

.contact__button {
  margin-top: calc(var(--ax) * 20);
}

.contact .icon-success {
  margin-top: calc(var(--ax) * 2);
}

.content-details {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.content-details__bg {
  height: 100%;
  top: 0;
  z-index: 1;
}

.content-details__bg:before {
  background: #000;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.content-details__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .content-details__item {
    overflow: hidden;
    padding: calc(var(--ax) * 67) 0;
  }

  .content-details__item--it2 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1512px) {
  .content-details__item--image-left {
    margin-left: 0;
  }

  .content-details__item--image-right {
    margin-right: 0;
  }
}

.content-details__content {
  position: relative;
  width: 100%;
  z-index: 3;
}

@media only screen and (min-width: 990px) {
  .content-details__content {
    align-items: center;
    box-sizing: content-box;
    margin: auto;
    width: 50%;
  }
}

.content-details__content__inner {
  padding: calc(var(--ax) * 30) calc(var(--ax) * 15);
}

@media only screen and (min-width: 990px) {
  .content-details__content__inner {
    box-sizing: content-box;
    margin: 0 auto;
    max-width: calc(var(--ax) * 450);
    padding: calc(var(--ax) * 62) calc(var(--ax) * 85);
  }
}

.content-details__block:not(:first-child) {
  margin-top: calc(var(--ax) * 30);
}

@media only screen and (min-width: 990px) {
  .content-details__block:not(:first-child) {
    margin-top: calc(var(--ax) * 45);
  }
}

.content-details__block__content {
  margin-top: calc(var(--ax) * 15);
}

.content-details__block__content b,
.content-details__block__content strong {
  font-weight: 500;
}

.content-details__block__content li,
.content-details__block__content p,
.content-details__block__content ul {
  margin: 0;
}

.content-details__block__content ul {
  padding-left: 0.8em;
}

.content-details__title {
  margin-top: 0.5em;
  position: relative;
  z-index: 1;
}

.content-details__text {
  margin-top: 1.5625em;
  padding-left: 2em;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .content-details__text {
    margin-left: -3.125em;
    padding-left: 3.125em;
    padding-right: 3.125em;
  }
}

.content-details__text--it2 {
  margin-top: 2em;
}

.content-details__buttons {
  display: flex;
  margin-top: calc(var(--ax) * 30);
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .content-details__buttons {
    margin-top: calc(var(--ax) * 48);
  }
}

.content-details__button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.content-details__button--2 {
  margin-left: calc(var(--ax) * 16);
}

@media only screen and (min-width: 990px) {
  .content-details__button {
    min-width: calc(var(--ax) * 98);
  }
}

@media only screen and (max-width: 989px) {
  .content-details__button {
    width: 100%;
  }
}

.divider-title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

@media only screen and (max-width: 989px) {
  .divider-title {
    flex-wrap: wrap;
  }
}

.divider-title__title {
  width: 100%;
}

@media only screen and (max-width: 989px) {
  .divider-title__title {
    text-align: right;
  }
}

@media only screen and (min-width: 990px) {
  .divider-title__title {
    margin-right: calc(var(--ax) * 56);
    width: max-content;
  }
}

.divider-title__divider {
  flex: 1;
  position: relative;
}

@media only screen and (max-width: 989px) {
  .divider-title__divider {
    margin-top: calc(var(--ax) * 15);
  }
}

.divider-title__dot {
  background: #272b1a;
  border-radius: 100%;
  height: calc(var(--ax) * 6);
  position: relative;
  width: calc(var(--ax) * 6);
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .divider-title__dot {
    height: calc(var(--ax) * 9);
    width: calc(var(--ax) * 9);
  }
}

.divider-title__line,
.divider-title__line-track {
  bottom: 0;
  height: 1px;
  left: 0;
  margin: auto 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 2;
}

.divider-title__line {
  background: #272b1a;
  transform: translateX(-100%);
}

.section-animated .divider-title__line {
  transform: translateX(0);
  transition: transform 1.8s cubic-bezier(0.32, 0.24, 0.15, 1) 0.05s;
}

.faq {
  max-width: calc(var(--ax) * 800);
  padding: 0 calc(var(--ax) * 24);
}

.header-group-container {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.header {
  background: #e4e4e3;
  z-index: 4;
}

.header,
.header__inner {
  position: relative;
  width: 100%;
}

.header__inner {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .header__inner {
    padding-bottom: calc(var(--ax) * 36.5);
    padding-top: calc(var(--ax) * 36.5);
  }
}

.header.animated-once {
  transition: background 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.header.animated-once svg circle,
.header.animated-once svg line,
.header.animated-once svg path,
.header.animated-once svg rect {
  transition: stroke 0.4s cubic-bezier(0.32, 0.24, 0.15, 1),
    fill 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.header-group-container.scrolled .header,
.header-group-container[data-open-mega] .header {
  background: #e6e5e4;
  transition: background 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.header-group-container.scrolled .header svg circle,
.header-group-container.scrolled .header svg line,
.header-group-container.scrolled .header svg path,
.header-group-container.scrolled .header svg rect,
.header-group-container[data-open-mega] .header svg circle,
.header-group-container[data-open-mega] .header svg line,
.header-group-container[data-open-mega] .header svg path,
.header-group-container[data-open-mega] .header svg rect {
  transition: stroke 0.4s cubic-bezier(0.32, 0.24, 0.15, 1),
    fill 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media only screen and (max-width: 989px) {
  .header,
  .mobile-menu-open .header {
    transition: transform 0.2s cubic-bezier(0.32, 0.24, 0.15, 1),
      background-color 0.2s cubic-bezier(0.32, 0.24, 0.15, 1);
  }

  .mobile-menu-open .header {
    background-color: #e6e5e4;
    transform: translateY(0);
  }
}

.header__menu {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  position: relative;
}

@media only screen and (max-width: 989px) {
  .header__menu {
    position: relative;
  }

  .mobile-menu-open .header__menu {
    z-index: 4;
  }
}

.header__menu ul {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.header__cart-icon,
.header__menu-item {
  position: relative;
  text-decoration: none;
  z-index: 3;
}

.header__menu-item {
  color: #272b1a;
  display: block;
  margin-left: 0.7272em;
}

.header__menu-item--logo svg {
  display: block;
  height: auto;
  width: 13.091em;
}

@media only screen and (min-width: 990px) {
  .header__menu-item--logo svg {
    width: 17.2155em;
  }
}

@media only screen and (max-width: 989px) {
  .mobile-menu-open .header__menu-item--logo {
    z-index: 6;
  }
}

.header__menu-item:focus:not(:focus-visible) {
  outline: none;
}

@media only screen and (min-width: 990px) {
  .header__menu-item {
    margin-left: 1.15em;
  }

  .header__menu-item--first {
    margin-left: 0;
  }

  body:not(.a11y) .header__menu-item--mobile-menu {
    cursor: default;
  }

  .header__menu-item--mobile-menu svg {
    display: none;
  }
}

@media only screen and (max-width: 989px) {
  .header__menu-item {
    display: none;
  }

  .header__menu-item--logo,
  .header__menu-item--mobile-menu {
    display: block;
  }

  .header__menu-item--mobile-menu {
    margin-right: 0;
    touch-action: manipulation;
  }

  .header__menu-item--mobile-menu svg {
    width: 1.72em;
  }

  .header__menu-item--mobile-menu span {
    clip: rect(0 0 0 0);
    word-wrap: normal !important;
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}

.header__icons {
  display: flex;
  margin-left: calc(var(--ax) * 36);
  position: relative;
}

.header__icon {
  box-sizing: content-box;
  font-size: calc(var(--ax) * 30);
  top: 0;
  width: 0.5em;
}

.header__icon svg {
  display: block;
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 989px) {
  .header__icon {
    padding: 0.4em 0.2em;
  }
}

@media only screen and (min-width: 990px) {
  .header__icon {
    align-items: center;
    display: flex;
    font-size: calc(var(--ax) * 36);
    height: 100%;
    margin-left: 0.33em;
    top: 0;
  }

  .header__icon svg {
    display: block;
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 989px) {
  .header .container {
    padding: calc(var(--ax) * 5);
  }

  .header__icon--cart,
  .header__menu-item--mobile-menu {
    opacity: 1;
    transition: opacity 0.1s ease-out;
  }

  .header__icon--cart {
    margin-left: 0;
  }
}

.hero {
  align-items: center;
  aspect-ratio: 1.40625;
  display: flex;
  height: auto;
  justify-content: flex-start;
  max-height: calc(100vh - var(--ax) * 96);
  overflow: hidden;
  position: relative;
  width: 100%;
}

.hero--full {
  max-height: 100vh;
}

@media only screen and (min-width: 990px) {
  .hero--short {
    aspect-ratio: 3.3644859813;
  }
}

.hero__media,
.hero__media:before {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.hero__media:before {
  background: #000;
  content: "";
  display: block;
  opacity: 0;
}

.hero__title {
  display: inline-block;
  margin: auto;
  position: relative;
  text-decoration: none;
  z-index: 3;
}

.hero-small {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: flex-start;
  max-height: calc(100vh - var(--ax) * 96);
  overflow: hidden;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .hero-small {
    aspect-ratio: 3.3644859813;
  }
}

.hero-small .svg-circle {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 1;
}

.hero-small__title {
  text-align: center;
  text-decoration: none;
}

.hero-small__media,
.hero-small__media:before {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.hero-small__media:before {
  background: #000;
  content: "";
  display: block;
  opacity: 0;
}

.hero-small__main {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 989px) {
  .hero-small__main {
    margin: calc(var(--ax) * -25) 0;
  }
}

.hero-small__content {
  align-items: center;
  aspect-ratio: 1;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  left: 0;
  max-width: calc(var(--ax) * 150);
  min-height: calc(var(--ax) * 150);
  padding: calc(var(--ax) * 45);
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 990px) {
  .hero-small__content {
    max-width: calc(var(--ax) * 478);
    min-height: calc(var(--ax) * 474);
    padding: calc(var(--ax) * 100);
  }
}

.instagram-feed__feed {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: calc(var(--ax) * 30) 0 calc(var(--ax) * 15);
  position: relative;
}

@media only screen and (min-width: 990px) {
  .instagram-feed__feed {
    margin-top: calc(var(--ax) * 10);
    min-height: 26.181vw;
    padding: 7.083333333vw 0 2.84722vw;
  }
}

.instagram-feed__feed #insta-feed {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--ax) * 16);
  justify-content: space-between;
  margin: 0;
}

@media only screen and (max-width: 989px) {
  .instagram-feed__feed #insta-feed {
    margin-bottom: calc(var(--ax) * 7);
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - var(--ax) * 14);
  }
}

.instagram-feed__feed #insta-feed .instafeed-overlay:after {
  opacity: 0.3;
}

.instagram-feed__feed #insta-feed a {
  display: block;
  width: calc(50% - var(--ax) * 8);
}

@media only screen and (min-width: 990px) {
  .instagram-feed__feed #insta-feed a {
    width: calc(20% - var(--ax) * 14);
  }
}

@media only screen and (max-width: 989px) {
  .instagram-feed__feed #insta-feed a:nth-child(5) {
    display: none;
  }
}

.instagram-feed__feed #insta-feed .instafeed-container {
  padding-top: 100% !important;
  width: 100% !important;
}

@media only screen and (min-width: 990px) {
  .instagram-feed__feed--with-bg {
    padding-top: 2.84722vw;
  }
}

@media only screen and (max-width: 989px) {
  .instagram-feed__paper {
    display: none;
  }
}

.instagram-feed__paper img {
  object-position: top;
}

.instagram-feed__block-wrapper {
  margin-top: calc(var(--ax) * 80);
  position: relative;
}

.instagram-feed__block-wrapper:before {
  background: #272b1a;
  content: "";
  height: 1px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100% - var(--x-spacing) * 4);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .instagram-feed__block-wrapper {
    margin-top: calc(var(--ax) * 80);
    position: relative;
  }

  .instagram-feed__block-wrapper:before {
    max-width: calc(var(--ax) * 1240);
  }
}

.page-title {
  margin-top: 0;
}

.main-page-title {
  margin-bottom: 3rem;
}

@media only screen and (min-width: 750px) {
  .main-page-title {
    margin-bottom: 4rem;
  }
}

.page-placeholder-wrapper {
  display: flex;
  justify-content: center;
}

.page-placeholder {
  height: 52.5rem;
  width: 52.5rem;
}

.mega-menu {
  background-color: #c3beb3;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  transform: translateX(100%);
  transition: transform 0.3s linear, opacity 0.05s linear 0.25s;
  width: 100%;
  z-index: 2;
}

.mega-menu__button {
  font-family: Agenda, "sans-serif";
}

@media only screen and (max-width: 989px) {
  .mega-menu {
    background-color: #c3beb3;
    border-top: 1px solid rgba(39, 43, 26, 0.25);
    height: calc(var(--viewport-height) - var(--header-height));
    transition: transform 0.4s cubic-bezier(0.32, 0.24, 0.15, 1);
    z-index: 4;
  }
}

.mega-menu__close {
  display: none;
}

@media only screen and (min-width: 990px) {
  .mega-menu {
    transform: translateY(-100%);
  }

  .mega-menu:before {
    background-color: #c3beb3;
    bottom: 100%;
    content: "";
    display: block;
    height: calc(var(--ax) * 100);
    left: 0;
    position: absolute;
    width: 100%;
  }

  .mega-menu__close {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    width: 100vw;
    z-index: 1;
  }
}

.mega-menu__wrapper {
  display: flex;
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.32, 0.24, 0.15, 1);
}

@media only screen and (max-width: 989px) {
  .mega-menu__wrapper {
    flex-direction: column;
    overflow: scroll;
    padding: 0 calc(var(--ax) * 20) calc(var(--ax) * 20);
  }
}

@media only screen and (min-width: 990px) {
  .mega-menu__wrapper {
    justify-content: flex-start;
    padding-bottom: calc(var(--ax) * 60);
    padding-top: calc(var(--ax) * 60);
  }
}

.mega-menu__wrapper[aria-hidden="true"] {
  visibility: hidden;
}

@media only screen and (max-width: 989px) {
  .mega-menu__content,
  .mega-menu__separator {
    display: none;
  }
}

@media only screen and (min-width: 990px) {
  .mega-menu__content {
    flex: 1;
    max-width: calc(var(--ax) * 185);
  }

  .mega-menu__title {
    line-height: 1.9;
  }

  .mega-menu__text {
    line-height: 1.485;
    margin-top: calc(var(--ax) * 35);
  }

  .mega-menu__text p {
    margin: 0.7em 0 0;
  }

  .mega-menu__text p:first-child {
    margin-top: 0;
  }

  .mega-menu__button {
    margin-top: calc(var(--ax) * 88);
  }

  .mega-menu__separator {
    background: #272b1a;
    height: calc(var(--ax) * 300);
    margin: 0 calc(var(--ax) * 72);
    width: 1px;
  }
}

.mega-menu__menus {
  display: flex;
}

@media only screen and (min-width: 990px) {
  .mega-menu__menus {
    flex: 1;
    margin-right: calc(var(--ax) * 60);
  }
}

@media only screen and (max-width: 989px) {
  .mega-menu__menus {
    order: 2;
  }
}

@media only screen and (min-width: 990px) {
  .mega-menu__block {
    margin-right: calc(var(--ax) * 60);
  }

  .mega-menu__block:last-of-type {
    margin-right: 0;
  }
}

.mega-menu__menu {
  width: 50%;
}

@media only screen and (min-width: 990px) {
  .mega-menu__menu {
    width: 100%;
    width: max-content;
  }
}

.mega-menu__menu_title {
  max-width: calc(var(--ax) * 250);
}

.mega-menu__menu__link {
  color: #272b1a;
  display: block;
  margin-top: calc(var(--ax) * 10);
  text-decoration: none;
}

li:first-child .mega-menu__menu__link {
  margin-top: calc(var(--ax) * 15);
}

@media only screen and (min-width: 990px) {
  .mega-menu__menu__link {
    margin-top: calc(var(--ax) * 15);
    width: max-content;
  }

  li:first-child .mega-menu__menu__link {
    margin-top: calc(var(--ax) * 25);
  }

  .mega-menu__menu__link:before {
    left: 0;
    opacity: 0.5;
    right: unset;
  }
}

@media only screen and (max-width: 989px) {
  .mega-menu__menu__link {
    line-height: 1.35;
    padding-right: calc(var(--ax) * 30);
  }
}

@media only screen and (min-width: 990px) {
  .mega-menu__images {
    display: flex;
    margin-left: auto;
  }
}

@media only screen and (max-width: 989px) {
  .mega-menu__images {
    border-bottom: 1px solid rgba(39, 43, 26, 0.1);
    margin-bottom: calc(var(--ax) * 20);
    order: 1;
    padding-bottom: calc(var(--ax) * 20);
  }
}

.mega-menu__image {
  color: #272b1a;
  text-decoration: none;
}

.mega-menu__image__image {
  aspect-ratio: 1.0902527076;
  height: auto;
  max-width: 100%;
  position: relative;
  width: 50%;
}

@media only screen and (min-width: 990px) {
  .mega-menu__image__image {
    width: calc(var(--ax) * 300);
  }
}

.mega-menu__image__subtitle,
.mega-menu__image__title {
  color: #272b1a;
  margin-top: calc(var(--ax) * 10);
}

@media only screen and (max-width: 989px) {
  .mega-menu__image__subtitle {
    line-height: 1;
    margin-top: calc(var(--ax) * 6);
  }
}

.mega-menu__mobile-close {
  background: none;
  border-bottom: 1px solid rgba(39, 43, 26, 0.1);
  color: #272b1a;
  font-size: calc(var(--ax) * 14);
  font-weight: 300;
  letter-spacing: 0.08em;
  margin-bottom: calc(var(--ax) * 20);
  padding: calc(var(--ax) * 20) 0;
  position: relative;
  text-align: right;
}

.mega-menu__mobile-close:before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  font-weight: 200;
  height: calc(var(--ax) * 10);
  left: 0;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  width: calc(var(--ax) * 10);
}

.paper-tear__image {
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .product-slider {
    gap: 0;
    overflow-x: hidden;
  }
}

.product-slider__above-text-image {
  display: block;
  height: auto;
  max-width: calc(var(--ax) * 180);
  width: 100%;
}

.product-slider__above-text-image + .product-slider__title {
  margin-top: calc(var(--ax) * 15);
}

.product-slider__left {
  box-sizing: border-box;
  width: 100vw;
}

@media only screen and (max-width: 989px) {
  .product-slider__left {
    max-width: calc(var(--ax) * 480);
    overflow: hidden;
  }
}

@media only screen and (min-width: 990px) {
  .product-slider__left {
    padding-right: calc(var(--ax) * 75);
    width: 30.7%;
  }
}

.product-slider__image {
  aspect-ratio: 1;
  border: 1px solid #272b1a;
  border-radius: 100%;
  overflow: hidden;
  width: 75%;
}

@media only screen and (max-width: 989px) {
  .product-slider__image {
    max-width: calc(var(--ax) * 480);
  }
}

@media only screen and (min-width: 990px) {
  .product-slider__image {
    width: 38.6%;
  }
}

.product-slider__image .unpositioned-image {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
}

.product-slider__right {
  box-sizing: border-box;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .product-slider__right {
    padding-left: calc(var(--ax) * 75);
    width: 30.7%;
  }
}

@media only screen and (max-width: 989px) {
  .product-slider__right {
    margin-top: calc(var(--ax) * 45);
    max-width: calc(var(--ax) * 480);
    text-align: center;
  }
}

.product-slider__item {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 989px) {
  .product-slider__item {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.product-slider__graphic {
  align-items: flex-end;
  display: flex;
  margin-right: calc(var(--ax) * -75);
  margin-top: calc(var(--ax) * 20);
  position: relative;
  transform: translateX(26%);
  width: calc(100% + var(--ax) * 75);
  z-index: 2;
}

.product-slider__graphic__track {
  align-items: flex-end;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.product-slider__graphic__line-bottom {
  background-color: #272b1a;
  height: 1px;
  transform: translateX(100%);
  width: 100%;
}

[aria-hidden="false"] .section-animated .product-slider__graphic__line-bottom {
  transform: translateX(0);
  transition: transform 0.6s linear 0.05s;
}

.product-slider__graphic__line-left {
  background-color: #272b1a;
  height: calc(var(--ax) * 70);
  transform: translateY(100%);
  width: 1px;
}

[aria-hidden="false"] .section-animated .product-slider__graphic__line-left {
  transform: translateY(0);
  transition: transform 0.155s cubic-bezier(0.32, 0.24, 0.15, 1) 0.65s;
}

.product-slider__graphic__dot {
  background-color: #272b1a;
  border-radius: 100%;
  height: calc(var(--ax) * 8);
  left: -1px;
  opacity: 0;
  position: relative;
  top: calc(50% - 1px);
  transform: translateY(50%);
  width: calc(var(--ax) * 8);
}

[aria-hidden="false"] .section-animated .product-slider__graphic__dot {
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.32, 0.24, 0.15, 1);
}

.product-slider__ingredients {
  margin-top: calc(var(--ax) * 53);
}

.product-slider__product-subtitle {
  margin-top: 0.3125em;
}

.product-slider__description {
  margin-top: 2em;
}

@media only screen and (min-width: 990px) {
  .product-slider__description {
    margin-top: 3.125em;
  }
}

.product-slider__button {
  margin-top: 2.8em;
}

@media only screen and (min-width: 990px) {
  .product-slider__button {
    margin-top: 3.57em;
  }
}

.product-slider__button-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
}

@media only screen and (max-width: 989px) {
  .product-slider__button-container {
    justify-content: center;
    width: 100%;
  }
}

.product-slider__nav {
  margin-top: calc(var(--ax) * 42);
}

@media only screen and (max-width: 989px) {
  .product-slider__nav {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--ax) * 20);
    width: 100%;
  }
}

.title__content {
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 2;
}

.title__text {
  margin-top: calc(var(--ax) * 20);
}

@media only screen and (min-width: 990px) {
  .title__text {
    margin-top: calc(var(--ax) * 30);
  }
}

.title__bg {
  height: 100%;
  top: 0;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .vertical-image-banner {
    flex-gap: calc(var(--ax) * 20);
    align-items: center;
    aspect-ratio: 1.3716814159;
    display: flex;
    justify-content: center;
    padding: calc(var(--ax) * 40) calc(var(--ax) * 40) calc(var(--ax) * 120);
    position: relative;
  }
}

.vertical-image-banner__content {
  position: relative;
  text-align: center;
  z-index: 3;
}

.vertical-image-banner__title {
  margin-top: 0.55em;
}

.vertical-image-banner__button {
  margin-top: 3.57em;
}

.vertical-image-banner__media,
.vertical-image-banner__media:before {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.vertical-image-banner__media:before {
  background: #000;
  content: "";
  display: block;
  z-index: 1;
}

.vertical-image-banner__item {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 989px) {
  .vertical-image-banner__item {
    aspect-ratio: 0.6755260244;
    width: 100%;
  }

  .vertical-image-banner__item--2 {
    bottom: 0;
    margin-top: calc(var(--ax) * 30);
    top: unset;
  }

  .vertical-image-banner__item--1,
  .vertical-image-banner__item--2 {
    height: 50%;
  }
}

@media only screen and (min-width: 990px) {
  .vertical-image-banner__item {
    bottom: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: calc(50% - var(--ax) * 10);
  }

  .vertical-image-banner__item--1 {
    left: 0;
  }

  .vertical-image-banner__item--2 {
    left: unset;
    right: 0;
  }
}

.wide-banner {
  align-items: center;
  aspect-ratio: 0.75;
  display: flex;
  justify-content: center;
  padding: calc(var(--ax) * 40);
  position: relative;
}

@media only screen and (min-width: 990px) {
  .wide-banner {
    aspect-ratio: 2.5;
    padding: calc(var(--ax) * 40) calc(var(--ax) * 40) calc(var(--ax) * 120);
  }
}

.wide-banner__content {
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 3;
}

.wide-banner__title {
  margin-top: 0.55em;
}

.wide-banner__button {
  margin-top: 3.57em;
}

.wide-banner__media {
  bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
}

.wide-banner__media:before {
  background: #000;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.wide-banner__media--full {
  left: 0;
  right: 0;
  width: 100%;
}

@media only screen and (max-width: 989px) {
  .wide-banner__media {
    width: 100%;
  }

  .wide-banner__media--2 {
    bottom: 0;
    top: unset;
  }

  .wide-banner__media--1,
  .wide-banner__media--2 {
    height: 50%;
  }
}

@media only screen and (min-width: 990px) {
  .wide-banner__media--1 {
    left: 0;
  }

  .wide-banner__media--2 {
    left: unset;
    right: 0;
  }
}

.rich-text {
  z-index: 1;
}

.pf-content .container {
  max-width: calc(var(--ax) * 1284);
}

.pf-content__item {
  width: 100%;
}

.pf-content__item:not(:first-child) {
  margin-top: calc(var(--ax) * 90);
}

@media only screen and (max-width: 989px) {
  .pf-content__item:not(:first-child) {
    margin-top: calc(var(--ax) * 120);
  }

  .pf-content__item--image-with-text .grid__item--text {
    margin-top: calc(var(--ax) * 48);
    order: 2;
  }

  .pf-content__item--image-with-text .grid__item--image {
    order: 1;
  }
}

.pf-content__item--image-with-text .text-block__eyebrow {
  margin-top: var(--ax6);
}

@media only screen and (min-width: 990px) {
  .pf-content__item--image-with-text .text-block {
    max-width: calc(var(--ax) * 420);
  }

  .pf-content__item--image-with-text .text-block--image_left {
    margin-left: auto;
  }

  .pf-content__item--image-with-text .text-block__eyebrow {
    margin-top: var(--ax15);
  }

  .pf-content__item {
    padding: 0 calc(var(--ax) * 40);
  }
}

.pf-content__item--mobile-product-grid:not(:first-child) {
  margin-bottom: 0;
}

@media only screen and (max-width: 989px) {
  .collection-content__item--product-block.m-down-hide
    + .pf-content__item--mobile-product-grid:not(:first-child) {
    margin-top: calc(var(--ax) * 60);
  }
}

.pf-content__image-wrapper {
  aspect-ratio: 0.967;
  height: auto;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 989px) {
  .pf-content__image-wrapper {
    aspect-ratio: unset;
    max-width: calc(var(--ax) * 300);
  }
}

.pf-content__image {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 1;
}

@media only screen and (min-width: 990px) {
  .pf-content__image--right {
    margin-left: auto;
  }

  .pf-content__image--left {
    margin-right: auto;
  }
}

.pf-content__bg {
  background-repeat: repeat-y;
  background-size: 100%;
}

.pf-columns__grid {
  margin-top: calc(var(--ax) * 60);
}

.pf-columns__grid.grid {
  margin-bottom: 0;
}

.pf-columns__item__icon {
  height: calc(var(--ax) * 145);
  width: calc(var(--ax) * 145);
}

.pf-columns__item__icon .unpositioned-image {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pf-columns__item__icon .unpositioned-image img {
  display: block;
  margin: auto;
  position: static;
  width: auto;
}

.pf-columns__item__title {
  margin-top: calc(var(--ax) * 28);
}

.pf-columns__item__text {
  margin-top: var(--ax20);
}

@media only screen and (min-width: 990px) {
  .pf-columns__item__text {
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(var(--ax) * 34);
    max-width: calc(var(--ax) * 220);
  }
}

@media only screen and (max-width: 749px) {
  .pf-columns__item.grid__item {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(var(--ax) * 250);
    width: 100%;
  }

  .pf-columns__item:not(:first-child) {
    margin-top: calc(var(--ax) * 45);
  }
}

.hero-quote {
  aspect-ratio: 0.75;
  height: auto;
  max-height: calc(100vh - var(--ax) * 96);
  overflow: hidden;
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .hero-quote {
    aspect-ratio: 3;
    max-height: calc(var(--ax) * 600);
  }
}

.hero-quote__title {
  margin: 0;
  text-decoration: none;
}

.hero-quote__media:before {
  background: #000;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.hero-quote__main {
  text-decoration: none;
}

@media only screen and (max-width: 989px) {
  .hero-quote__main {
    padding: calc(var(--ax) * 25);
  }
}

@media only screen and (min-width: 990px) {
  .hero-quote__main {
    align-self: center;
    left: 0;
    margin: 0 auto;
    max-width: calc(var(--ax) * 1164);
    right: 0;
  }
}

.hero-quote__title-wrap {
  align-self: flex-start;
}

.hero-quote__subtitle-wrap {
  align-self: flex-start;
  display: block;
  margin-top: calc(var(--ax) * 25);
  width: 100%;
}

@media only screen and (min-width: 990px) {
  .pf-delivery-methods .grid__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.pf-delivery-methods__image-wrapper .unpositioned-image {
  aspect-ratio: 1.2;
  max-width: calc(var(--ax) * 820);
  position: relative;
}

.pf-delivery-methods__content-image {
  height: 100%;
  width: 100%;
}

.pf-delivery-methods__text-wrapper {
  padding: var(--ax36);
  text-align: left;
}

@media only screen and (min-width: 990px) {
  .pf-delivery-methods__text-wrapper {
    margin: 0 auto;
    max-width: calc(var(--ax) * 450);
  }
}

.pf-delivery-methods__text-wrapper a {
  text-decoration: none;
}

.pf-delivery-methods__block {
  margin-top: calc(var(--ax) * 45);
}

.pf-delivery-methods__title {
  font-weight: 500;
  letter-spacing: 0.05em;
}

.pf-delivery-methods__subtitle {
  font-family: Poppins, "sans-serif";
  font-size: 1.2rem;
  font-size: clamp(1rem, 3.0768vw, 1.2rem);
  font-weight: 600;
  letter-spacing: 0.215625em;
  text-transform: uppercase;
}

@media only screen and (min-width: 990px) {
  .pf-delivery-methods__subtitle {
    font-size: calc(var(--ax) * 16);
  }
}

.pf-delivery-methods__text {
  font-weight: 300;
  margin-top: var(--ax15);
}

.pf-delivery-methods__text li::marker {
  font-size: 0.55em;
}

.pf-inside-your-box__text {
  letter-spacing: 0.025em;
  margin-top: calc(var(--ax) * 40);
}

.pf-inside-your-box__text li:not(:first-of-type) {
  margin-top: var(--ax25);
}

.pf-inside-your-box__dot {
  display: inline-block;
  margin-right: 0.5em;
  position: relative;
  top: -0.2em;
}

h3.pf-reco-h3 {
  font-family: Poppins, "sans-serif" !important;
  font-size: 1.1em;
  line-height: 1.3em;
}

.mf-price {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.4em;
}

.mf-h2 {
  font-size: 2em;
  font-family: Poppins, "sans-serif" !important;
  font-weight: bold;
  text-transform: unset;
}

/* iTRENDS Customization */
.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.color-pf-brown {
  background: #2c2217;
}

div.pf-promise {
  padding: 40px 12px 20px;
}

div.pf-promise-item {
  width: 25%;
  display: block;
  float: left;
  text-align: center;
  margin-bottom: 20px;
}

div.pf-promise-item p {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 1em;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

div.pf-promise-item img {
  width: 80px;
}

.pf-promise div p,
.pf-promise h2 {
  color: #e6e5e4;
}

.pf-columns-v2 h2 {
  font-size: 3em;
  text-transform: initial;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.pf-columns-v2 .subtitle {
  font-size: 1.5em;
  text-transform: initial;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.pf-columns-v2 .description {
  font-size: 1.3em;
  text-transform: initial;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 300;
  padding: 15px;
}

.pf-columns-v2 .pf-columns__item__title {
  font-size: 1.5em;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.pf-columns-v2 .pf-columns__item__text {
  font-size: 1.3em;
  font-family: "Poppins";
  font-weight: 300;
  margin: 0;
  max-width: initial;
}

.pf-columns-v2 .pf-columns__item__button a {
  margin-top: 20px;
  padding: 5px 20px;
  display: inline-block;
  background-color: #2b2316;
  text-decoration: none;
  color: #e6e5e4;
  font-size: 1.6em;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.pf-columns-v2 .pf-columns__item__button.bg-color--sand-dark a {
  background-color: #e6e5e4;
  color: #2b2316;
}

.pf-columns-v2 .pf-columns__item__upper_title {
  font-size: 2.5em;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}

.pf-columns-v2 .pf-columns__item__upper_subtitle {
  font-size: 1.5em;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.4em;
}

.text-color--lightgray {
  color: #2b2316;
}

.text-color--lightgray2 {
  color: #e5e4e3;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .pf-columns__item.grid__item {
    max-width: fit-content;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div.pf-promise-item {
    width: 12.5%;
  }
  .pf-columns-v2 h2 {
    font-size: 4em;
  }
  .pf-columns-v2 .subtitle {
    font-size: 2.5em;
  }
  .pf-columns-v2 .description {
    font-size: 1.7em;
    padding: 25px;
  }
  div.pf-promise-item img {
    width: 120px;
  }
  div.pf-promise-item p {
    font-size: 1.6em;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
